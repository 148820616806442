// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
// import project
import Question from '../Question/Question';
import AnswerListModel from './AnswerListModel';
import MultipleChoiceView from '../MultipleChoice/MultipleChoice';
import { Answer } from './AnswerListModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A common base class for all question slides that have a list of answers like multiple/single choice.<br/>
 * It renders all the answers with a specific template. And handles the user input and model adjustments.
 */
class AnswerList extends Question {

	/**
	 * The model that is rendered into this view
	 */
	public model: AnswerListModel;

	/**
	 * A listrenderer used to render the answers
	 */
	public answerRenderer: ListRenderer<Object>;

	/**
	 * A js key that selects the container where to render the answers.<br/>
	 * The selector must be within the slide container.
	 * @default answer_container
	 */
	protected answerContainerKey: string;

	/**
	 * A html template where all the answer model placeholders can be used.
	 * This template must contain a root node that encapsulates everything inside the template. Like a surrounding div tag.
	 */
	protected answerListTemplate: string;

	constructor( model: AnswerListModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );
		this.answerContainerKey = this.model.get( 'answerContainerKey', 'answer-container' );
		this.answerListTemplate = ''; // should be overwritten by subclass.  
	}

	protected init() {
		super.init();
		this.renderAnswers();
	}

	/**
	 * Renders the list of answers into the answer container.
	 * @protected
	 */
	protected renderAnswers() {
		var answers = new List<Object>( this.model.answers );
		this.answerRenderer = new ListRenderer<Object>();
		this.answerRenderer.container = this.node.js( this.answerContainerKey );

		this.answerRenderer.defaultRender(( answer: any ) => {
			var answerNode = Node.fromHTML( Template.render( this.answerListTemplate, answer ) );
			if ( answer.selected ) {
				( answerNode.one( 'input' ).native as HTMLInputElement ).checked = true;
			}

			this.node.js( this.answerContainerKey ).append( answerNode );
			answerNode.click.add( function ( node: Node, event: MouseEvent ) {
				this.handleAnswerClick( answer );
			}, this );

			return { node: answerNode };
		});
		this.answerRenderer.source = answers;
	}

	/**
	 * Refreshs the checked state of the answers based on the model
	 */
	protected refreshAnswers() {
		var answerModels: any[] = this.model.answers;

		answerModels.forEach(( answerModel ) => {
			var link = this.answerRenderer.linkOf( answerModel );
			var answerNode = link.node;
			( answerNode.one( 'input' ).native as HTMLInputElement ).checked = answerModel.selected;
		});
	}

	/**
	 * Callback when a user clicks on a answer input.
	 * @param answer The answer that was clicked on
	 */
	private handleAnswerClick( answer: Answer ) {

		this.model.select( answer );
		this.refreshAnswers();
	}

}

export default AnswerList;