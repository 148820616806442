import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';

class Overview extends View {
    //public data;

    constructor( ) {
        super( { 'template': 'lb.overview' } );
    }

    init() {
        var listRenderer = new ListRenderer( this.node.js('themen') );

        listRenderer.defaultRender(function( item ) {
            var node = Node.fromHTML( Template.render( 'lb.thema-card', item ));
            node.click.add( function() {
                window.location.href = setup.route( 'thema', { "thema": item.slug, "uid": '' } ).url()
            });
            return { node: node }
        });

        listRenderer.source.fill( setup.data( 'themen' ) )
    }

    /*setData( data:any) {
        this.data = data;
    }*/ 
}

export default Overview;

scanner.ioc.add( 'overview', function( node:Node ) {
    return new Overview( ).render( node );
});