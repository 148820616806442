import Node from 'ln/node/Node';
import Question from '../Question/Question';
import SlideRenderer from '../../sliderenderer/SlideRenderer';
import { MatrixQuestion, MatrixLabel } from '../MatrixChoice/MatrixChoiceModel';
// import MatrixChoiceModel from '../MatrixChoice/MatrixChoiceModel';
import MatrixChoice from '../MatrixChoice/MatrixChoice';
import MatrixBinaryChoiceModel from './MatrixBinaryChoiceModel';


import SingleChoice from '../SingleChoice/SingleChoice';

/**
 * A slide that can shows multiple singlechoice elements with 2 possible answers (binary choice).
 * 
 * 			{
 *              "id": 15,
 *              "title": "matrix binary choice",
 *              "text": "matrix binary choice demo",
 *              "posFeedback": "positiv",
 *              "negFeedback": "negativ",
 *              "modelName": "MatrixBinaryChoice",
 *              "questions": [
 *                  {"id": 2, "text": "1+1=2", "correct": true, "modelName": "MatrixBinaryChoiceQuestion" },
 *                  { "id": 3, "text": "2+1=4", "correct": false, "modelName": "MatrixBinaryChoiceQuestion" }
 *              ]
 *          }
 */

class MatrixBinaryChoice extends MatrixChoice {

	public model:MatrixBinaryChoiceModel;

	constructor( model: MatrixBinaryChoiceModel, slideRenderer: SlideRenderer ) { 
        
        super( model, slideRenderer );
        this.defaultTemplate = this.model.get( 'template', 'lf.matrixbinarychoice-slide' );
	}

}

export default MatrixBinaryChoice;