// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
import DragDropManager from 'lnui/dragdrop/DragDrop';
// import project
import Question from '../Question/Question';
import QuestionModel from '../Question/Question';
import { Drag, Drop } from './DragDropModel';
import DragDropModel from './DragDropModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A slide that can shows multiple drag elements that have to be draged on mutiple target elements.
 * Drag elements with target<=0 have to stay outside any drop element.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		modelName: "DragDrop",
 *		dragTemplate: "<div>{text}</div>",
 *		dropTemplate: "<div>{text}</div>",
 *		maxDragsOnDrop: 2,
 *		strictDrag: false,
 *		drags: [
 *			{ text: 'Drag element 1 with target 1', target: 1 },
 *			{ text: 'Drag element 1 with target 2', target: 2 },
 *			{ text: 'Drag element 1 with target 2', target: 2 }
 *		],
 *		drops: [
 *			{ text: 'Drop target 1 text' },
 *			{ text: 'Drop target 2 text' }
 *		]
 *	}
 * 
 * An example of a corresponding template:
 * 
 *	<div>
 *		<div><img src="img/quiz_sized/{imgPath}" alt="quiz bild" width="447" height="300"></div>
 *		<p class="desc">{description}</p>
 *		<div class="answer_container"></div>
 *	</div>
 *
 */
class DragDrop extends Question {

	
	/**
	 * The default template
	 * @default lf.dragdrop-slide
	 */
	public defaultTemplate: string;

	/**
	 * The template for the drag targets
	 * @default lf.dragdrop-drag 
	 */
	protected dragTemplate: string;

	/**
	 * The template for the drop target
	 * @default lf.dragdrop-drop
	 */
	protected dropTemplate: string;

	/**
	 * If true this will add css classes while dragging so you can show which target is correct or wrong.
	 * Furthermore, its not possible to drag on the wrong element.
	 * @default false
	 */
	private strictDrag: boolean;

	/**
	 * The js key for the drag container within the defaultTemplate
	 * @default drag-container
	 */
	private dragContainerKey: string;

	/**
	 * The js key for the drop container within the defaultTemplate
	 * @default drag-container
	 */
	private dropContainerKey: string;


	/**
	 * Sets the max number of drag items for a drop target
	 * @default 2
	 */
	protected maxDragsOnDrop: number;

	public dragListRenderer: ListRenderer<Drag>;
	public dropListRenderer: ListRenderer<Drop>;
	private dragDropManager: DragDropManager;
	protected dragContainer: Node;
	private dropContainer: Node;
	protected activeDrag: Node = undefined;

	model:DragDropModel;


	constructor( model: DragDropModel, slideRenderer: SlideRenderer ) { 
		super( model, slideRenderer );

		this.defaultTemplate = this.model.get( 'template', 'lf.dragdrop-slide' );
		this.dragTemplate = this.model.get( 'dragTemplate', 'lf.dragdrop-dragitem' );
		this.dropTemplate = this.model.get( 'dropTemplate', 'lf.dragdrop-dropitem' );
		this.dragContainerKey = this.model.get( 'dragContainerKey', 'drag-container' );
		this.dropContainerKey = this.model.get( 'dropContainerKey', 'drop-container' );

		this.strictDrag = this.model.get( 'strictDrag', false );
		this.maxDragsOnDrop = this.model.get( 'maxDragsOnDrop', 2 );
	}


	protected init() {
		super.init();
		this.dragContainer = this.node.js( this.dragContainerKey );
		this.dropContainer = this.node.js( this.dropContainerKey );

		this.renderDrags();
		this.renderDrops();
		this.bindUI();
		this.initDropPositions()
	}

	/**
	 * Render all the drag items into the drag container
	 */
	protected renderDrags() {
		this.dragListRenderer = new ListRenderer<Drag>( this.dragContainer );
		this.dragListRenderer.defaultRender(( drag: Drag, index:number ) => {
			var node = Node.fromHTML( Template.render( this.dragTemplate, drag ) );
			node.setAttribute( 'data-index', index + "" );
			return { node: node };
		});
		this.dragListRenderer.source = new List<Drag>( this.model.drags );
	}

	/** 
	 * Render the drop targets
	 */
	protected renderDrops() {
		this.dropListRenderer = new ListRenderer<Drop>( this.dropContainer );
		this.dropListRenderer.defaultRender(( drop: Drop, index:number ) => {
			var node = Node.fromHTML( Template.render( this.dropTemplate, drop ) );
			node.js( 'target' ).setAttribute( 'data-index', index + 1 + "" );
			return { node: node };
		});
		this.dropListRenderer.source = new List<Drop>( this.model.drops );
	}

	private bindUI() {
		var containers = this.node.all( '[js=target]' );
		if ( containers.length > 0 ) { containers.push( this.dragContainer ) }
		this.dragDropManager = new DragDropManager( containers );

		this.dragDropManager.dropped.add( this.onDropped, this );

		this.handleClicks();

		// reset activeDrag in case drag event is fired in between clicks
		this.dragDropManager.dragged.add( ( el, source ) => {
			if( this.activeDrag ) this.unsetActiveDrag();
		});
	}

	public unbindUI(){
		this.dragDropManager.destroy();
		this.dragListRenderer.links.all().forEach( drag => {
			drag.node.click.removeAll();
		});
		this.dropListRenderer.links.all().forEach( drop => {
			drop.node.click.removeAll();
		});
	}

	public handleClicks(){
		this.dragListRenderer.links.all().forEach( drag => {
			drag.node.click.add( ( n, e ) => {
				// in case we have a currentDrag allready
				if( this.activeDrag ) this.unsetActiveDrag();
				this.activeDrag = n;
				this.activeDrag.addClass( '-clicked' );
				e.stopPropagation();
			});
		});

		this.dropListRenderer.links.all().forEach( drop => {
			drop.node.click.add( ( n ) => {
				if( this.activeDrag ){
					var target = n.js( 'target' );
					target.append( this.activeDrag );
					this.onDropped( this.activeDrag, target, null, null );
					this.unsetActiveDrag();
				}
			});
		});
	}

	protected unsetActiveDrag(){
		this.activeDrag.removeClass( '-clicked' );
		this.activeDrag = undefined;
	}



	/**
	 * Move the drags to the drop targets according 'dropped' values of the model
	 */
	protected initDropPositions() {
		this.model.drags.forEach(( drag ) => {
			if ( drag.dropped != undefined && drag.dropped != 0 ) {
				var dragLink = this.dragListRenderer.linkOf( drag );
				var drop = ( this.dropListRenderer.source as List<Drop> ).get( drag.dropped - 1 );
				var dropLink = this.dropListRenderer.linkOf( drop );

				dropLink.node.js( 'target' ).append( dragLink.node );
			}
		});
	}

	protected onDropped( element: Node, target: Node, source: Node, sibling: Node ) {

		var dragModel = this.model.drags[ element.data.index ];
		var dropIndex = target.data.index; // dropIndex includes start drop area => index + 1
		var dropModel = this.model.drops[ dropIndex - 1 ];

//		if ( target.hasClass( 'target' ) ) {
		if ( target.getAttribute( 'js' ) == 'target' ) {
			dragModel.dropped = parseInt( dropIndex );

			if ( this.dragsOnDrop( dropModel ) > this.maxDragsOnDrop ) {
				this.removeFirstDrag( dropModel );
			}

		} else {
			dragModel.dropped = 0;
		}

		 this.model.fireUserInput();

		 if ( this.model.isAnswered() ) {
		 	this.model.fireUserAnswered();
		 }
	}

	/**
	 * Returns the number of drags on a drop node
	 */
	protected dragsOnDrop( drop: Drop ) {

		var dropLink = this.dropListRenderer.linkOf( drop );
		var dropNode = dropLink.node;
		var drags = dropNode.all( '[js=drag-node]' );
		return drags.length;

	}

	protected removeFirstDrag( drop: Drop ) {
		var dropLink = this.dropListRenderer.linkOf( drop );
		var dropNode = dropLink.node;

		if ( dropNode.all( '[js=drag-node]' ).length > 0 ) {
			// get the first node
			var dragNode = dropNode.all( '[js=drag-node]' )[0];
			// remove it from target
			dropNode.js( 'target' ).removeChild( dragNode );
			// move it to the initial position 
			this.dragContainer.append( dragNode );

			this.dragListRenderer.linkOf( dragNode ).item.dropped = 0;
		}

	}

}

export default DragDrop;