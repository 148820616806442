import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
import Question from '../Question/Question';
import HotspotModel from '../Hotspot/HotspotModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A class that represents a hotspot question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		"modelName":"Hotspot",
 *		"title": "Hot Spot Demo",
 *		"description": "Identify the error on the image by clicking on it.",
 *		"hotspotAreas": [
 *			{ "height": "0.4", "left": "0.128", "top": "0.48", "width": "0.08" }
 *			{ "height": "0.4", "left": "0.5", "top": "0.48", "width": "0.08" }
 *		],
 *		"image": "./img/teamfoto-lernetz.jpg",							
 *	}
 * 
 */
class Hotspot extends Question {

	/**
	 * The model
	 */
	public model: HotspotModel;

	/**
	 * The template for the point on the image
	 */
	private pointTemplate: string;

	/**
	 * The template to render hotspots. This propably only used for decorators that show the hotspots
	 */
	private hotspotAreaTemplate: string;

	/**
	 * The js key for the container of the points
	 */
	private pointContainerKey: string;

	/**
 	 * A list of points (nodes) on top of the image
	 */
	public points: Node[];

    /**
	 * Constructs a Hotspot View
	 */
	constructor( model: HotspotModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		// set default values
		this.defaultTemplate = this.model.get( 'template', 'lf.hotspot-slide' );
		this.pointTemplate = this.model.get( 'spotTemplate', 'lf.hotspot-point' );
		this.hotspotAreaTemplate = this.model.get( 'hotspotAreaTemplate', 'lf.hotspot-hotspot' );
		this.pointContainerKey = this.model.get( 'pointContainerKey', 'points' );
		this.points = [];
	}

	protected init() {
		super.init();

		// bind ui
		var c = this.node.js( this.pointContainerKey );

		// check for valid container
		if ( c === null ) {
			console.log( "No point container found in HotspotView with selector: " + this.pointContainerKey, "error" );
			return;
		} else {
			this.bindUI();
			this.rerenderPoints();
		}

	}

	/**
	 * Binds the click event for adding points
	 */
	bindUI() {
		this.node.js( this.pointContainerKey ).click.add( this.onClick, this );
	}

	/**
	 * Unbinds the click event for disabling to add points
	 */
	unbindUI() {
		this.node.js( this.pointContainerKey ).click.remove( this.onClick, this );
	};

	/**
	 * Renders the points in the point container
	 */
	protected rerenderPoints() {

		var pointsContainer = this.node.js( this.pointContainerKey );

		// clear all old points
		pointsContainer.all( '.point' ).forEach(( point ) => {
			point.remove();
		});
		this.points = [];
		var points = this.model.userPoints;

		points.forEach(( point, index ) => {
			( point as any ).index = index;
			var node = Node.fromHTML( Template.render( this.pointTemplate, point ) );
			node.addClass( 'point' );
			node.addClass( 'point' + index );
			node.style.zIndex = '100';
			pointsContainer.append( node );
			this.points.push( node );
		});
	}

	/**
	 * Callback when the background is clicked
	 * @method onClick
	 */
	private onClick( n:Node, event:MouseEvent ) {

		var c = this.node.js( this.pointContainerKey );

		var cWidth = c.bounds().width;
		var cHeight = c.bounds().height;
		var cX = c.bounds().left;
		var cY = c.bounds().top;


		this.model.addPoint(( event.pageX - cX ) / cWidth, ( event.pageY - cY ) / cHeight );
		this.rerenderPoints();

		this.model.fireUserInput();
	};


	/**
	 * Renders the hotspot areas on the slide
	 * @method renderHotspotAreas
	 */
	public renderHotspotAreas() {

		// check container to render hotspots
		var c = this.node.js( this.pointContainerKey ).one( '.hotspots' );

		// if there is no container - create it.
		if ( !c ) {
			c = Node.fromHTML( '<div class="stretch hotspots" />' );
			this.node.js( this.pointContainerKey ).prepend( c );
		}

		// render the hotspots
		var hotspotAreas = this.model.hotspotAreas;
		c.empty();

		hotspotAreas.forEach(( hotspot, index ) => {
			( hotspot as any ).index = index;
			var node = Node.fromHTML( Template.render( this.hotspotAreaTemplate, hotspot ) );
			node.addClass( 'hotspot' + index );
			node.style.zIndex = '1';
			c.append( node );

		});

	};


	/**
	 * Returns an array of rendered user point nodes 
	 */
	public getPointNodes(): Node[] {
		return this.points;
	};

}

export default Hotspot;