// import lib
import View from 'ln/view/View';
import SlideModel from '../Slide/SlideModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';



class Slide extends View {

	public model:SlideModel;
	public slideRenderer:SlideRenderer;

	constructor( model:SlideModel, slideRenderer:SlideRenderer ) {
		super();
		this.model = model;
		this.slideRenderer = slideRenderer;
		this.defaultTemplate = this.model.get( 'template', 'lf.base-slide' );
	}

	protected renderData() {
		return this.model.object();
	}

	/**
	 * Returns the name for the decorator's ioc lookup
	 * This method may be overwritten by other slide types
	 */
	get decoratorKey():string {
		return this.model.modelName;
	}

}

export default Slide;