import QuestionModel from '../Question/QuestionModel';
// import { MatrixBinaryQuestion } from '../MatrixBinaryChoice/MatrixBinaryChoiceModel';

export interface MatrixLabel{
    text:string
}
/**
 * An interface for Answers
 */
export interface MatrixAnswer {
    index: number;
    selected: boolean;
    solution: number;
}

/**
 * An interface for questions
 */
export interface MatrixQuestion {
    text: string;
    index: number;
    answered: boolean;
    correct: boolean;
    answers: MatrixAnswer[];
    solution: number;
}


/**
 * A model class for MatrixChoiceSlides
 */
export class MatrixChoiceModel extends QuestionModel {
    
    
    constructor( json ) {
        super( json );
        this.setAnswers();
    };
    
    get labels():MatrixLabel[] {
        return this.get( 'labels', []) as MatrixLabel[];
    }
    
    get questions():MatrixQuestion[] {
        return this.get( 'questions', []) as MatrixQuestion[];
    }

    //adds answers to the question model if not existing
    protected setAnswers() {
        this.questions.forEach( (question) => {
            if( question.answers === undefined ) {
                question.answers = [];
                this.labels.forEach( ( label, index ) => {
                    question.answers.push( { selected: false, index: index+1, solution: question.solution } );
                });
            }

        });
    }

    //returns true if every question is answered
    public isComplete():boolean {
        var answered = true;
        this.questions.forEach( ( question ) => {
            answered = (question.answered && answered) ? true : false;
        });
        return answered;
    }

    /**
	 * Checks on the slide model if the user has already made a selection.
	 * This overwrites the default behaviour of the Question slide.
	 * @return {Boolean} True if there is already something selected on the slide model.
	 */
	public hasUserInput():boolean {
        return this.questions.some( ( question ) => {
            for( var i = 0; i < question.answers.length; i++ ) {
                if( question.answers[i].selected ) return true;			
            }
        });
    };

    /**
	 * Returns true if all the correct answers are checked and the others not
	 */
	public isCorrect():boolean {
        var result = true;
        
        // TODO evaluation not correct
        this.questions.forEach( ( question ) => { 
            var correct = true;
            var selected = false;
            for( var i = 0; i < question.answers.length; i++ ) {
                selected = selected || question.answers[i].selected;
                correct = correct && (( question.answers[i].solution === question.answers[i].index && question.answers[i].selected === true )
                            || ( question.answers[i].solution !== question.answers[i].index && question.answers[i].selected === false ));
            
                result = ( correct && result ) ? true : false;
            }
            question.correct = correct && selected;
        });
        return result;
    };


}

export default MatrixChoiceModel;