import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import Chapter from './Chapter';

class Lernpfad extends View {

    public data;

    constructor( ) {
        super( { 'template': 'lb.lernpfad' } );
    }

    init() {
        
        var listRenderer = new ListRenderer( this.node.js('chapters') );

        listRenderer.defaultRender(function( item ) {
            var view = new Chapter();
            view.setData( item );
            view.render();
            return view;
        });

        listRenderer.source.fill( this.renderData().chapters )
    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
    } 
}

export default Lernpfad;