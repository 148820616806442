// import lib
import Model from 'ln/model/Model';
import LinkedList from 'ln/linkedlist/LinkedList';
import { mixin } from 'ln/js';
import QuestionModel from '../Question/QuestionModel';
import LernFragen from '../../LernFragen';


/**
 * An interface for gaps object
 */
export interface Gaps {
	[index: string]: { answers: string[] };
}

/**
 * An interface for userInputs object
 */
export interface UserInputs {
	[key: string]: { value: string };
}



/**
 * The model of a Cloze slide
 */
class ClozeModel extends QuestionModel {

	//public gaps: { [index: string]: { answers: string[] } };
	//public userInputs: { [key: string]: { value: string } };


	/**
	 * Create a new question model instance
	 * @param obj The object to create the model from
	 */
	constructor( obj: Object = {} ) {
		super( obj );

		// this.gaps = {};
		// this.userInputs = {};

		this.parseGaps();
	}

	get gaps():Gaps {
		return this.get( 'gaps', {}) as Gaps;
	}

	set gaps( gaps:Gaps ) {
		this.set( 'gaps' , gaps );
	}

	get userInputs():UserInputs {
		return this.get( 'userInputs', {} ) as UserInputs;
	}
	
	set userInputs( userInputs:UserInputs ) {
		this.set( 'userInputs' , userInputs );
	}

	

	/**
	 * Returns true if all user inpts are correct
	 * Overrides the corresponding method of the superclass
	 */
	public isCorrect():boolean {
		var correct: boolean = true;

		Object.keys( this.gaps ).forEach( ( key ) => {
			correct = correct && this.hasCorrectInput( key );
		});

		return correct;
	}

	/**
	 * Returns true if there is a user input
	 * Overrides the corresponding method of the super class
	 */
	public hasUserInput(): boolean {
		return Object.keys( this.userInputs ).length > 0;
	}	

	/**
	 * Parses the text for gaps and builds the gap definition
	 * It replaces the text definition with gap labels instead of the gap definition.
	 */
	private parseGaps() {

		var orgText: string = this.get( 'text' ) as string;
		var found: RegExpExecArray;
		var label: string;
		var index: number = 0;

		// regex to match everything between curly bracets
		var regex = /{(.*?)}/g;

		// loop over all matches and replace cloze definitions with gap labels.
		while ( ( found = regex.exec( orgText ) ) != null ) {
			label = 'gap' + index++;
			orgText = orgText.substr( 0, found.index ) + '{' + label + '}' + orgText.substr( regex.lastIndex );
			regex.lastIndex = found.index + label.length + 2;

			var gaps = this.gaps;
			gaps[label] = { answers: found[1].split( ';' ).map( function ( value ) { return value.trim() } ) };
			this.gaps = gaps;
		}

		this.set( "parsedText", orgText );
	}

	/**
	 * Returns an array of all the gap labels.
	 */
	public getGapLabels():string[] {
		return Object.keys( this.gaps );
	};

	/**
	 * Gets a user input by gap label.
	 * @param label Gab label
	 */
	public getGapUserinput( label: string ):string {
		if( ! this.userInputs[label]) return null;
		var userInputs = this.userInputs;
		return userInputs[label].value.replace( /^\s+|\s+$|\s+(?=\s)/g, "" );
	}

	/**
	 * Set the value of a user input
	 * @param label Gap label
	 * @param value The value of the user input field
	 */
	public setGapUserinput( label: string, value: string ) {
		var userInputs = this.userInputs;

		if ( userInputs[label] ) {
			userInputs[label].value = value;
		} else {
			userInputs[label] = { value: value };
		}

		this.userInputs = userInputs;

		this.fireUserInput();
	}

	/**
	 * Get the answers of a given gap
	 * @param label Gap label
	 */
	public getGapAnswers( label: string ):string[] {
		return this.gaps[label].answers;
	}

	/**
	 * Is the user input evaluation case sensitive?
	 */
	private isCaseSensitive():boolean {
		return this.get( 'casesensitive' ) ? this.get( 'casesensitive' ) as boolean : true;
	}

	/**
	 * Returns if this cloze gaps should all have different inputs
	 */
	private isDistinct():boolean {
		return this.get( 'distinct' ) ? this.get( 'distinct' ) as boolean : false;
	};

	/**
	 * Checks if all user inputs ar distinct
	 */
	private hasDistinctInput():boolean {
		var inputs: Array<string> = [];

		Object.keys( this.userInputs ).forEach( ( key ) => {
			inputs.push( this.userInputs[key].value );
		});

		var uniques = inputs.filter( function ( value, index, items ) {
			return inputs.indexOf( value ) === index;
		} );

		return inputs.length === uniques.length;
	}

	/**
	 * Returns true if the input of the given gap is correct
	 * @param label Gap label
	 */
	public hasCorrectInput( label: string ):boolean {
		var answers = this.getGapAnswers( label );
		var input = this.getGapUserinput( label );
		var casesensitive = this.isCaseSensitive();

		// check for wrong input
		if ( !input ) return false;

		// check for distinct values
		if ( this.isDistinct() ) {
			var distinct = this.hasDistinctInput();
			if ( !distinct ) return false;
		}

		// check over answers if one was hit
		for ( var i = 0; i < answers.length; i++ ) {

			// check for casesensitivity
			if ( casesensitive ) {
				if ( input.toLowerCase() === answers[i].toLowerCase() ) return true;
			} else {
				if ( input === answers[i] ) return true;
			}
		}

		return false;
	}


};

export default ClozeModel;
