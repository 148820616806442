import Model from 'lb/models/ElementModel';

/**
 * This class generates a slug based on the id and model name
 */
class ElementModel extends Model {
    
	public parent:ElementModel;

	constructor( json:Object ){
		super( json );
	}
	
}

export default ElementModel;