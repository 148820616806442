import IoC from '../ioc/IoC';
import Model from '../model/Model';
import View from '../view/View';
import { List, ListAddedListener, ListFilledListener, ListRemovedListener } from '../list/List';
import Node from '../node/Node';
import Signal from '../signal/Signal';
import Template from '../template/TemplateManager';

/**
 * Specific interface for closure function used in the ioc
 */
export interface IoCFactoryFunction<T> {
	( item:T, index?:number ): { node:Node };
}

/**
 * A function that defines how to a map the registered keywords in the ioc with the item:T
 * Example if T is Model:
 * 		function( model:Model ) {
 * 			return model.modelName;
 * 		}
 *  
 */
export interface IoCSelectorFunction<T> {
	( item: T ): string;
}


/**
 * An interface for links that combine the node and its item
 */
export interface ILink<T> {
	node: Node;
	item: T;
}

/**
 * An interface that defines what is required as a source for the ListRenderer 
 */
export interface ListRendererSource<T> {
	added:Signal<ListAddedListener<T>>;
	removed:Signal<ListRemovedListener<T>>;
	filled:Signal<ListFilledListener<T>>;
	sorted:Signal<ListFilledListener<T>>;
	all():T[];
	fill( data:T[] );
}


/**
  * Renders a list with items of type T into a template. Listens on list signals for rerendering.
  * The rendering of the list items is defined through an ioc of for simpler usage with a render function.
  * 
 */
export class ListRenderer<T,G extends ListRendererSource<T> = List<T>> {

	public ioc: IoC<IoCFactoryFunction<T>> = new IoC<IoCFactoryFunction<T>>();
	public selectorFunction: IoCSelectorFunction<T>;
	public links = new List<ILink<T>>();
	public container:Node;

	protected _source:G;
	 
	constructor( container?:Node ) {
		this.container = container;

		// the default selector function to use without ioc definitions.
		this.selectorFunction = function( item:T ) {
			return ( item instanceof Model ) ? item.get<string>( 'modelName' ) : 'default';
		}

		this.source = new List<T>( [] );
	}
	
	get source():G {
		return this._source;
	}
	
	set source( items:G ) {
		if( this._source ) this.removeListeners();
		this._source = items;
		this.addListeners();
		
		if( this.container ) this.onFilled( items.all() );
	}

	public defaultRender( closure:IoCFactoryFunction<T> ) {
		this.ioc.add( 'default', closure );
	}
	
	protected addListeners(){
		this._source.filled.add( this.onFilled, this );
		this._source.added.add( this.onAdded, this );
		this._source.removed.add( this.onRemoved, this );
		this._source.sorted.add( this.onSorted, this );
	}
	
	protected removeListeners(){
		this._source.filled.remove( this.onFilled, this );
		this._source.added.remove( this.onAdded, this );
		this._source.removed.remove( this.onRemoved, this );
		this._source.sorted.remove( this.onSorted, this );
	}
	
	protected renderList( items:T[] ) {

		var fragment = document.createDocumentFragment();
		
		items.forEach( function( item, index ) {

			var obj = <ILink<T>>this.ioc.get( this.selectorFunction( item ) )( item, index );
			obj.item = item; // inject item
			this.links.append( obj );

			// add to fragment
			fragment.appendChild( ( obj.node instanceof Node ) ? obj.node.native : obj.node );
		}, this);

		// append fragment to container.
		this.container.append( fragment );
	}

	protected onFilled( items:T[] ) {
		// remove all links
		this.links.all().forEach( link => {
			link.node.remove();
		});
		
		this.links.empty();	
		this.renderList( items );
	}

	protected onAdded( newItem:T, newIndex:number ) {
		var obj = <ILink<T>>this.ioc.get( this.selectorFunction( newItem ) )( newItem, newIndex );
		obj.item = newItem;
		this.container.insert( obj.node, newIndex );
		this.links.add( obj , newIndex );
	} 

	protected onRemoved( removedItem:T, index:number ) {
		var link = this.links.get( index );
		this.links.removeAt( index );
		this.container.removeChild( link.node );
		link.node = undefined;
	}

	protected onSorted( items:T[] ) {
		var links = [];
		for( var i = 0; i < items.length; i++ ) {
			var link = this.linkOf( items[i] );
			this.container.insert( link.node, i );
			links.push( link );
		}
		this.links.fill( links );
	}

	public linkOf( item:T | Node ):ILink<T> {

		// define on which attribute to check
		var attr = ( item instanceof Node ) ? "node" : "item";

		return this.links.find( function( link ) {
			return link[attr] === item; 
		});
	}
}

export default ListRenderer;
