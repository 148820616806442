// import lib
import ClozeModel from '../Cloze/ClozeModel';

/**
 * An interface for gaps object
 */
export interface Gaps {
	[index: string]: { answers: string[], correct?: string };
}


/**
 * The model of a DropDown slide
 */
class DropDownModel extends ClozeModel {

    constructor( obj: Object = {}) { //todo: save correct answer as string in userInputs (and don't use str_replace anymore...*) for dropdown
		super( obj );
		this.setCorrectAnswer();
	}

	get gaps():Gaps {
		return this.get( 'gaps', {}) as Gaps;
	}

	set gaps( gaps:Gaps ) {
		this.set( 'gaps' , gaps );
	}

	protected setCorrectAnswer() {

		Object.keys( this.gaps ).forEach( ( key ) => {
			this.gaps[ key ].answers.forEach( function( answer, index) {
				if (answer.match(/\*/)) {
					answer = answer.replace(/\*/, '');
					this.gaps[ key ].answers[ index ] = answer;
					this.gaps[ key ].correct = answer;
				}
			}, this);
		});
	}
	
	/**
	 * Returns true if the input of the given gap is correct
	 * @param label Gap label
	 */
	public hasCorrectInput( label: string ):boolean {
		var answers = this.getGapAnswers( label );
		var input = this.getGapUserinput( label );

		// check for wrong input
		if ( !input ) return false;

		if (input === this.gaps[ label].correct ) return true;

		return false;
	}

}


export default DropDownModel;