// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';

// import project
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import QuestionModel from '../slides/Question/QuestionModel';
import SlideRenderer from '../sliderenderer/SlideRenderer';
import NextPrevNavigation from './NextPrevNavigation';
import LernFragen from '../LernFragen';


export interface NextSolutionNavigationConfig {
	skipInputCheck?: boolean;
	forceCorrectAnswer?: boolean;
}

/**
 * A navigation that shows a deactivated "solution" button instead of the "next" button. A user input activates the 
 * "solution" button. By clicking on the "solution" button it is turned into a "next" button and the solution is shown
 */
class NextSolutionNavigation extends NextPrevNavigation {

	private skipInputCheck: boolean;
	private forceCorrectAnswer: boolean;

	constructor( slideRenderer: SlideRenderer, config: NextSolutionNavigationConfig = {}) {

		super( slideRenderer );


		/**
		 * The template for this navigation
		 * @default lf.next-solution-navigation
		 */
		this.defaultTemplate = 'lf.next-solution-navigation';

		/**
		 * Flag to skip the input check and thus always allow showing the solution, even without input
		 * @default false
		 */
		this.skipInputCheck = ( config.skipInputCheck != undefined ) ? config.skipInputCheck : false;

		/**
		 * Flag to to force a right answer. Next button gets only active, when the question is corretly answered
		 * @default false
		 */
		this.forceCorrectAnswer = ( config.forceCorrectAnswer != undefined ) ? config.forceCorrectAnswer : false;

	}


	/**
	 * Render the template for the navigation
	 */
	public init() {

		super.init();

		// register signals on slide renderer
		this.slideRenderer.userInput.add( this.onUserInput, this );
		this.slideRenderer.userAnswered.add( this.onAnswered, this );
	}


	protected onSlideChanged( currentModel: SlideModel, currentView: SlideView ) {

		// check if nav button has to be hidden or shown
		this.prevButton.toggleClass( '-disabled', currentModel.previous == null );

		// setup next button
		// what kind of slide do we have?
		if ( this.slideRenderer.currentModel instanceof QuestionModel ) {
			this.setupSolutionButton();
		} else {
			this.setupNextButton();
		}
	}

	/**
	 * setups the next button with the solutionLabel and the solution callback
	 */
	private setupSolutionButton() {

		this.nextButton.click.remove( this.onSolution, this );
		this.nextButton.click.remove( this.onNext, this );

		var hasInput = this.slideRenderer.currentModel.hasUserInput() || this.skipInputCheck;

		if ( hasInput ) {
			this.nextButton.click.add( this.onSolution, this );
			this.nextButton.addClass( '-solve' );
		}

		this.nextButton.toggleClass( '-disabled', !hasInput );
	};

	/**
	 * setups the next button with the nextLabel and the next callback
	 */
	private setupNextButton() {

		this.nextButton.click.remove( this.onSolution, this );
		this.nextButton.click.remove( this.onNext, this );
		this.nextButton.removeClass( '-solve' );


		// check if there is a next slide.
		var hasNext = this.slideRenderer.currentModel.next != null

		// check if the question is correctly answerded. Always true, if its not a question slide
		// or the forceCorrectAnswer flag is not set
		var correct = true;
		if ( this.slideRenderer.currentModel instanceof QuestionModel ) {
			correct = ( this.slideRenderer.currentModel as QuestionModel ).isCorrect() || !this.forceCorrectAnswer;
		}


		if ( hasNext && correct ) this.nextButton.click.add( this.onNext, this );
		
		this.nextButton.toggleClass( '-disabled', !hasNext || !correct );
	};

	/**
	 * Callback when the solution button is clicked.
	 * Mark the current question as answered.
	 */
	private onSolution() {

		// make the slide as answered.
		// this will fire the answered event.
		this.slideRenderer.currentModel.markAsAnswered();

	};

	/**
	 * Callback when the user selects something on the question.
	 * If the question is already answered we will adjust decoration otherwise enable solution button.
	 */
	private onUserInput() {

		// enable solution button.
		this.setupSolutionButton();
	};

	/**
	 * Callback when the question is marked as answered.
	 * We will do the decoration and then setup the next button to jump to next slide.
	 */
	private onAnswered() {

		// now change to the next button
		this.setupNextButton();
	};



}
export default NextSolutionNavigation;