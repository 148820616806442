import Node from '../../core/node/Node';
import Model from '../../core//model/Model';
import ModelFilter from './ModelFilter';

class TermFilter extends ModelFilter {

    public ignoreCase: boolean;
    public startSearchAtChar: number;

    constructor( searchAttributes: string[] ) {
        super( searchAttributes );
        this.defaultTemplate = 'ui.filter-term';
        this.ignoreCase = false;
        this.startSearchAtChar = 3;
    }

    init() {
        super.init();
        this.node.js( 'term' ).keyup.add(this.onInput, this);
    }

    onInput( node:Node ) {
        var length = node.value.length;
        if( length >= this.startSearchAtChar || length == 0 ) this.change.dispatch();
    }

    reset() {
        this.node.js( 'term' ).value = "";
    }


    filter( models: Model[] ):Model[] {

        if ( !this.node.js( 'term' ) ) return

        var term = this.node.js( 'term' ).value;
        if ( term.length == 0 ) return models;

        return models.filter( ( model: Model ) => {

            return this.searchAttributes.some( (attribute) => {

                return ( this.ignoreCase )
                    ?  model.get<string>( attribute ).toUpperCase().indexOf( term.toUpperCase() ) >= 0
                    :  model.get<string>( attribute ).indexOf( term ) >= 0;
            });

        } );
    }

}

export default TermFilter;