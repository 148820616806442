// import lib
import { isType } from 'ln/js';
import Node from 'ln/node/Node';
import Model from '../slides/Slide/SlideModel';

// import project
import ContainerFeedback from './ContainerFeedback';
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import QuestionModel from '../slides/Question/QuestionModel';
import QuestionView from '../slides/Question/Question';
import AnswerListModel from '../slides/AnswerList/AnswerListModel';
import AnswerListView from '../slides/AnswerList/AnswerList';
import Question from '../slides/Question/Question';


export interface RelatedFeedbackConfig {
	posFeedbackProperty?: string;
	negFeedbackProperty?: string;
	posFeedback?: string;
	negFeedback?: string;
}


class RelatedFeedback extends ContainerFeedback {

	private posFeedbackProperty: string;
	private negFeedbackProperty: string;
	private posFeedback: string;
	private negFeedback: string;


	constructor( config: RelatedFeedbackConfig ) {
		super( {});

		/**
		 * The name of the property to lookup of the model for a positive feedback 
		 * @default ''
		 */
		this.posFeedbackProperty = ( config.posFeedbackProperty ) ? config.posFeedbackProperty : 'positiveFeedback';

		/**
		 * The name of the property to lookup of the model for a negative feedback 
		 * @default ''
		 */
		this.negFeedbackProperty = ( config.negFeedbackProperty ) ? config.negFeedbackProperty : 'negativeFeedback';

		/**
		 * The fallback positive feedback that is shown for decorator if no posFeedbackProperty is found on the model
		 * @default This is correct!
		 */
		this.posFeedback = ( config.posFeedback ) ? config.posFeedback : 'This is correct!';

		/**
		 * The fallback negative feedback that is shown for decorator if no negFeedbackProperty is found on the model
		 * @default This is wrong!
		 */
		this.negFeedback = ( config.negFeedback ) ? config.negFeedback : 'This is wrong!';

	}
	
	/**
	 *  Decorates the question slides with a container to show if the user has correctly answered the question.
	 */
	public decorate(  slideModel:SlideModel, slideView:SlideView ) {
		if( slideModel instanceof QuestionModel ) {
			this.showFeedback( slideView.node, (slideModel as QuestionModel).isCorrect(), slideModel );
		}
	}

	/**
	 * Shows the feedback and marks it correct/wrong
	 * @param correctlyAnswered True if the question was correctly answered false otherwise.
	 * @param parentNode The parent node where the container is added.
	 * @param model The model to read the model specific feedback properties
	 */
	public showFeedback( parentNode: Node, correctlyAnswered: boolean, model: Model ) {

		var feedback = ( correctlyAnswered ) ? this.getFallbackFeedback( model.get( this.posFeedbackProperty ), this.posFeedback ) : this.getFallbackFeedback( model.get( this.negFeedbackProperty ), this.negFeedback );

		// only show feedback if there is any.
		if ( feedback ) {
			this.showContainer( parentNode, feedback );
			this.clearSolutionOn( this.getContainer( parentNode ) );
			this.decorateSolutionOn( this.getContainer( parentNode ), correctlyAnswered );
		}
	};

	/**
	 * Returns the fallback feedback based on its type.
	 * If its an array it takes a random one.
	 * If string it returns the string.
	 */
	private getFallbackFeedback = function ( modelValue, fallback ) {
		if ( isType( modelValue, "String" ) && modelValue.length > 0 ) return modelValue;
		if ( isType( fallback, "String" ) ) return fallback;
		if ( isType( fallback, "Array" ) ) return fallback[Math.floor( Math.random() * fallback.length )];
	}

}

export default RelatedFeedback;

