import Node from 'ln/node/Node';
import Question from '../Question/Question';
import SlideRenderer from '../../sliderenderer/SlideRenderer';
import { MatrixQuestion, MatrixLabel } from './MatrixChoiceModel';
import MatrixChoiceModel from './MatrixChoiceModel';

/**
 * A slide that can shows multiple singlechoice elements with multiple possible answers.
 * 
 * 			{
 *              "id": 15,
 *              "title": "matrix",
 *              "text": "matrix demo",
 *              "posFeedback": "positiv",
 *              "negFeedback": "negativ",
 *              "modelName": "MatrixChoice",
 *              "labels": [
 *                  { "id": 3, "text": "richtig", "modelName": "MatrixLabel" },
 *                  { "id": 4, "text": "falsch", "modelName": "MatrixLabel" }
 *              ],
 *              "questions": [
 *                  { "id": 2, "text": "1+1=2", "solution": 1, "modelName": "MatrixQuestion" },
 *                  { "id": 3, "text": "2+1=4", "solution": 2, "modelName": "MatrixQuestion" }
 *              ]
 *          }
 */

class MatrixChoice extends Question {
	
	public model:MatrixChoiceModel;


	constructor( model: MatrixChoiceModel, slideRenderer: SlideRenderer ) { 
        
        super( model, slideRenderer );
		this.defaultTemplate = this.model.get( 'template', 'lf.matrixchoice-slide' );
	}

	protected init() {
		super.init();
        this.registerAnswers();
	}

    //(re)set the answers according to model and register click handler
    protected registerAnswers() {
        this.node.all('.matrix-input').forEach( (node:Node ) => {
            var question = this.model.questions[node.data.question];

            (node.native as HTMLInputElement ).checked = question.answers[node.data.index-1].selected;
            node.click.add(this.onClick, this);
        });
    }

    protected onClick( node:Node ){
        //unset question row
       node.parent().parent().all('.matrix-input').forEach( ( input) => {
            (input.native as HTMLInputElement ).checked = false;
            this.model.questions[input.data.question].answers[input.data.index-1].selected = false;
            this.model.questions[input.data.question].answered = false;
       });
       //set selected checkobox
        (node.native as HTMLInputElement ).checked = true;
        this.model.questions[node.data.question].answers[node.data.index-1].selected = true;
        this.model.questions[node.data.question].answered = true;

        this.model.fireUserInput();
    }

}

export default MatrixChoice;