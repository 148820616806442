/**
	* A class to register closure functions under a given key
	* ...
	*/
class IoC<T>{
	
	private map:{ [index:string]: T };
	
	constructor(){
		this.map = {};
	}
	
	/**
	* Registers the given closure under the given key
	* @param key The key to register the closure
	* @param closure The closure function
	*/
	public add( key:string, closure:T ){
		this.map[ key ] = closure;
	}
	
	/**
	* Returns the registered closure under the given key
	* @param key The key of registerd closure to look for
	* @return The closure under the given key or undefined, if
	* the key does not exist
	*/
	public get( key:string ):T{
		var tmp = this.map[ key ];
		tmp = ( tmp ) ? tmp : this.map[ 'default' ];

		if( !tmp ) throw new Error( "No key with name: '" + key + "' is registered and no default fallback is defined on ioc." );
		
		return tmp;
	}
	
	/**
	* Returns this key is registered allready
	* @param key The key to check if it is registered allready
	*/
	public has( key:string ):boolean{
		return this.map[ key ] != undefined;
	}
	
	/**
	* Returns all registered keys
	* @return Array of all the registered keys
	*/
	public keys():string[] {
		return Object.keys( this.map );
	}

	/**
	 * Creates an alias for an already registered closure
	 * @param newName The alias name
	 * @param oldName The name of the already registered function
	 */
	public alias( newName:string, oldName:string ) {
		this.map[ newName ] = this.map[ oldName ];
	}

	/**
	 * Renames an already registered closure to a new name
	 * @param newName The new name 
	 * @param oldName The old registered name 
	 */
	public rename( newName:string, oldName:string ) {
		this.alias( newName, oldName );
		delete this.map[ oldName ];
	}
}

export default IoC;