// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
// import project
import Question from '../Question/Question';
import ClozeModel from '../Cloze/ClozeModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A class that represents a cloze question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		"modelName":"Cloze",
 *		"title": "Lückentext Demo",
 *		"description": "Fill the gaps with the correct words",
 *		"text": "",
 *	}
 * 
 */
class Cloze extends Question {
	/**
	 * The model
	 */
	public model: ClozeModel;

	/**
	 * The slide template
	 */
	public defaultTemplate: string;

	/**
	 * The gap template
	 */
	public gapTemplate: string;

	/**
	 * An array of all gaps
	 */
	public gaps: Array<Node> = [];

	constructor( model: ClozeModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		// set default values
		this.defaultTemplate = this.model.get( 'template', 'lf.cloze-slide' );
		this.gapTemplate = this.model.get( 'gap-template', 'lf.cloze-gap' );
	}

	protected init() {
		super.init();
		this.renderInputs();
		this.bindUI();
	}

	/**
	 * Renders the inputfields on the given text
	 */
	protected renderInputs() {
		var c = this.node.js( 'text' );
		var gaps = this.model.getGapLabels();
		var replace = {};
		var repl = [];

		for ( var i = 0; i < gaps.length; i++ ) {

			var obj = { label: gaps[i], value: '' };
			obj.value = this.model.getGapUserinput( obj.label );

			var node = Node.fromHTML( Template.render( this.gapTemplate, obj ) );

			node.setAttribute( 'name', obj.label );
			node.addClass( 'gap' );
			node.addClass( obj.label );
			node.addClass( this.getInputSizeClass( obj.label ) );

			var tempNode = Node.fromHTML( '<div/>' );
			tempNode.append( node );

			replace[obj.label] = tempNode.toString();
			repl.push( { "label": obj.label, "value": node.toString() });

			this.gaps.push( node );
		}

		var template: string = c.toString();
		for ( var i = 0; i < repl.length; i++ ) {
			template = template.replace( "{" + repl[i].label + "}", repl[i].value );
		}
		c.html = template;
	}

	/**
	 * Binds the UI
	 */
	protected bindUI() {
		this.model.getGapLabels().forEach( function ( gap ) {
			var node = this.node.one( '.' + gap );
			if(node) node.native.addEventListener( 'input', this.onValueChanged.bind( this ) );
		}, this );
	}

	/**
	 * Unbinds the UI
	 */
	protected unbindUI() {
		this.model.getGapLabels().forEach( function ( gap ) {
			var node = this.getNodeByLabel( gap );
			if( node ) node.native.removeEventListener( 'input' );
		}, this );
	}

	/**
	 * Get a Node by its label
	 * @param label Gap label
	 */
	public getNodeByLabel( label: string ) {
		return this.node.one( '.' + label );
	}

	private onValueChanged( event ) {
		this.model.setGapUserinput( event.target.getAttribute( 'name' ), event.target.value );
	}

	private getInputSizeClass( label ) {

		var answers = this.model.getGapAnswers( label );
		var maxLength = answers.reduce( function ( previousValue, currentValue ) {
			return previousValue.length > currentValue.length ? previousValue : currentValue;
		}).length;

		var cssClass = 'large';
		if ( maxLength <= 7 ) cssClass = 'medium';
		if ( maxLength <= 2 ) cssClass = 'small';

		return cssClass + ' gap-' + maxLength;
	};

}
export default Cloze;
