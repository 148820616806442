// import library
import Model from 'ln/model/Model';
import SlideModel from '../Slide/SlideModel';
import LernFragen from '../../LernFragen';
import CorrectWrongAggregator from '../../aggregators/CorrectWrong';



/**
 * The model for a summary slide
 */
export class SummaryModel extends SlideModel {


	/**
	 * Return a clone of this object
	 */
	public object():any {
       var object = super.object() as any ;

       var aggregator = new CorrectWrongAggregator();
	   var slides = this.toArray() as SlideModel[];
	   
	   aggregator.aggregate( slides );

       object.total_slides = aggregator.totalSlides;
       object.correct_slides = aggregator.correctSlides;
	   object.wrong_slides = aggregator.wrongSlides;
	   
	   return object;
	}


}

export default SummaryModel;
