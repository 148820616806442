import View from 'ln/view/View';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import { mapper } from 'lb/LernBuch';
import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import Materiallist from './Materiallist';

class Article extends View {
    public data;

    constructor( ) {
        super( { 'template': 'lb.article' } );
    }

    init() {

        var data = mapper.model( setup.data( 'article' ))
        var listRendererContent = new ListRenderer( this.node.js( 'content' ));

        listRendererContent.ioc.add( 'default', function ( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });

        listRendererContent.ioc.add( 'App\\Materiallist', function ( model:ElementModel ) {
            return new Materiallist( model ).render() as Element;
        });

        listRendererContent.source.fill( data.elements )
    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
        return this;
    } 
}

export default Article;

scanner.ioc.add( 'article', function( node:Node, data ) {
    return new Article( ).setData( data).render( node );
});