// import lib
import Model from 'ln/model/Model';
import LinkedList from 'ln/linkedlist/LinkedList';
import { mixin } from 'ln/js';

import QuestionModel from '../Question/QuestionModel';
import LernFragen from '../../LernFragen';

/**
 * The model of a Freetext slide
 */
class FreetextModel extends QuestionModel {


	public hasUserInput(): boolean {
		return this.value !== undefined && this.value.length > 0;
	}

	set value( v:string ) {
		this.set( 'value', v );
		this.fireUserInput();
	}

	get value(): string {
		return this.get( 'value' ) as string;
	}

	/**
	 * Only mark as correct if there is input.
	 */
	public isCorrect(): boolean {
		return this.hasUserInput();
	}
	
}

export default FreetextModel;