import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import Taskitem from './Taskitem';
import Task from './Task';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import ElementModel from '../models/ElementModel';


class Chapter extends View {
    public data;
    private count = 0;
    private open = false

    constructor( ) {
        super( { 'template': 'lb.chapter' } );
    }

    init() {
        var listRenderer = new ListRenderer( this.node.js('tasks') );

        listRenderer.defaultRender(function( item ) {
            var view = new Taskitem();
            view.setData( item );
            view.clicked.add( function( taskitem:Taskitem ) {
                if( taskitem.checked ) {
                    this.count ++;
                } else {
                    this.count --;
                }
                this.setCounter()
            }, this)
            view.render();
            return view;
        }.bind( this ));

        listRenderer.source.fill( this.renderData().tasks )

        listRenderer.links.all().forEach( function( link ) {
            var item = link;
            item.data.parent = this.data.data;
        }, this)

        this.node.js( 'toggle' ).click.add( function() {
            if( ! this.open ) {
                this.showTasks();
            } else {
                this.hideTasks()
            }
        }, this)

        this.setCounter()
        if( this.loadLocalstorageUserData( 'chapter-state') == 'closed') {
            this.hideTasks();
        }else{
            this.showTasks();
        }
    }

    renderData() {
        return this.data.data;
    }

    showTasks() {
        this.node.js( 'toggle' ).addClass( 'open' )
        this.node.js( 'tasks' ).removeClass( 'hidden' )
        this.open = true;
        this.saveLocalstorageUserData( 'chapter-state', 'open' );
    }

    hideTasks() {
        this.node.js( 'toggle' ).removeClass( 'open' )
        this.node.js( 'tasks' ).addClass( 'hidden' )
        this.open = false;
        this.saveLocalstorageUserData( 'chapter-state', 'closed' );
    }

    setData( data:any) {
        this.data.data = data;
    }

    setCounter() {
        var total = this.renderData().tasks.length
        var count = this.count
        this.node.js( 'counter' ).html = count + '/' + total;
        this.node.js( 'progress' ).setAttribute( 'style', 'background-position:' + (100-(count/total*100)) + '% 0' )
    }

    saveLocalstorageUserData( attribute, value ) {
        localStorage.setItem( this.data.data.id + '-' + attribute, value );

    }

    loadLocalstorageUserData( attribute ) {
        return localStorage.getItem( this.data.data.id + '-' + attribute );
    }    

}

export default Chapter;