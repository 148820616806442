import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';

import { mapper } from 'lb/LernBuch';
import { defaultsetup } from 'lb/setup/defaultsetup';
import { ioc as chapterIoC } from 'lb/elements/Chapter';
import setup from 'ln/setup/setup';
import { SetupConfig } from 'ln/setup/setup';
import './views/Overview';
import './views/Header';
import './views/Footer';
import './views/Thema';
import './views/Task';
import './views/Article';
import './views/Materialpool';
import './views/MaterialcardImage';
import Materiallist from './views/Materiallist';
import './views/SaveDialog';

import './templates/elements';
import './templates/lernbuch';
import './templates/icons';
import './templates/lernfragen';
import './templates/slides';
import ElementModel from './models/ElementModel';
import LbElementModel from 'lb/models/ElementModel';
import Element from 'lb/elements/Element';
import View from 'ln/view/View';
import Lang from 'ln/lang/Lang';
import MultipleChoiceModel from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import SingleChoiceModel from 'lf/slides/SingleChoice/SingleChoiceModel';
import SlideModel from 'lf/slides/Slide/SlideModel';


export function init( config:SetupConfig ){
	
	setup.init( config )
	//defaultsetup();
	Lang.add( config.data.lang );

	Template.context.setup = setup;
	Template.context.imagePath = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
		if( image === null) return "";
		return decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() );
	}

	Template.context.taskDimensions = function( dimensions:any ) {
		var classes = dimensions.join(" -show-").split("ö").join("oe");
		if( classes != "" ) classes = "-show-" + classes;

		return classes;
	}

	Template.context.downloadFile = function( file_name:string ){
		if( file_name === null) return "";
		return '/download/' + file_name;
		//return decodeURIComponent( setup.route( 'download', { file_name: file_name } ).url() );
	}

	Template.context.taskPath = function( themeSlug:string, lernpfadSlug:string, chapterSlug:string, taskSlug:string ) {
		return decodeURIComponent( setup.route( 'task', { themeSlug: themeSlug, lernpfadSlug: lernpfadSlug, chapterSlug: chapterSlug, taskSlug: taskSlug }).url() );
	}

	Template.context.themaPath = function( themeSlug:string ) {
		return decodeURIComponent( setup.route( 'thema', { thema: themeSlug }).url() );
	}

	mapper.toModel.add( 'App\\Thema', function( obj ) {
		var model = new ElementModel( obj );

		var tasks:Array<ElementModel> = model.get( 'lernpfade' );
		if( tasks ) {
			tasks.forEach( function( child ) {
				child.parent = model;
			}, this)
		}
			
		return model;
	});

	mapper.toModel.add( 'App\\Lernpfad', function( obj ) {
		var model = new ElementModel( obj );
		var chapters:Array<ElementModel> = model.get( 'chapters' );
		chapters.forEach( function( child ) {
			child.parent = model;
		}, this)

		return model;
	});

	mapper.toModel.add( 'App\\Chapter', function( obj ) {
		var model = new ElementModel( obj );
		model.tasks.forEach( function( child ) {
			child.parent = model;
		}, this)

		return model;
	});

	mapper.toModel.add( 'default', function( obj ) {
		return new ElementModel( obj );
	});

	mapper.toModel.add( 'App\\SingleChoice', function ( json ) {
		return new SingleChoiceModel( json );
	});
	
	mapper.toModel.add( 'App\\MultipleChoice', function ( json ) {
		return new MultipleChoiceModel( json );
	});


	scanner.scan( Node.fromNative( document.body ), config.data, [ 'overview', 'savedialog', 'thema', 'task', 'article', 'materialienpool' ] );

};