// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';
import QuestionModel from '../slides/Question/QuestionModel';
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import SlideRenderer from '../sliderenderer/SlideRenderer';
import LernFragen from '../LernFragen';


export interface NextPrevNavigationConfig {
	nextButtonKey?: string;
	prevButtonKey?: string;
}

/**
 * A simple navigation that allows browsing through the slides without any contrains  
 */
class NextPrevNavigation extends View {

	protected slideRenderer: SlideRenderer;
	
	protected nextButton: Node;
	protected prevButton: Node;

	constructor( slideRenderer: SlideRenderer ) {
		super();

		this.slideRenderer = slideRenderer;
		this.defaultTemplate = 'lf.next-prev-navigation';
	}

	/**
	 * Render the template for the navigation
	 */
	public init() {

		this.nextButton = this.node.js( 'next-button' );
		this.prevButton = this.node.js( 'prev-button' );

		// register listeners
		this.prevButton.click.add( this.onPrevious, this );
		this.nextButton.click.add( this.onNext, this );

		// register signals on slide renderer
		this.slideRenderer.slideChanged.add( this.onSlideChanged, this );
	}

	protected onPrevious() {
		this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.previous as SlideModel );
	}

	protected onNext() {
		this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.next as SlideModel );
	}

	protected goto( current:SlideModel, next:SlideModel ) {

		// check if question and not answered
		if( current.hasUserInput() && current instanceof QuestionModel && !current.answered ) {
			current.markAsAnswered();
		}

		this.slideRenderer.show( next );
	}

	protected onSlideChanged( currentModel: SlideModel, currentView: SlideView ) {
		// check if one of the nav buttons has to be hidden or shown
		this.nextButton.toggleClass( '-disabled', currentModel.next == null );
		this.prevButton.toggleClass( '-disabled', currentModel.previous == null );
	}

}

export default NextPrevNavigation;