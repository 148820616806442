import Signal from '../signal/Signal';
import { List, ListAddedListener, ListFilledListener, ListRemovedListener } from './List';


export interface ModifierClosure<T> {
	( items:T[] ):T[]
}
/**
 * A generic representation of a modifiable (fiterable, sortable) list. 
 * 
 */
export class ModifiableList<T> {

	public added:Signal<ListAddedListener<T>>;
	public removed:Signal<ListRemovedListener<T>>;
	public filled:Signal<ListFilledListener<T>>;	
	public sorted:Signal<ListFilledListener<T>>;	
	
	public modifiers:List<ModifierClosure<T>>;
	public originals:List<T>;

	private cache:List<T>;

	constructor( items?:T[] ) {

		this.added = new Signal<ListAddedListener<T>>();
		this.removed = new Signal<ListRemovedListener<T>>();
		this.filled = new Signal<ListFilledListener<T>>();
		this.sorted = new Signal<ListFilledListener<T>>();

		this.modifiers = new List<ModifierClosure<T>>();
		this.modifiers.added.add( this.onModifiersChanged, this );
		this.modifiers.removed.add( this.onModifiersChanged, this );
		this.modifiers.filled.add( this.onModifiersChanged, this );

		this.originals = new List<T>( items );
		this.originals.added.add( this.onOriginalsAdded, this );
		this.originals.removed.add( this.onOriginalsRemoved, this );
		this.originals.filled.add( this.onOriginalsFilled, this );

		this.update();
	}
	
	/**
	 * Returns only the modified items  
	 */
	all():T[] {
		return this.cache.all();
	}

	/**
	 * Delegate
	 */
	fill( items:T[] ) {
		this.originals.fill( items );
	}

	/**
	 * Delegate
	 */
	sort( items:T[] ) {
		this.originals.sort( items );
		this.sorted.dispatch( items );
	}

	/**
	 * forces to reapply modifiers
	 */
	update() {
		this.buildCache();
		this.filled.dispatch( this.cache.all() );
	}

	/**
	 * Callback when the modifies list has changed.
	 */
	private onModifiersChanged() {
		this.update();
	}
	
	/**
	 * Callback when something is added to the originals
	 * Check it needs to be added to the modified items
	 */
	private onOriginalsAdded( item:T, index:number ) {
		this.buildCache();

		// check if it should be displayed
		var modIndex = this.cache.index( item );
		if( modIndex >= 0 ) this.added.dispatch( item, modIndex );
	}
	
	/**
	 * Callback when something is removed from the originals
	 * Check it needs to be removed from the modified items
	 */
	private onOriginalsRemoved( item:T, index:number ) {

		var oldIndex = this.cache.index( item );

		this.buildCache();

		if( oldIndex >= 0 ) this.removed.dispatch( item, oldIndex );
	}

	/**
	 * Callback when list is filled
	 */
	private onOriginalsFilled( item:T[] ) {
		this.onModifiersChanged();
	}


	/**
	 * Updates the results of the modifications into the cache var
	 */
	private buildCache() {

		var data = this.originals.all();
		this.modifiers.all().forEach( function( modifier ) {
			data = modifier( data );			
		});

		this.cache = new List( data );
	}
}

export default ModifiableList;
