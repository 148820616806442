import QuestionModel from '../Question/QuestionModel';
import LernFragen from '../../LernFragen';

/**
 * An interface for drag items
 */
export interface Drag {
	text: string;
	target: number;
	dropped: number;
}

/**
 * An interface for drop items
 */
export interface Drop {
}


/**
 * A model class for DragDrop slides.
 */
export class DragDropModel extends QuestionModel {

	/**
	 * The constructor
	 */
	constructor( json ) {
		super( json );
		this.complete();
	}

	/**
	 * Complete and adjusts the model
	 */
	public complete() {
		// make shure that the dropped and target value is set correctly
		this.drags.forEach( ( drag ) => {
			if( drag.dropped === undefined ) {
				drag.dropped = 0;
			}
			if( drag.target == undefined || drag.target < 0 ) {
				drag.target = 0;
			}
		});
	}

	public hasUserInput():boolean {
		for( var i = 0; i < this.drags.length; i++ ) {
			if( this.drags[i].dropped > 0 ) return true;
		}
		return false;
	};

	public isCorrect():boolean {
		for( var i = 0; i < this.drags.length; i++ ) {
			if( this.drags[i].dropped != this.drags[i].target ) { return false };
		}

		return true;
	};

	get drags():Drag[] {
		return this.get( 'drags', []) as Drag[];
	}

	get drops():Drop[] {
		return this.get( 'drops', []) as Drop[];
	}


}

export default DragDropModel;