// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';

// import project
import Question from '../Question/Question';

import SlideRenderer from '../../sliderenderer/SlideRenderer';

import DropDownModel from '../DropDown/DropDownModel';
import Cloze from '../Cloze/Cloze';

/**
 * A class that represents a dropdown question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		"modelName":"DropDown",
 *		"title": "DropDown Demo",
 *		"description": "choose the correct word for the gaps",
 *		"text": ""Fill the gaps with the {correct*;wrong} words",",
 *	}
 * 
 */
class DropDown extends Cloze {
	/**
	 * The model
	 */
	public model: DropDownModel;

	/**
	 * The slide template
	 */
	public defaultTemplate: string;

	/**
	 * The select template
	 */
	public selectTemplate: string;
	public optionTemplate: string;

	public gaps: Array<Node> = [];

	constructor(model: DropDownModel, slideRenderer: SlideRenderer) {
		super(model, slideRenderer);

		// set default values
		this.defaultTemplate = this.model.get('template', 'lf.dropdown-slide');
		this.selectTemplate = this.model.get('select-template', 'lf.dropdown-select');
		this.optionTemplate = this.model.get('option-template', 'lf.dropdown-option');
	}

	/**
	 * Renders the inputfields on the given text
	 */
	protected renderInputs() {
		var c = this.node.js('text');
		var gaps = this.model.getGapLabels();
		var replace = {};
		var repl = [];

		for (var i = 0; i < gaps.length; i++) {

			var obj = { label: gaps[i], value: Object };
			var selectNode = Node.fromHTML(Template.render(this.selectTemplate, obj));
			var answers = this.model.getGapAnswers(gaps[i]);

			var userAnswer = this.model.getGapUserinput(obj.label);

			for (var j = 0; j < answers.length; j++) {

				var answer = {
					'text': answers[j],
					'label': gaps[i]
				};

				var optionNode = Node.fromHTML(Template.render(this.optionTemplate, answer));
				optionNode.setAttribute('name', obj.label);
				optionNode.addClass('dropdown');
				optionNode.addClass(obj.label);
				if (userAnswer == answers[j]) {
					optionNode.setAttribute('selected', 'selected');
				}

				selectNode.insert(optionNode);
			}

			replace[obj.label] = selectNode.toString();
			repl.push({ "label": obj.label, "value": selectNode.toString() });

			this.gaps.push(selectNode);
		}

		var template: string = c.toString();

		for (var i = 0; i < repl.length; i++) {
			template = template.replace("{" + repl[i].label + "}", repl[i].value);
		}
		c.html = template;
	}

	protected bindUI() {
		this.model.getGapLabels().forEach(function (gap) {
			var node = this.getNodeByLabel(gap);
			if (node) node.native.addEventListener('change', this.onValueChanged.bind(this));
		}, this);
	}

	protected unbindUI() {
		this.model.getGapLabels().forEach(function (gap) {
			var node = this.getNodeByLabel(gap);
			if (node) node.native.removeEventListener('change');
		}, this);
	}
}
export default DropDown;
