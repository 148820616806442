
/**
* An interface of the complied template function containing also its source
*/
interface CompliedTemplate {
    ( data:any ):string;
    source:string;
}

interface TemplatePass {
    regex:RegExp;
    mapper:( match:string, inner:string ) => string;
}


/**
* This class adapts the template implementation of the underscore library:
* https://github.com/jashkenas/underscore
*/
class TemplateRenderer {
    
    public passes:TemplatePass[] = [];
    public context:any = { };
    
    
    constructor() {
        
        // inject escape function
        this.context.esc = this.escape;
        this.context.empty = this.empty;
        this.context.url = encodeURIComponent;

        this.passes = this.defaultPasses();
    }
    
    /**
        * Renders the given data into the given template string
        */
    render( template:string, data:any = {} ):string {
        return this.compile( template )( data );
    }
    
    /**
        * Compiles a template string into a template function.
        */
    compile( templateString:string ):CompliedTemplate {
        
        var source:string = this.parse( templateString );
        var render = new Function( 'data', source );
        var contx = this.context;
        
        // wrap function to adjust context
        var template = <CompliedTemplate>function( data ):string {
            return render.call( contx, data );
        }
        
        template.source = source;
        return template;
    }
    
    /**
        * Turns the given template into a function body code.
        */
    parse( template:string ):string {
        
        template = this.sanitize( template );
        
        // loop over all passes
        this.passes.forEach( function( pass ) {
            template = template.replace( pass.regex, pass.mapper );
        });
        
        // finalize full template instructions
        template = "__t='" + template + "';\n";
        
        // adjust scope with 'with'
        template = 'with( data ) {\n' + template + '}\n';
        
        // add opt variable for optional access
        return 'var __t;\nvar opt = data;\nvar __c = this;' + template + 'return __t;'
    }
    
    /**
        * Escapes the given string with html entities
        */
    escape( text:string ):string {
        var reg:RegExp = /[&<>"'\/`]/g;
        var lookup = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#x27;', '/': '&#x2F;', '`': '&#x60;' };
        text = text + ''; // make string.
        return text.replace( reg, function( match:string ) {
            return lookup[ match ];
        });
    }
    
    /**
        * Tests if the value of the template is empty or not
        */
    empty( data:any ):string {
        return data || data === 0 ? data : '';
    }
    
    /**
        * Sanitizes the given string to avoid escaping of the source
        */
    sanitize( text:string ):string {
        var reg:RegExp = /'|\\|\r|\n|\u2028|\u2029/g;
        var lookup = { "'": "\\'", '\\': '\\\\', '\r': '\\r', '\n': '\\n', '\u2028': '\\u2028', '\u2029': '\\u2029' };
        return text.replace( reg, function( match:string ) {
            return lookup[ match ];
        });
    }
    
    /**
        * Reverts the sanitizes escapes
        */
    unsanitize( text:string ):string {
        var reg:RegExp = /\\\\|\\'|\\r|\\n|\\u2028|\\u2029/g;
        var lookup = { '\\\\': '\\', "\\'": "'", '\\r': '\r', '\\n': '\n', '\\u2028': '\u2028', '\\u2029': '\u2029' };
        return text.replace( reg, function( match:string ) {
            return lookup[ match ];
        });
    }
    
    /**
        * Returns the array of default template render passes
        * Most specific match has to be the first.
        */
    defaultPasses():TemplatePass[] {
        
        var revert = this.unsanitize;
        
        return [
            {
                regex:/\[\[=([\s\S]+?)\]\]/g,
                mapper: function( match, inner ) {
                    return "' + __c.empty( " + revert( inner ) + " ) + '";
                }
            },
            {
                regex:/\[\[([\s\S]+?)\]\]/g,
                mapper: function( match, inner ) {
                    return "' + __c.esc( __c.empty( " + revert( inner ) + " ) ) + '";
                }
            },
            {
                regex:/\[%([\s\S]+?)%\]/g,
                mapper: function( match, inner ) {
                    return "';\n" + revert( inner ) + "\n__t+='";
                }
            }
        ]
    }
}

export default TemplateRenderer;