import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import { mapper } from 'lb/LernBuch';
import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import ModifiableList from 'ln/list/ModifiableList';
import Filterbutton from './Filterbutton';
import TermFilter from 'lnui/filter/TermFilter';

import Lang from 'ln/lang/Lang';
import MaterialcardText from './MaterialcardText';

class Materialpool extends View {
    public data;

    public filterThemen = [];
    public filterDimensionen = [];
    //public filterCompetencies = [];
    public filterMediatypes = [];
    public filterGoals = [];
    private listRenderer

    constructor( ) {
        super( { 'template': 'lb.materialienpool' } );
    }

    init() {

        Template.context.goalIconPath = function( key:string ){
            if( key === null) return "";
            return decodeURIComponent( setup.route( 'goalicon', { file_name: key } ).url() );
        }
        
        var data = mapper.model( setup.data( 'materials' ))

        this.listRenderer = new ListRenderer<{}, ModifiableList<{}>>( this.node.js('materials') );

        this.listRenderer.ioc.add( 'default', function ( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });

        this.listRenderer.ioc.add( 'App\\MaterialText', function ( model:ElementModel ) {
            return new MaterialcardText( model ).render() as Element;
        });

        this.listRenderer.source = new ModifiableList();
        this.listRenderer.source.originals.fill( data, true )
        this.listRenderer.source.modifiers.append( this.checkFilters.bind( this ))
        

        var dimensionValues = [];
        var dimensionData = []
        // var competenciesValues = [];
        // var competenciesData = [];
        var mediatypeValues = [];
        var mediatypeData = [];
        var goalValues = [];
        var goalData = [];

        data.forEach( element => {
            dimensionValues = dimensionValues.concat( element.dimensions.filter( function( dimension ) {
                return dimensionValues.indexOf( dimension ) < 0
            }))
            if( mediatypeValues.indexOf( element.modelName ) < 0 ) mediatypeValues.push( element.modelName )
            // mediatypeValues = mediatypeValues.concat( element.modelName.filter( function( competency ) {
            //     return mediatypeValues.indexOf( competency ) < 0
            // }))
            goalValues = goalValues.concat( element.goals.filter( function( goal ) {
                return goalValues.indexOf( goal ) < 0
            }))
        });
        // sort goals alphabetically
        goalValues = goalValues.sort((a, b) => a.localeCompare(b))

        dimensionValues.forEach( element => {
            dimensionData.push( {
                'value': element,
                'context': this
            })
        }, this)

        mediatypeValues.forEach( element => {
            mediatypeData.push( {
                'value': element,
                'context': this
            })
        }, this)

        goalValues.forEach( element => {
            goalData.push( {
                'value': element,
                'context': this
            })
        }, this)

        var dimensionLRdr = new ListRenderer( this.node.js('filter-dimensions') );
        dimensionLRdr.ioc.add( 'default', this.addFilterDimension.bind( this ))
        dimensionLRdr.source.fill( dimensionData );
        
        var themenLRdr = new ListRenderer( this.node.js( 'filter-themen' ) );
        themenLRdr.ioc.add( 'default', this.addFilterThema.bind( this ))
        themenLRdr.source.fill( setup.data('themen') );

        var mediatypeLRdr = new ListRenderer( this.node.js( 'filter-mediatype' ) );
        mediatypeLRdr.ioc.add( 'default', this.addFilterMediatype.bind( this ))
        mediatypeLRdr.source.fill( mediatypeData );

        var goalFilterList = new ListRenderer( this.node.js( 'filter-goals' ) );
        goalFilterList.ioc.add( 'default', this.addFilterGoal.bind( this ))
        goalFilterList.source.fill( goalData );

        var termFilter = new TermFilter( [ "title" ] );
        termFilter.ignoreCase = true;
        termFilter.render( this.node.js( 'term-input' ));
        this.listRenderer.source.modifiers.append( termFilter.filter.bind( termFilter ));

        Node.js( 'term' ).keyup.add( function() {
            this.listRenderer.source.update()
        }.bind( this ))

    }

    private checkFilters( items ) {
        return items.filter( function( item:any ) {

            var included = true;

            this.filterDimensionen.forEach( key => {
                if( item.dimensions.indexOf( key ) < 0) included = false;
            } )

            this.filterThemen.forEach( key => {
                var inThemen = false;
                if( item.themen ) {
                    item.themen.forEach( thema => {
                        if( thema.title == key) inThemen = true
                    } )
                }
                if( ! inThemen ) included = false
            } )

            this.filterMediatypes.forEach( key => {
                if( item.modelName.indexOf( key ) < 0 ) included = false;
            })

            this.filterGoals.forEach( key => {
                if( item.goals.indexOf( key ) < 0 ) included = false;
            })

            return included
        }, this)
    }

    private addFilterDimension( dimension ) {

        var v =  new Filterbutton( dimension, true);
        v.render();
        
        v.buttonClicked.add( function( data, toggleState ) {
            if( toggleState ) {
                this.filterDimensionen.push( data.value )
            } else {
                this.filterDimensionen.splice( this.filterDimensionen.indexOf( data.value ) ,1)
            }
            this.listRenderer.source.update();
        }.bind( this ));
        return v;
    
    }

    private addFilterThema( thema ) {
        var button = new Filterbutton( { 'value': thema.title}, true )
        button.render();

        button.buttonClicked.add( function( data, toggleState ) {
            if( toggleState ) {
                this.filterThemen.push( data.value )
            } else {
                this.filterThemen.splice( this.filterThemen.indexOf( data.value ), 1 )
            }
            this.listRenderer.source.update();
        }.bind( this ))

        return button

    }

    private addFilterMediatype( mediatype ) {

        var v =  new Filterbutton( mediatype, true);
        v.render();
        
        v.buttonClicked.add( function( data, toggleState ) {
            if( toggleState ) {
                this.filterMediatypes.push( data.value )
            } else {
                this.filterMediatypes.splice( this.filterMediatypes.indexOf( data.value) ,1)
            }
            this.listRenderer.source.update();
        }.bind( this ));
        return v;
    
    }

    private addFilterGoal( goal ) {

        var v =  new Filterbutton( goal, true, 'lb.filter-icon');
        v.render();
        
        v.buttonClicked.add( function( data, toggleState ) {
            if( toggleState ) {
                this.filterGoals.push( data.value )
            } else {
                this.filterGoals.splice( this.filterGoals.indexOf( data.value) ,1)
            }
            this.listRenderer.source.update();
        }.bind( this ));
        return v;
    
    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
        return this;
    } 
}

export default Materialpool;

scanner.ioc.add( 'materialienpool', function( node:Node, data ) {
    return new Materialpool( ).setData( data).render( node );
});