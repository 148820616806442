import QuestionModel from '../Question/QuestionModel';
import LernFragen from '../../LernFragen';

interface Point {
	left: number;
	top: number;
}

interface Area {
	left: number;
	top: number;
	height: number;
	width: number;
}

/**
 *  A model class for a HotSpot slide.
 */

class HotspotModel extends QuestionModel {

	/**
	 * Checks on the slide model if the user has already made a selection.
	 * This overwrites the default behaviour of the Question slide.
	 * @return True if there is already something selected on the slide model.
	 */
	public hasUserInput() {
		return this.userPoints.length > 0;
	};

	/**
	 * Adds a user input point to the model
	 */
	public addPoint( left: number, top: number ) {

		// append new point
		this.userPoints.push( { left: left, top: top });

		// remove too much userPoints
		while ( this.userPoints.length > this.hotspotAreas.length ) {
			this.userPoints.shift();
		}
	};

	/**
	 * Returns the user points that lies on the given hotpot. If there is no one found an empty array is returned.
	 */
	public getUserPointOn( hotspot: Area ): Point[] {

		// no valid data
		if ( this.userPoints.length == 0 ) return [];

		return this.userPoints.filter( function ( userPoint ) {

			var l = hotspot.left;
			var r = l + hotspot.width;
			var t = hotspot.top;
			var b = t + hotspot.height;

			// test horizontally and vertically
			return l <= userPoint.left && userPoint.left <= r && t <= userPoint.top && userPoint.top <= b;
		});
	};


	/**
	 * Splits all userpoints into correct and wrong ones depending on they are on a hotspot or not.
	 */
	public partitionUserPoints() {

		var wrongPoints = this.userPoints.concat();
		var correctPoints = [];
		var t = this;

		// this function will remove the given point from the wrong ones
		// and add it to the correct ones.
		var markCorrectPoint = function ( userPoint ) {

			var i = wrongPoints.indexOf( userPoint );
			if ( i > -1 ) wrongPoints.splice( i, 1 );

			correctPoints.push( userPoint );
		};

		// loop over all hotspotAreas and check if there is a user point
		this.hotspotAreas.forEach( function ( hotspot ) {
			var correctPoint = t.getUserPointOn( hotspot );
			// if there are correct point mark the first as correct
			if ( correctPoint.length != 0 ) markCorrectPoint( correctPoint[0] );
		});

		return { correct: correctPoints, wrong: wrongPoints };
	};


	/**
	 * Returns true if the hotspot question is correctly solved
	 */
	isCorrect() {

		// if there are no hotpots
		if ( this.userPoints.length === 0 && this.hotspotAreas.length === 0 ) return true;

		// regular check
		var result = this.partitionUserPoints();
		return result.correct.length === this.hotspotAreas.length;
	};

	/**
	 * Returns the array of hotspotAreas form the slide model
	 */
	get hotspotAreas(): Area[] {
		if ( this.get( 'hotspot_areas' ) == undefined ) this.set( 'hotspot_areas', new Array<Area>() );
		return this.get( 'hotspot_areas' ) as Area[];
	}

	/**
	 * Returns the array of userPoints form the slide model
	 */
	get userPoints(): Point[] {
		if ( this.get( 'userPoints' ) == undefined ) this.set( 'userPoints', new Array<Point>() );
		return this.get( 'userPoints' ) as Point[];
	}



}

export default HotspotModel;