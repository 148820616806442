import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
import View from 'ln/view/View';
import Window from 'ln/node/Window';
import Element from './elements/Element';
import Chapter from './elements/Chapter';
import ChapterModel from './models/ChapterModel';
import IChapterLink from './LernBuch';

/**
 * Interface for all listener function that listen on the top signal
 */
interface ScrollChangeListener {
	( visible:Array<Element>, ...args:any[] );
}

/**
 * A class that monitors the visible elements of a lernbuch
 */
class ScrollMonitor {
	
	// Offest-Top and Offset-Bottom (in case of fixed header / footer)
	public offsetTop:number = 0;
	public offsetBottom:number = 0;
	
	private visibleElements:Array<View> = [];
	private _elements:Array<View> = [];
	
	// event when the top visible element has changed
	public change:Signal<ScrollChangeListener> = new Signal<ScrollChangeListener>();
	
	constructor( elements:Array<View> = [] ) {
		
		this._elements = elements;

		// register on window events
		Window.resize.add( this.update, this );
		Window.scroll.add( this.update, this );

	}

	/**
	 * Set the elements for the scrollMonitor
	 */
	set elements( elements:Array<View> ){
		this._elements = elements;
		this.visibleElements = [];
		this.update();
	}
	
	/**
	 * Iterates over all the rendered Elements and updates the visibleElements array
	 */
	public update() {
		
		var scrollInfo = Window.scrollInfo();
		var viewport = Window.viewport();
		
		var viewportTop:number = scrollInfo.top + this.offsetTop;
		var viewportHeight:number = viewport.height - (this.offsetTop + this.offsetBottom);
		var viewportBottom:number = viewportTop + viewportHeight;
		
		var currentElements:Array<View> = [];
		
		// loop over the rendered elements
		this._elements.forEach( ( element:Element ) => {
			
			var bounds = element.node.bounds();
			
			// element larger than viewPort
			if (bounds.top < viewportTop && bounds.bottom > viewportBottom) {
				currentElements.push( element );
				return;
			}
			
			// element fully inside viewport
			if( bounds.top >= viewportTop && bounds.bottom <= viewportBottom) {
				currentElements.push( element );
				return;
			}
								
			// partial visible, calc overlappping height,
			// if more than 50% of viewport is covered -> add
			var visibleTop = Math.max( bounds.top, viewportTop );
			var visibleBot = Math.min( bounds.bottom, viewportBottom );
			if( ( visibleBot - visibleTop ) / viewportHeight >= 0.5 ) {
				currentElements.push( element );
				return;
			}
			
		});
			
		// compare 
		this.compareElements( currentElements );
	}
	
	/**
	 * Compares the given current elements and check with the visibleElements which are still visible or not.
     */
	private compareElements( currentElements:Array<View> ) {
		
		var hasChange = false;
		
		// check if current element is new visible
		currentElements.forEach( ( element:View, index )=> {
			if( this.visibleElements.indexOf(element) == -1 ) {
				hasChange = true;
			}
		});
		
		// check if any of the old elements are not visible anymore.
		this.visibleElements.forEach( ( element, index )=> {
			if( currentElements.indexOf(element) == -1 ) {
				hasChange = true;
			}
		});
		
		// check if top element has changed
		hasChange = hasChange || currentElements[0] != this.visibleElements[0];
		
		// update the visible element
		this.visibleElements = currentElements;
			
		if( hasChange ) this.change.dispatch( currentElements );
	}

	public scrollToElementHash(){

		// no element hash, scroll to page top
		if( window.location.hash == '' ){
			window.scrollTo( 0, 0 );
		} else {

			Node.one( window.location.hash ).native.scrollIntoView();
	
			// plus add scrolling for offset
			if( this.offsetTop != 0 ){
				window.scrollBy( 0, -this.offsetTop );
			}
		}
	}
}

export default ScrollMonitor;
