/**
 * A collection of js specific helper functions.
 */

type baseTypes = "Array" | "Object" | "String" | "Date" | "RegExp" | "Function" | "Boolean" | "Number" | "Null" | "Undefined";

/**
 * Helper function that checks if the given input is any of the given types 
 */
export function isType( input:any, checkOn:baseTypes | Array<baseTypes> ):boolean {

	// catch single string input
	var types:Array<baseTypes> = ( !Array.isArray( checkOn ) ) ? [ checkOn ] : checkOn;

	// test on object.
	if( types.indexOf( "Object" ) >= 0 ) {
		var match = input && input.constructor == Object;
		if( match ) return match;

		types.splice( types.indexOf( "Object" ), 1 );
	}

	// test anything else
	var inputType = Object.prototype.toString.call( input ).slice(8, -1);
	return types.indexOf( inputType ) >= 0;
}


/**
 * This function applies multiple mixins to the given extendedClass.
 */
export function mixin( extendedClass: any, mixins: any[] ) {
    mixins.forEach( baseCtor => {
        Object.getOwnPropertyNames( baseCtor.prototype ).forEach( name => {
            extendedClass.prototype[name] = baseCtor.prototype[name];
        });
    }); 
}

/**
 * Merges multiple objects into one. No deep clone.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 */
export function assign( target:{} = {}, ...args:Object[] ) {

	var copy = Object( target );

	args.forEach( ( source ) => {
		if( source ) {
			for( var nextKey in source ) {
				if( source.hasOwnProperty( nextKey ) ) copy[ nextKey ] = source[ nextKey ];
			}
		}
	});

	return copy;
}


/**
 * Adapted from: http://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
export function shuffle(array) {
    var i = array.length, t, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== i) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * i);
        i -= 1;
        // And swap it with the current element.
        t = array[i];
        array[i] = array[randomIndex];
        array[randomIndex] = t;
    }
    return array;
}