import IoC from '../ioc/IoC';
import Model from './Model';
import { isType } from '../js';

export interface ModelFactoryFunction {
	( json:Object ):any
}

export interface JsonFactoryFunction {
	( model:Model ):Object
}

/**
 * A class that helps to map models on a json structure
 */
class Mapper {

	public toModel:IoC<ModelFactoryFunction> = new IoC<ModelFactoryFunction>();
	public toJson:IoC<JsonFactoryFunction> = new IoC<JsonFactoryFunction>();
	
	public jsonLookup:( json:Object ) => string;
	public modelLookup:( model:Model ) => string;




	constructor() {

		// setup default json to model conversion.
		this.toModel.add( 'default', function( json:Object, data?:any ) {
			return json;
		});
		
		// setup default model to json conversion
		this.toJson.add( 'default', function( model:Model, data?:any ) {
			return model;
		});

		// setup default lookup function for json
		this.jsonLookup = function( json:Object ) {
			return json["modelName"];
		}

		// setup default lookup function for models
		this.modelLookup = function( model:Model ) {
			return ( model instanceof Model ) ? model.get<string>( 'modelName' ) : 'default';
		}
	}





	public model( json:any ) {

		// if primitive type is given simply return it
		if( isType( json, [ "String", "Date", "RegExp", "Function", "Boolean", "Number", "Null", "Undefined" ] ) ) {
			return json;
		}

		// if json array is given map each
		if( isType( json, "Array" ) ) {
			return ( json as Array<any> ).map( ( item ) => {
				return this.model( item );
			});
		}

		// Object given make recursion over keys
		Object.keys( json ).forEach( ( name ) => {
			var value = json[ name ];

			// only follow object or array references
			if( isType( value, [ "Object", "Array" ] ) ) json[ name ] = this.model( value );
		});

		// Finally turn object into model with ioc
		return this.toModel.get( this.jsonLookup( json ) )( json );
	}





	public json( model:any ) {

		// if primitive type is given simply return it
		if( isType( model, [ "String", "Date", "RegExp", "Function", "Boolean", "Number", "Null", "Undefined" ] ) ) {
			return model;
		}

		// if json array is given map each
		if( isType( model, "Array" ) ) {
			return ( model as Array<any> ).map( ( item ) => {
				return this.json( item );
			});
		}

		// try to convert it with ioc
		model = this.toJson.get( this.modelLookup( model ) )( model );

		// if object given make recursion over keys
		Object.keys( model ).forEach( ( name ) => {
			model[ name ] = this.json( model[ name ] );
		});

		// any other types simply return
		return model;
	}
}

export var mapper = new Mapper();

export default Mapper;