// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
// import project
import AnswerList from '../AnswerList/AnswerList';
import MultipleChoiceModel from '../MultipleChoice/MultipleChoiceModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A class that represents a single choice question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		modelName: MultipleChoice,
 *		imgPath: "url",
 *		description: "My question."
 *		answers: [
 *			{ text: "Answer 1" },
 *			{ text: "Answer 2", correct: true, selected: true },
 *			{ text: "Answer 3", correct: true }
 *		]
 *	}
 * 
 */
class MultipleChoice extends AnswerList {

	public model: MultipleChoiceModel;

	constructor( model: MultipleChoiceModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );
		this.defaultTemplate = 'lf.answerlist-slide';
		this.answerListTemplate = 'lf.multiplechoice-answer';
	}

	/**
	 * Renders the list of answers into the answer container.
	 * @protected
	 */
	protected renderAnswers() {
		var answers = new List<Object>( this.model.answers );
		this.answerRenderer = new ListRenderer<Object>();
		this.answerRenderer.container = this.node.js( this.answerContainerKey );

		this.answerRenderer.defaultRender(( answer: any ) => {
			var answerNode = Node.fromHTML( Template.render( this.answerListTemplate, answer ) );
			if ( answer.selected ) {
				( answerNode.one( 'input' ).native as HTMLInputElement ).checked = true;
			}

			this.node.js( this.answerContainerKey ).append( answerNode );
			answerNode.click.add( function ( node: Node, event: MouseEvent ) {
				this.handleAnswerClick( answer );
			}, this );

			// Hack: prevent default behaviour of the label and checkbox
			if ( this instanceof AnswerList ) {
				answerNode.one( 'label' ).click.add( function ( node: Node, event: MouseEvent ) {
					event.preventDefault();
				}, this );

				answerNode.one( 'input' ).click.add( function ( node: Node, event: MouseEvent ) {
					this.model.select( answer );
					event.stopPropagation();
				}, this );
			}


			return { node: answerNode };
		});
		this.answerRenderer.source = answers;
	}

}

export default MultipleChoice;