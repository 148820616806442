
import dragula from "dragula";
import Signal from '../../core/signal/Signal';
import Node from '../../core/node/Node';


interface DragConfigObject {
    containers?: Element[];       
    isContainer?: ( el:Node )=>boolean;                                      // default: false. Only elements in drake.containers will be taken into account
    moves?: ( el:Node, source:Node , handle:any, sibling:Node )=>boolean;    // default: true. Elements are always draggable by default
    accepts?: ( el:Node, target:Node, source:Node, sibling:Node )=>boolean;  // default: true. Elements can be dropped in any of the `containers` by default
    invalid?: ( el:Node, handle:any )=>boolean;                              // default: false. Don't prevent any drags from initiating by default
    direction?:string;                     // default: 'vertical'. Y axis is considered when determining where an element would be dropped
    copy?:boolean;                         // default: false. Elements are moved by default, not copied
    copySortSource?:boolean;               // default: false. Elements in copy-source containers can be reordered
    revertOnSpill?:boolean;                // default: false. Spilling will put the element back where it was dragged from, if this is true
    removeOnSpill?:boolean;                // default: false. Spilling will `.remove` the element, if this is true
    mirrorContainer?:Element;              // default: document.body. Set the element that gets mirror elements appended
    ignoreInputTextSelection?:boolean;     // default: true. Allows users to select input text, see details below
};

interface onDragFunction {
    ( element: Node, source: Node ):void;
}

interface onDropFunction {
    ( element: Node, target: Node, source: Node, sibling: Node ):void;
}

interface onEventFunction {
    ( element: Node, container: Node, sibling: Node ):void;
}


/**
 * A typescript wraper for the drag and drop lib dragula
 * https://github.com/bevacqua/dragula
 * 
 * There's a few CSS styles you need to incorporate in order for dragula to work as expected.
 * You can add them by including ./dragdrop.min.css or https://github.com/bevacqua/dragula/dist/dragula.min.css in your document.
 */
export class DragDrop {

    private drake:dragula.Drake;
    public dragged:Signal<onDragFunction>;
    public dropped:Signal<onDropFunction>;
    public canceled:Signal<onEventFunction>;
    public removed:Signal<onEventFunction>;

    constructor( containers?:Node|Node[], options?:DragConfigObject ) {
        var containerElements:HTMLElement[] = [];
        var o:any = {};

        if( !Array.isArray( containers ) ) {
            containers = [ containers ];
        }

        containers.forEach( ( container ) => {
            containerElements.push( container.native );
        } );

        // be shure that options is an opject
        if( options == undefined ) { options = {} };

        // convert options for dragula
        var o = this.convertOptions( options );

        // add containers to the options
        o.containers = containerElements;

        // make an instance
        this.drake = dragula( o );

        // register signals
        this.dragged = new Signal<onDragFunction>();
        this.drake.on( 'drag', this.onDrag.bind( this ) );

        this.dropped = new Signal<onDropFunction>();
        this.drake.on( 'drop', this.onDrop.bind( this ) );
        
        this.canceled = new Signal<onEventFunction>();
        this.drake.on( 'cancel', this.onCancel.bind( this ) );

        this.removed = new Signal<onEventFunction>();
        this.drake.on( 'remove', this.onRemove.bind( this ) );

    }

    /**
     * Adds a container 
     */
    public addContainer( container:Node ) {
        this.drake.containers.push( container.native );
    }

    /**
     * Gracefully end the drag event
     */
    public end() {
        this.drake.end();
    }

    /**
     * If an element managed by drake is currently being dragged, this method will gracefully cancel the drag action.
     */
    public cancel() {
        this.drake.cancel();
    }

    /**
     * If an element managed by drake is currently being dragged, this method will gracefully remove it from the DOM.
     */
    public remove() {
        this.drake.remove();
    }
    /**
     * Removes all drag and drop events used by dragula to manage drag and drop between the containers. 
     * If .destroy is called while an element is being dragged, the drag will be effectively cancelled.
     */
    public destroy() {
        this.drake.off();
        this.drake.destroy();
    }
    /**
     * This property will be true whenever an element is being dragged.
     */
    get dragging():boolean {
        return this.drake.dragging;
    }

    private onDrag( element:HTMLElement, source:HTMLElement ) {
        this.dragged.dispatch( Node.fromNative( element ), Node.fromNative( source ) );
    }

    private onDrop( element:HTMLElement, target:HTMLElement, source:HTMLElement, sibling:HTMLElement ) {
        this.dropped.dispatch( Node.fromNative( element ), Node.fromNative( target ), Node.fromNative( source ), ( sibling == null ) ? null :  Node.fromNative( sibling ) );
    }

    private onCancel( element:HTMLElement, container:HTMLElement, sibling:HTMLElement ) {
        this.canceled.dispatch( Node.fromNative( element ), Node.fromNative( container ), Node.fromNative( sibling ) );
    }

    private onRemove( element:HTMLElement, container:HTMLElement, sibling:HTMLElement ) {
        this.removed.dispatch( Node.fromNative( element ), Node.fromNative( container ), Node.fromNative( sibling ) );
    }

    private convertOptions( options:DragConfigObject ) {
        var o:any = {};


        if( options.direction != undefined ) { o.direction = options.direction };
        if( options.copy != undefined ) { o.copy = options.copy };
        if( options.copySortSource != undefined ) { o.copySortSource = options.copySortSource };
        if( options.revertOnSpill != undefined ) { o.revertOnSpill = options.revertOnSpill };
        if( options.removeOnSpill != undefined ) { o.removeOnSpill = options.removeOnSpill };
        if( options.mirrorContainer != undefined ) { o.mirrorContainer = options.mirrorContainer };
        if( options.ignoreInputTextSelection != undefined ) { o.ignoreInputTextSelection = options.ignoreInputTextSelection };

        if( options.isContainer != undefined ) {
            o.isContainer = function( el:HTMLElement ):boolean {
                var node = Node.fromNative( el );
                return options.isContainer( node );
            }
        }

        if( options.moves != undefined ) {
            o.moves = function( el:HTMLElement, source:HTMLElement , handle:any, sibling:HTMLElement ):boolean {
                return options.moves( Node.fromNative( el ), Node.fromNative( source ), Node.fromNative( handle ), Node.fromNative( sibling ) );
            }
        }

        if( options.accepts != undefined ) {
            o.accepts = function( el:HTMLElement, target:HTMLElement , source:HTMLElement, sibling:HTMLElement ):boolean {
                return options.accepts( Node.fromNative( el ), Node.fromNative( target ), Node.fromNative( source ), Node.fromNative( sibling ) );
            }
        }

        if( options.invalid != undefined ) {
            o.invalid = function( el:HTMLElement, handle:any ):boolean {
                return options.invalid( Node.fromNative( el ), handle );
            }
        }

        return o;
    }

} 

export default DragDrop;