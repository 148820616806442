import QuestionModel from '../Question/QuestionModel';

/**
 * An interface for answers
 */
export interface Answer {
	text: string;
	correct?: boolean;
	selected?: boolean;
}


/**
 * A model class for answerlist slides.
 */
export class AnswerListModel extends QuestionModel {

	/**
	 * The constructor
	 * 
	 */
	constructor( json ) {
		super( json );
		// verifiy that the answers have required flags
		this.answers.forEach( ( answer ) => {
			if( answer.correct == undefined ) { answer.correct = false; }
			if( answer.selected == undefined ) { answer.selected = false; }
		});
	}

	/**
	 * Checks on the slide model if the user has already made a selection.
	 * This overwrites the default behaviour of the Question slide.
	 * @return {Boolean} True if there is already something selected on the slide model.
	 */
	public hasUserInput():boolean {
		
		var answers = this.answers;

		if( this.answers.length == 0 ) { return true; }

		for( var i = 0; i < this.answers.length; i++ ) {
			if( this.answers[i].selected ) { return true }			
		}

		return false;
	};

	/**
	 * Returns true if all the correct answers are checked and the others not
	 */
	public isCorrect():boolean {
		var answers = this.answers;
		var correct = true;

		for( var i = 0; i < answers.length; i++ ) {
			correct = ( ( answers[i].correct && answers[i].selected ) || ( !answers[i].correct && !answers[i].selected ) ) && correct;
		}

		return correct;
	};	

	/**
	 * Marks one of the answers as selected
	 */
	public select( selectedAnswer:Answer ) {
	}

	get answers():Answer[] {
		return this.get( 'answers', []) as Answer[];
	}

	set answers( a:Answer[] ) {
		this.set( 'answers', a );
	}

}

export default AnswerListModel;
