// import lib
import Node from 'ln/node/Node';
// import project
import Decorator from './Decorator';
import { DecoratorFunction } from './Decorator';
import IoC from 'ln/ioc/IoC';
import AnswerListModel from '../slides/AnswerList/AnswerListModel';
import AnswerListView from '../slides/AnswerList/AnswerList';
import HotspotImageModel from '../slides/Hotspot/HotspotModel';
import HotspotImageView from '../slides/Hotspot/Hotspot';
import Template from 'ln/template/TemplateManager';


export interface ContainerFeedbackConfig {
	containerSelector?: string;
	containerTemplate?: string;
}


/**
 * This class can be used as a base class for every decorator that needs to show something in its own container
 */
class ContainerFeedback extends Decorator {

	private containerSelector: string;
	private containerTemplate: string;

	public static ioc: IoC<DecoratorFunction> = new IoC<DecoratorFunction>();

	constructor( config: ContainerFeedbackConfig ) {
		super();
		/**
		 * The js selector that selects the node where the feedback will be rendered.
		 */
		this.containerSelector = ( typeof config.containerSelector != 'undefined' ) ? config.containerSelector : 'feedback-container';
		/**
		 * The template, the feedback is rendered with
		 */
		this.containerTemplate = ( typeof config.containerTemplate != 'undefined' ) ? config.containerTemplate : 'lf.feedback-panel';
	}


	/**
	 * Shows the feedback container with the corresponing feedback message.
	 * @param parentNode The parent node where the container is added.
	 * @param content The content to show in the container. This can either be a node or a simple html string
	 */

	public showContainer( parentNode, content:string|Node ) {

		var feedbackNode:Node;

		if( content instanceof Node ) {
			feedbackNode = content;
		}else {
			feedbackNode = Node.fromHTML( Template.render( this.containerTemplate, { content: content } ) );
		}

		var container = this.getContainer( parentNode )
		if ( container != null ) {
			//container.empty();
			container.replace( feedbackNode );
		}else {
			parentNode.append( feedbackNode );
		}

	};

	public getContainer( parentNode: Node ) {
		return parentNode.js( this.containerSelector );
	}

}


export default ContainerFeedback;