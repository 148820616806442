import LookupDict from '../lookup/LookupDict';
import LookupObject from '../lookup/LookupObject';
import { HttpRequest } from '../request/Request';
import Template from '../template/TemplateManager';
import Asset from './Asset';

export interface SetupConfig {
	routes:any,
	data:any,
	assets:any,
}

/**
 * A class that manages external routes, data and assets.
 */
export class Setup {

	private lookup:LookupDict;

	constructor() {
		this.lookup = new LookupDict();
	}

	init( data:SetupConfig ) {
		this.lookup.add( new LookupObject( data ) );
		Template.context.setup = this;
	}

	/**
	 * Returns a request that can load an save data to the url defined in the configuration
	 * @param key The key under the routes definition
	 * @param urlParams The parameters for the url
	 */
	route( key:string, urlParams:{} = {} ):HttpRequest {

		var config = this.search( "routes." + key );
		var url =  Template.render( config.url, urlParams );
		var request = new HttpRequest( url );

		if( config.method ) request.method( config.method );
		if( config.headers ) request.headers( config.headers );
		
		return request;
	}

	/**
	 * Returns any data that is defined under the data configuration
	 */
	data( key?:string ):any {
		if( key === undefined ) return this.search( 'data' );
		return this.search( "data." + key );
	}

	/**
	 * Returns a asset loader that can load additional scripts and css files.
	 */
	asset( key:string ):Asset {
		return new Asset( this.search( "assets." + key ) );
	}

	private search( key ):any {
		var config = this.lookup.get( key, null );
		if( !config ) throw new Error( "Could not find defintion for api with key: " + key );
		return config;
	}
}


var setup = new Setup();
export default setup;