import Model from 'ln/model/Model';
import { shuffle } from 'ln/js';

import DragDropModel from '../DragDrop/DragDropModel';
/**
 * A model class for Order slides.
 */
class OrderModel extends DragDropModel {

	/**
	 * The constructor
	 */
	constructor( json ) {
		
		super( json );
		this.generateDrops();
	}


	protected generateDrops() {

		var drops = [];
		var drags = this.drags;

		drags.forEach( function ( drag, index ) {
			drag.target = index + 1;
		 	drops.push( {} );
		});

		drags = shuffle( drags );
		
		this.set( 'drops', drops );
		this.set( 'drags', drags );
	}
}

export default OrderModel;