// lib
import Node from 'ln/node/Node';
import IoC from 'ln/ioc/IoC';
// project
import SlideModel from '../slides/Slide/SlideModel';
import Question from '../slides/Question/QuestionModel';

export interface AggregatorFunction {
	(model: SlideModel, aggregator: Aggregator): void;
}

/**
 * This is an abstract class for all aggregators. Aggregators collect information on slide models.
 * Each aggregator holds an ioc that maps the modelName to an aggregator function
 */
class Aggregator {

	public ioc: IoC<AggregatorFunction> = new IoC<AggregatorFunction>();

	constructor(ioc?: IoC<AggregatorFunction>) {
		if (ioc != undefined) {
			this.ioc = ioc;
		}
	}

	/**
	 * Loops throug an array of slideModels of type Question and calls the aggregate function
	 * registered in the ioc each model
	 */
	public aggregateArray(slideModels: SlideModel[]) {

		slideModels.forEach(function (slideModel) {

			if (slideModel instanceof Question) {

				var key = slideModel.aggregatorKey;
				this.ioc.get( key ).call( this, slideModel, this );

			}

		}, this);

	}


}

export default Aggregator;