import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import { mapper } from 'lb/LernBuch';
import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import Taskitem from './Taskitem';
import Request from 'ln/request/Request';
import Lernpfad from './Lernpfad';

import Lang from 'ln/lang/Lang';

class SaveDialog extends View {

    private tasks = [];
    private currentThema;
    private currentLernpfad:Lernpfad;
    private themaTasks = [];
    private code = '';
    private counters = {
        'oekologie' : 0,
        'oekonomie' : 0,
        'soziales' : 0
    }

    constructor( ) {
        super( { 'template': 'lb.savedialog' } );

    }

    init() {
        this.currentThema = setup.data( 'thema' );
        this.calculateDimensionCounts();

        // template stuff
        this.node.js( 'open-dialog' ).click.add( function() {
            this.update()
            this.node.js( 'dialog' ).removeClass( 'hidden' )
        }.bind( this ))

        this.node.js( 'close-dialog').click.add( function() {
            this.node.js( 'dialog' ).addClass( 'hidden' )
        }.bind( this ))

        if( this.node.js( 'save' ) ) {
            this.node.js( 'save' ).click.add( function() {
                this.enableSavestate();
            }.bind( this ))
        }

        if( this.node.js( 'loadstateurl' ) ) {
            this.node.js( 'copyloadstateurl' ).click.add( function() {
                var native = this.node.js( 'loadstateurl' ).native;
                native.setSelectionRange(0, native.value.length );
                native.focus();
                document.execCommand( 'copy' );
            }, this)
        }

        if( setup.data( 'savestate' ).code ) {
            this.node.js( 'save-link-panel').style.display = 'none'; 
            this.node.js( 'copy-link-panel').style.display = 'block';
        }else {
            this.node.js( 'save-link-panel').style.display = 'block'; 
            this.node.js( 'copy-link-panel').style.display = 'none';
        }

    }

    calculateDimensionCounts() {
        this.currentThema.lernpfade.forEach( function( lernpfad ) {
            var tasks = [];

            lernpfad.chapters.forEach( function( chapter ) {
                tasks = tasks.concat( chapter.tasks )
                chapter.tasks.forEach(element => {
                    if( element.dimensions.indexOf( 'ökologie' ) > -1 ) this.counters['oekologie'] ++;
                    if( element.dimensions.indexOf( 'ökonomie' ) > -1 ) this.counters['oekonomie'] ++;
                    if( element.dimensions.indexOf( 'soziales' ) > -1 ) this.counters['soziales'] ++;
                }, this);
            }, this)

            this.themaTasks.push( { 'lernpfad': lernpfad, 'tasks': tasks } )
        }, this)
    }

    getCurrentDimensionCounts() {
        var counters = {
            'ökologie' : 0,
            'ökonomie' : 0,
            'soziales' : 0,
        };

        this.themaTasks.forEach( function( obj ) {
            if( obj.lernpfad.id == this.currentLernpfad.id ) {
                obj.tasks.forEach( function( task ) {
                    if( task.dimensions.indexOf( 'ökologie' ) > -1 ) counters['ökologie'] ++;
                    if( task.dimensions.indexOf( 'ökonomie' ) > -1 ) counters['ökonomie'] ++;
                    if( task.dimensions.indexOf( 'soziales' ) > -1 ) counters['soziales'] ++;
                });
            }
        }, this);

        return counters;
    }

    renderData() {
        var counts = this.getCurrentDimensionCounts();
        var data = {
            'saveCount': this.tasks.length,
            'oekologie': counts['ökologie'] > 0 ? Math.round( this.getTaskCount( 'ökologie' ) / counts['ökologie'] * 100 ) : 0,
            'oekonomie': counts['ökonomie'] > 0 ? Math.round( this.getTaskCount( 'ökonomie' ) / counts['ökonomie'] * 100 ) : 0,
            'soziales': counts['soziales'] > 0 ? Math.round( this.getTaskCount( 'soziales' ) / counts['soziales'] * 100 ) : 0,
            'thema': this.currentThema,
            'code' : setup.data( 'savestate' ).code,
            'enableServersideUserDataStorageUrl' : setup.route( 'enableServersideUserDataStorage' ).url(),
            'loadstateurl' : setup.route( 'loadstate' ).url()
        }

        return data;
    }

    getTaskCount( dimension:string ) {
        var currentLernpfad = setup.data( 'thema' ).lernpfade.filter( lernpfad => {
            return lernpfad.id == this.currentLernpfad.id;
        });

        var count = 0;
        currentLernpfad[0].chapters.forEach( chapter =>  {
            chapter.tasks.forEach( task => {
                if( task.dimensions.indexOf( dimension) > -1 && task.checked ) count++;
            });
        });
        return count;
    }

    setData( data:any) {
        this.data.data = data;
        return this;
    }

    setCurrentLernpfad( lernpfad:Lernpfad ) {
        this.currentLernpfad = lernpfad;
    }

    enableSavestate() {
        var data = {
            'currentThema': this.currentThema.id,
        };

        Request.post( setup.route( 'enableServersideUserDataStorage' ).url() )
            .headers( { 'Content-Type':'application/json' } )
            .send( JSON.stringify( data ))
            .then( function( data ) {
                setup.data( 'savestate' ).code = data.code;
                setup.data( 'savestate' ).id = data.id;
                this.saveTaskStates();
                this.saveLernpfadState();
                this.update();
            }.bind( this ));

    }

    update() {

        // change the labels
        this.node.js( 'label-oekologie' ).html = this.renderData().oekologie.toString() + '%'
        this.node.js( 'label-oekonomie' ).html = this.renderData().oekonomie.toString() + '%'
        this.node.js( 'label-soziales' ).html = this.renderData().soziales.toString() + '%'
        this.node.js( 'thema-title' ).html = this.renderData().thema.title

        // modify the circles in the svg
        this.node.js( 'oekologie').setAttribute( 'rx', (29 + ( 41 * (this.renderData().oekologie / 100 ))).toString() )
        this.node.js( 'oekologie').setAttribute( 'ry', (29 + ( 41 * (this.renderData().oekologie / 100 ))).toString() )

        this.node.js( 'oekonomie').setAttribute( 'rx', (29 + ( 41 * (this.renderData().oekonomie / 100 ))).toString() )
        this.node.js( 'oekonomie').setAttribute( 'ry', (29 + ( 41 * (this.renderData().oekonomie / 100 ))).toString() )

        this.node.js( 'soziales').setAttribute( 'rx', (29 + ( 41 * (this.renderData().soziales / 100 ))).toString() )
        this.node.js( 'soziales').setAttribute( 'ry', (29 + ( 41 * (this.renderData().soziales / 100 ))).toString() )

        if( setup.data( 'savestate' ).code ) {
            this.node.js( 'save-link-panel').style.display = 'none'; 
            this.node.js( 'copy-link-panel').style.display = 'block';
        }else {
            this.node.js( 'save-link-panel').style.display = 'block'; 
            this.node.js( 'copy-link-panel').style.display = 'none';
        }

        this.node.js( 'loadstateurl' ).value = setup.route( 'loadstate' ).url() + '/' + setup.data( 'savestate' ).code;
    }

    hasTask( task:any ) {
        return ( this.tasks.indexOf( task.data.data.id ) > -1 )
    }

    saveTaskStates() {
        var currentLernpfad = setup.data( 'thema' ).lernpfade.filter( lernpfad => {
            return lernpfad.id == this.currentLernpfad.id;
        });

        currentLernpfad[0].chapters.forEach( chapter =>  {
            chapter.tasks.forEach( task => {
                if( task.checked ) {
                    var data = {
                        'savedModelName': task.modelName,
                        'savedModelId': task.id,
                        'attribute': 'checked',
                        'value': true,
                    };
            
                    Request.post( setup.route( 'userData' ).url() )
                        .headers( { 'Content-Type':'application/json' } )
                        .send( JSON.stringify( data ));
                }
            });
        });
    }

    saveLernpfadState() {
        var data = {
            'savedModelName': this.currentThema.modelName,
            'savedModelId': this.currentThema.id,
            'attribute': 'difficulty',
            'value': this.currentThema.difficulty,
        };

        Request.post( setup.route( 'userData' ).url() )
            .headers( { 'Content-Type':'application/json' } )
            .send( JSON.stringify( data ));

        var data = {
            'savedModelName': this.currentThema.modelName,
            'savedModelId': this.currentThema.id,
            'attribute': 'duration',
            'value': this.currentThema.duration,
        };

        Request.post( setup.route( 'userData' ).url() )
            .headers( { 'Content-Type':'application/json' } )
            .send( JSON.stringify( data ));
    }
 }

var singleton = new SaveDialog();

export default singleton;

scanner.ioc.add( 'savedialog', function( node:Node, data ) {
    return singleton.setData( data ).render( node );
});