// import lib
import Model from 'ln/model/Model';
import LinkedList from 'ln/linkedlist/LinkedList';
import TreeNode from 'ln/tree/TreeNode';
import { mixin } from 'ln/js';

import SlideModel from '../Slide/SlideModel';
import LernFragen from '../../LernFragen';


class ChapterModel extends Model implements TreeNode, LinkedList {

	// Mixin with TreeNode
	public assignParent: () => void;
	public root: () => TreeNode;
	public level: ( level:number ) => TreeNode[];
	public _level: ( level:number, current:TreeNode, currentDepth:number ) => TreeNode[];
	public parent: TreeNode;

	// Mixin with LinkedList
	public next:LinkedList = null;
	public previous:LinkedList = null;
	public getFirst: () => LinkedList;
	public getLast: () => LinkedList;
	public addNext: () => void;
	public addPrevious: () => void;
	public toArray: () => LinkedList[];


	get children():TreeNode[] {
		if( this.get( 'slides') != undefined ) {
			return this.get( 'slides' );        
		} else if ( this.get( 'chapters') != undefined ) {
			return this.get( 'chapters' );
		} 
	}

	/* public parent():TreeNode {
		return this.get( 'parent' ) as TreeNode;
	} */

	get slides():SlideModel[] {
		return this.get( 'slides', [] );
	}

	get categories():ChapterModel[] {
		return this.get( 'chapters', [] );
	}

}

/**
 * Apply the mixin of the classes
 */
mixin( ChapterModel, [ LinkedList, TreeNode ] );
export default ChapterModel;




