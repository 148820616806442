
export interface AssetConfig {
	url: string,
	type?: "js" | "css",
}

export class Asset {

	// store which assets are already loaded.
	static promises:{ [index:string]: Promise<any> } = {};

	config:AssetConfig;


	constructor( config:AssetConfig ) {
		if( !config.type ) config.type = this.guessType( config.url );
		this.config = config;
	}


	load():Promise<any> {

		// if not loaded create promise
		if( !Asset.promises[ this.config.url ] ) {
			( this.config.type == "js" ) ? this.loadScript() : this.loadCSS();
		}

		return Asset.promises[ this.config.url ];
	}

	/**
	 * Loads a js file into dom
	 */
	private loadScript() {

		var url = this.config.url;

		Asset.promises[ url ] = new Promise<any>( function( resolve, reject ) {
			var element = document.createElement( 'script' );
			element.src = url;
			element.addEventListener( 'load', resolve );
			element.addEventListener( 'error', reject );
			document.body.appendChild( element );
		}).then( function( event:Event ) {
			this.finalize( url );
			return event;
		}.bind( this ));
	}

	/**
	 * Loads a css file into the dom
	 */
	private loadCSS() {

		var url = this.config.url;

		Asset.promises[ url ] = new Promise<any>( function( resolve, reject ) {
			var element = document.createElement( 'link' );
			element.type = 'text/css';
            element.rel = 'stylesheet';
			element.href = url;
			element.addEventListener( 'load', resolve );
			element.addEventListener( 'error', reject );
			document.head.appendChild( element );
		}).then( function( event:Event ) {
			this.finalize( url );
			return event;
		}.bind( this ));
	}

	private finalize( url:string ) {
		Asset[ url ] = new Promise( function( resolve, reject ) { resolve() } );
	}

	/**
	 * Guesses the type based on the given url
	 */
	private guessType( url:string ):"js"|"css" {

		if( url.split('.').pop() == "js" ) return "js";
		if( url.split('.').pop() == "css" ) return "css";

		return "js";
	} 
}

export default Asset;