// import lib
import Node from 'ln/node/Node';
import List from 'ln/list/List';
import IoC from 'ln/ioc/IoC';
// import project
import Aggregator from './Aggregator';
import { AggregatorFunction } from './Aggregator';
import SlideModel from '../slides/Slide/SlideModel';

import Question from '../slides//Question/QuestionModel';


class CorrectWrong extends Aggregator {

	public static ioc: IoC<AggregatorFunction> = new IoC<AggregatorFunction>();

	/**
	 * The number of correct solved slides
	 */
	public correctSlides = 0;

	/**
	 * The total number of aggregated slides
	 */
	public totalSlides = 0;

	constructor() {
		super( CorrectWrong.ioc );
	}

	
	public aggregate( slides: SlideModel[] ) {
		this.reset();
		this.aggregateArray( slides );
	}

	/**
	 * Return the percentage of correctly answered questions
	 */
	public get percentage(): number {
		return ( this.totalSlides > 0 ) ? Math.round( this.correctSlides * 100 / this.totalSlides ) : 0;
	}

	/**
	 * Returns the number of wrong answered questions
	 */
	public get wrongSlides():number {
		return this.totalSlides - this.correctSlides;
	}

	/**
	 * Returns an object containing:
	 * - correctSlides, number of correct slides
	 * - totalSlides, number of total slides
	 * - wrongSlides, number of wrong slides
	 * - percentage, percentage of correct slides
	 * @return {JSON} A json object.
	 */
	public toJSON(): Object {
		return {
			correctSlides: this.correctSlides,
			wrongSlides: this.wrongSlides,
			totalSlides: this.totalSlides,
			percentage: this.percentage
		};
	};

	/**
	 * Resets the counters
	 */
	private reset() {
		this.correctSlides = 0;
		this.totalSlides = 0;
	}	

}

export default CorrectWrong;


var defaultAggregateFunction = function ( slideModel: Question, aggregator: CorrectWrong ) {

	// we have a question
	aggregator.totalSlides += 1;

	// one more correct slide?
	if ( slideModel.isCorrect() ) aggregator.correctSlides += 1;

};

CorrectWrong.ioc.add( 'default', defaultAggregateFunction );


