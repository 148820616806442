import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';

class Header extends View {

    private currentThema;

    constructor(){

        var themen, thema

        try {
            themen = setup.data( 'themen' )
        } catch(e) {}

        try {
            thema = setup.data( 'thema' )
        } catch(e) {}

        super( { template: 'lb.header', 'themen': themen, 'thema': thema } );

        this.currentThema = thema
    }

    init() {
        // this.node.js('theme-menu').
        var listRenderer = new ListRenderer( this.node.js('theme-menu') );

        var currentId = '';

        if( this.currentThema) currentId = this.currentThema.id



        listRenderer.defaultRender(function( item ) {
            return new View( { 'template': 'lb.theme-menuitem', 'active': (item.id == currentId) ,'thema': item, 'url': setup.route( 'thema', { 'thema': item['slug'], 'uid':'' }).url() }).render();
        }.bind( this ));
        listRenderer.source.fill( this.data.themen )

        this.node.js( 'menu-toggler' ).click.add( function() {
            this.node.js( 'theme-menu' ).toggleClass( 'hidden' );
        }, this);

    }
}

export default Header;

scanner.ioc.add( 'header', function( node:Node ) {
    return new Header( ).render( node );
});