import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import Chapter from './Chapter';
import Lernpfad from './Lernpfad';
import Element from 'lb/elements/Element';
import IoC from 'ln/ioc/IoC';
import { IoCFactoryFunction } from 'ln/list/ListRenderer';
import ElementModel from 'lb/models/ElementModel';
import { mapper } from 'lb/LernBuch';
import ModifiableList from 'ln/list/ModifiableList';
import Materiallist from './Materiallist';
import SaveDialog from './SaveDialog';
import Quiz from '../quiz/Quiz';
import Request from 'ln/request/Request';

export interface ChapterElementFactoryFunction extends IoCFactoryFunction<ElementModel> {
	( item:ElementModel ):Element;
}

export var ioc = new IoC<ChapterElementFactoryFunction>();

class Thema extends View {

    public filterDifficulty
    public filterDuration
    public currentLernpfad

    constructor( model:any ) {
        super( { 'template': 'lb.thema' } );

        this.filterDifficulty = 'simple';
        this.filterDuration = 'short';
    }

    init() {
        var data = mapper.model( setup.data( 'thema' ))

        var listRenderer = new ListRenderer<{}, ModifiableList<{}>>( this.node.js('lernpfade') );

        listRenderer.defaultRender(function( item ) {
            var view = new Lernpfad();
            view.setData( item );
            view.render();
            return view;
        });
        listRenderer.source = new ModifiableList();
        listRenderer.source.originals.fill( data.lernpfade )

        var view = this;
        listRenderer.source.modifiers.append( function( items ) {
            var lernpfade = items.filter( function( item:ElementModel ) {
                return (item.get( 'difficulty' ) == view.filterDifficulty) && (item.get( 'duration' ) == view.filterDuration)
            })
            this.currentLernpfad = lernpfade[0];
            SaveDialog.setCurrentLernpfad( this.currentLernpfad )
            return lernpfade;
        }.bind( this ))
        
        var listRendererContent = new ListRenderer( this.node.js( 'content' ));

        listRendererContent.ioc.add( 'default', function ( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });

        listRendererContent.ioc.add( 'App\\Materiallist', function ( model:ElementModel ) {
            return new Materiallist( model ).render() as Element;
        });

        listRendererContent.ioc.add( 'App\\MultipleChoice', function( model:ElementModel ) {
            return new Quiz( model ).render() as Element;
        });

        listRendererContent.ioc.add( 'App\\SingleChoice', function( model:ElementModel ) {
            return new Quiz( model ).render() as Element;
        }); 

        listRendererContent.source.fill( data.elements )

        // maybe obsolete
        this.node.all( '[js=toggle]' ).forEach( function( node ) {
            node.click.add( function( node ) {
                if( node.getAttribute( 'data-hide' ) ) this.node.one( node.getAttribute( 'data-hide' ) ).addClass( 'hidden' );
                if( node.getAttribute( 'data-show' ) ) this.node.one( node.getAttribute( 'data-show' ) ).removeClass( 'hidden' );
                if( node.getAttribute( 'data-toggle' ) ) this.node.one( node.getAttribute( 'data-toggle' ) ).toggleClass( 'hidden' );

                node.addClass( '-active' );
            }, this)
        }, this)

        this.node.one( '[js=toggleContent]' ).click.add( function( node ) {
            this.showContent( node )
        }, this)
        this.node.one( '[js=toggleLernpfad]' ).click.add( function( node ) {
            this.showLernpfad( node )
        }, this)

        if( this.loadLocalstorageUserData( 'tab' ) == 'lernpfad' ) this.showLernpfad( this.node.js( 'toggleLernpfad' ) )

        this.node.js( 'filter-difficulty' ).change.add( function( node ) {
            // convert from boolean to string
            if( node.checked == true ) {
                this.filterDifficulty = 'complex';
                setup.data( 'thema' ).difficulty = 'complex'
            } else {
                this.filterDifficulty = 'simple';
                setup.data( 'thema' ).difficulty = 'simple'
            }
            listRenderer.source.update();
            this.saveUserData( 'difficulty', this.filterDifficulty );
        }, this)

        this.node.js( 'filter-duration' ).change.add( function( node ) {
            // convert from boolean to string
            if( node.checked == true ) {
                this.filterDuration = 'long';
                setup.data( 'thema' ).duration = 'long';
            } else {
                this.filterDuration = 'short';
                setup.data( 'thema' ).duration = 'short';
            }
            listRenderer.source.update();
            this.saveUserData( 'duration', this.filterDuration );

        }, this)


        if( this.loadLocalstorageUserData( 'duration' ) == 'long' ) {
            this.node.js( 'filter-duration' ).checked = true;
            this.filterDuration = 'long';
        }
        if( this.loadLocalstorageUserData( 'duration' ) == 'short' ) {
            this.node.js( 'filter-duration' ).checked = false;
            this.filterDuration = 'short';
        }
        if( this.loadLocalstorageUserData( 'difficulty' ) == 'complex' ) {
            this.node.js( 'filter-difficulty' ).checked = true;
            this.filterDifficulty = 'complex';
        }
        if( this.loadLocalstorageUserData( 'difficulty' ) == 'simple' ) {
            this.node.js( 'filter-difficulty' ).checked = false;
            this.filterDifficulty = 'simple';
        }

        if( data.duration == 'long' ) {
            this.node.js( 'filter-duration' ).checked = true;
            this.filterDuration = 'long';
        }
        if( data.duration == 'short' ) {
            this.node.js( 'filter-duration' ).checked = false;
            this.filterDuration = 'short';
        }
        if( data.difficulty == 'complex' ) {
            this.node.js( 'filter-difficulty' ).checked = true;
            this.filterDifficulty = 'complex';
        }
        if( data.difficulty == 'simple' ) {
            this.node.js( 'filter-difficulty' ).checked = false;
            this.filterDifficulty = 'simple';
        }

        listRenderer.source.update();

    }

    renderData() {
        return setup.data();
    }

    saveUserData( attribute, value ) {
        if( !setup.data( 'savestate' ).id ) {
            localStorage.setItem( setup.data( 'thema' ).id + '-' + attribute, value );
        }

        var data = {
            'savedModelName': setup.data( 'thema' ).modelName,
            'savedModelId': setup.data( 'thema' ).id,
            'attribute': attribute,
            'value': value,
        };

        Request.post( setup.route( 'userData' ).url() )
            .headers( { 'Content-Type':'application/json' } )
            .send( JSON.stringify( data ));
    }

    loadLocalstorageUserData( key ) {
        return localStorage.getItem( setup.data( 'thema' ).id + '-' + key );
    }

    showContent( node ) {
        this.node.one( '[js=content]' ).removeClass( 'hidden' )
        this.node.one( '[js=lernpfade]' ).addClass( 'hidden' )
        node.addClass( '-active' )
        this.node.one( '[js=toggleLernpfad]' ).removeClass( '-active')
        this.saveUserData( 'tab', 'content' );

    }

    showLernpfad( node ) {
        this.node.one( '[js=content]' ).addClass( 'hidden' )
        this.node.one( '[js=lernpfade]' ).removeClass( 'hidden' )
        node.addClass( '-active' )
        this.node.one( '[js=toggleContent]' ).removeClass( '-active')
        this.saveUserData( 'tab', 'lernpfad' );
    }
}

export default Thema;

scanner.ioc.add( 'thema', function( node:Node ) {
    return new Thema( ).render( node );
});