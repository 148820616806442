// import lib
import DragDrop from '../DragDrop/DragDrop';
import DragDropModel from '../DragDrop/DragDropModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';
/**
 * A  variation of the DropDown Model to order drag items.
 */

class Order extends DragDrop {

    model:DragDropModel;
	constructor( model: DragDropModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );
		this.maxDragsOnDrop = this.model.get( 'maxDragsOnDrop', 1 );

		this.defaultTemplate = this.model.get( 'template', 'lf.order-slide' );
		this.dragTemplate = this.model.get( 'dragTemplate', 'lf.order-dragitem' );
		this.dropTemplate = this.model.get( 'dropTemplate', 'lf.order-dropitem' );
	}
}

export default Order;