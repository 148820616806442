// import library
import Signal from 'ln/signal/Signal';
import { EmptyCallback } from 'ln/signal/Signal';

import SlideModel from '../Slide/SlideModel';
import LernFragen from '../../LernFragen';


/**
 * An interface for a Button 
 */
export interface Button {
	label: string;
	text: string;
}

/**
 * The model for a reveal slide
 */
export class RevealModel extends SlideModel {


	/**
	 * Returns the array of buttons
	 */
	get buttons(): Button[] {
		if ( this.get( 'buttons' ) == undefined ) this.set( 'buttons', new Array<Button>() );
		return this.get( 'buttons' ) as Button[];
	}

}

export default RevealModel;
