import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import { mapper } from 'lb/LernBuch';
import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import MaterialcardImage from './MaterialcardImage';
import Materiallist from './Materiallist';

class Task extends View {
    public data;

    constructor( ) {
        super( { 'template': 'lb.task' } );
    }

    init() {

        var data = mapper.model( setup.data( 'task' ))
        var listRendererContent = new ListRenderer( this.node.js( 'content' ));

        listRendererContent.ioc.add( 'default', function ( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });

        listRendererContent.ioc.add( 'App\\Materiallist', function ( model:ElementModel ) {
            return new Materiallist( model ).render() as Element;
        });

        listRendererContent.source.fill( data.elements )

        if( data.materials.length > 0 ) {

            var listRendererMaterials = new ListRenderer( this.node.js( 'taskmaterials' ));

            listRendererMaterials.ioc.add( 'default', function( model:ElementModel ) {
                return new Element( model ).render() as Element;
            });

            listRendererMaterials.ioc.add( 'App\\MaterialImage', function ( model:ElementModel ) {
                return new MaterialcardImage( model ).render() as Element;
            });

            listRendererMaterials.source.fill( data.materials );
        }
    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
        return this;
    } 
}

export default Task;

scanner.ioc.add( 'task', function( node:Node, data ) {
    return new Task( ).setData( data).render( node );
});