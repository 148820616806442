// import lib
import View from 'ln/view/View';
import Scanner from 'ln/view/Scanner';
import Slide from './slides/Slide/SlideModel';
import SlideRenderer from './sliderenderer/SlideRenderer';
import Mapper from 'ln/model/Mapper';
import { common as commonModels } from './setup/models';
import { all as commonViews } from './setup/lernfragen';
import { common as commonSlides } from './setup/slides';

/**
 * Main class for LernFragen quizzes
 */
class LernFragen extends View {

	public scanner:Scanner;
	public mapper:Mapper;

	private slides:( renderer:SlideRenderer ) => void;

	constructor() {
		super();
		this.scanner = new Scanner();
		this.mapper = new Mapper();

		this.defaultTemplate = 'lf.lernfragen';
	}

	public init() {

		// make sure slide renderer is instantiate first.
		this.scanner.scan( this.node, this, [ 'slide-renderer' ] );

		if( this.renderer ) this.slides( this.renderer );
	}

	/**
	 * Default setup with all slides, models and views.
	 */
	public defaultSetup() {
		return this.setup( commonSlides, commonModels, commonViews );
	}

	/**
	 * Setups the sliderenderer, mapper and viewscanner with corresponding closures that registers themself.
	 */
	public setup( slides:( renderer:SlideRenderer ) => void, models:( mapper:Mapper ) => void, views:( scanner:Scanner ) => void ) {

		views( this.scanner );
		models( this.mapper );

		this.slides = slides;
		return this;
	}

	/**
	 * Returns the slide renderer from the scanner
	 */
	get renderer():SlideRenderer {
		return this.scanner.first( 'slide-renderer', this.node ) as SlideRenderer;
	}
}


export default LernFragen;