// import lib
import Model from 'ln/model/Model';
import LinkedList from 'ln/linkedlist/LinkedList';
import TreeNode from 'ln/tree/TreeNode';
import { mixin } from 'ln/js';
// import project
import Chapter from '../Chapter/ChapterModel';

class SlideModel extends Model {

	// Mixin with TreeNode
	public parent(): TreeNode {
		return this.get( 'parent' ) as TreeNode;
	}
	public children(): TreeNode[] {
		return this.get( 'children' ) as TreeNode[];
	};
	public assignParent: () => void;
	public root: ( treeNode?: TreeNode ) => TreeNode;

	// Mixin with LinkedList
	public next: LinkedList = null;
	public previous: LinkedList = null;
	public getFirst: () => LinkedList;
	public getLast: () => LinkedList;
	public addNext: () => void;
	public addPrevious: () => void;
	public toArray: () => LinkedList[];

	get chapter(): Chapter {
		if ( this.parent() instanceof Chapter ) return this.parent() as Chapter;
		return ( this.parent() as SlideModel ).chapter as Chapter;
	}

	/**
	 * Returns the model name of this slide
	 */
	get modelName(): string {
		return this.get( 'modelName', '' );
	}

	/**
	 * Returns the name for the aggregators's ioc lookup
	 * This method may be overwritten by other slide types
	 */
	get aggregatorKey():string {
		return this.modelName;
	}

	/**
	 * Return if this question has a user input
	 * This is an abstract methode, because every model
	 * can have different type of user inputs
	 */
	public hasUserInput(): boolean {
		return false;
	}

	/**
	 * An abstract methode usually used for question slides
	 */
	public markAsAnswered() {
	}

}

/**
 * Apply the mixin of the classes
 */
mixin( SlideModel, [LinkedList, TreeNode] );

export default SlideModel;




