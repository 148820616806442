// improt lib
import Signal from 'ln/signal/Signal';
import View from 'ln/view/View';
import { EmptyCallback } from 'ln/signal/Signal';

// import project
import ElementModel from '../models/ElementModel';
import LernBuch from 'lb/LernBuch';
import Chapter from './Chapter';

/**
 * A base class for elements that can be rendered in a chapter
 */
class MaterialcardImage extends View {

	public model:ElementModel;
	//public chapter:Chapter

	/**
	 * Sets the configuration for an element
	 * @param model ElementModel
	 */
	constructor( model:ElementModel ) {
		super();
		this.model = model;
		this.defaultTemplate = ( this.model ) ? this.model.get( 'template', 'lb.' + this.model.modelName.toLowerCase().replace( 'app\\', '' ) + '-element' ) : '';
	}
	
	/**
	 * Execute things after element was rendered
	 * For example register to events on the element
	 */
	public init() {
		
		this.node.setAttribute( 'name', this.model.uid );
        this.node.setAttribute( 'id', this.model.uid );
        
        /*this.node.click.add( function() {
            // view image?
        })*/
	}

	/**
	 * Define the data to render on the element
	 */
	protected renderData(){
		return this.model.object();
	}
}

export default MaterialcardImage;