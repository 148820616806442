import Scanner from 'ln/view/Scanner';
import SlideRenderer from '../sliderenderer/SlideRenderer';

import SlideModel from '../slides/Slide/SlideModel';
import ClozeModel from '../slides/Cloze/ClozeModel';
import DragDropModel from '../slides/DragDrop/DragDropModel';
import FreetextModel from '../slides/FreeText/FreetextModel';
import HotspotModel from '../slides/HotSpot/HotspotModel';
import MultipleChoiceModel from '../slides/MultipleChoice/MultipleChoiceModel';
import RevealModel from '../slides/Reveal/RevealModel';
import SingleChoiceModel from '../slides/SingleChoice/SingleChoiceModel';
import TooltipModel from '../slides/ToolTip/TooltipModel';
import DropDownModel from '../slides/DropDown/DropDownModel';
import OrderModel from '../slides/Order/OrderModel';
import DragDropClozeModel from '../slides/DragDropCloze/DragDropClozeModel';
import DragDropSentenceModel from '../slides/DragDropSentence/DragDropSentenceModel';
import MatrixChoiceModel from '../slides/MatrixChoice/MatrixChoiceModel';
import MatrixBinaryChoiceModel from '../slides/MatrixBinaryChoice/MatrixBinaryChoiceModel';


import Slide from '../slides/Slide/Slide';
import Cloze from '../slides/Cloze/Cloze';
import DragDrop from '../slides/DragDrop/DragDrop';
import Freetext from '../slides/FreeText/Freetext';
import Hotspot from '../slides/HotSpot/Hotspot';
import MultipleChoice from '../slides/MultipleChoice/MultipleChoice';
import Reveal from '../slides/Reveal/Reveal';
import SingleChoice from '../slides/SingleChoice/SingleChoice';
import Tooltip from '../slides/ToolTip/Tooltip';
import DropDown from '../slides/DropDown/DropDown';
import Order from '../slides/Order/Order';
import DragDropCloze from '../slides/DragDropCloze/DragDropCloze';
import DragDropSentence from '../slides/DragDropSentence/DragDropSentence';
import MatrixChoice from '../slides/MatrixChoice/MatrixChoice';
import MatrixBinaryChoice from '../slides/MatrixBinaryChoice/MatrixBinaryChoice';

import EvaluateAnswersDeco from '../decorators/EvaluateAnswers';
import DisableInputs from '../decorators/DisableInputs';
import RelatedFeedbackDeco from '../decorators/RelatedFeedback';


export function base( renderer:SlideRenderer ) {
	renderer.ioc.add( 'default', function ( model:SlideModel, slideRenderer:SlideRenderer ) {
		return new Slide( model, slideRenderer ).render() as Slide;
	});
}

export function cloze( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Cloze', function( model:ClozeModel, slideRenderer:SlideRenderer ) {
		return new Cloze( model, slideRenderer ).render() as Cloze;
	});

	renderer.ioc.alias( 'App\\Cloze', 'Cloze' );
}

export function dragdrop( renderer:SlideRenderer ) {
	renderer.ioc.add( 'DragDrop', function( model:DragDropModel, slideRenderer:SlideRenderer ) {
		return new DragDrop( model, slideRenderer ).render() as DragDrop;
	});

	renderer.ioc.alias( 'App\\DragDrop', 'DragDrop' );
}

export function freetext( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Freetext', function ( model:FreetextModel, slideRenderer:SlideRenderer ) {
		return new Freetext( model, slideRenderer ).render() as Freetext;
	});

	renderer.ioc.alias( 'App\\Freetext', 'Freetext' );
}

export function hotspot( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Hotspot', function ( model:HotspotModel, slideRenderer:SlideRenderer ) {
		return new Hotspot( model, slideRenderer ).render() as Hotspot;
	});

	renderer.ioc.alias( 'App\\Hotspot', 'Hotspot' );
}

export function multiplechoice( renderer:SlideRenderer ) {
	renderer.ioc.add( 'MultipleChoice', function ( model:MultipleChoiceModel, slideRenderer:SlideRenderer ) {
		return new MultipleChoice( model, slideRenderer ).render() as MultipleChoice;
	});

	renderer.ioc.alias( 'App\\MultipleChoice', 'MultipleChoice' );
}

export function reveal( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Reveal', function ( model:RevealModel, slideRenderer:SlideRenderer ) {
		return new Reveal( model, slideRenderer ).render() as Reveal;
	});

	renderer.ioc.alias( 'App\\Reveal', 'Reveal' );
}

export function singlechoice( renderer:SlideRenderer ) {
	renderer.ioc.add( 'SingleChoice', function ( model:SingleChoiceModel, slideRenderer:SlideRenderer ) {
		return new SingleChoice( model, slideRenderer ).render() as SingleChoice;
	});

	renderer.ioc.alias( 'App\\SingleChoice', 'SingleChoice' );
}

export function tooltip( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Tooltip', function ( model:TooltipModel, slideRenderer:SlideRenderer ) {
		return new Tooltip( model, slideRenderer ).render() as Tooltip;
	});

	renderer.ioc.alias( 'App\\Tooltip', 'Tooltip' );
}

export function dropdown( renderer:SlideRenderer ) {
	renderer.ioc.add( 'DropDown', function( model:DropDownModel, slideRenderer:SlideRenderer ) {
		return new DropDown( model, slideRenderer ).render() as DropDown;
	});

	renderer.ioc.alias( 'App\\DropDown', 'DropDown' );
}

export function order( renderer:SlideRenderer ) {
	renderer.ioc.add( 'Order', function( model:OrderModel, slideRenderer:SlideRenderer ) {
		return new Order( model, slideRenderer ).render() as Order;
	});

	renderer.ioc.alias( 'App\\Order', 'Order' );
}

export function dragdropcloze( renderer:SlideRenderer ) {
	renderer.ioc.add( 'DragDropCloze', function( model:DragDropClozeModel, slideRenderer:SlideRenderer ) {
		return new DragDropCloze( model, slideRenderer ).render() as DragDropCloze;
	});

	renderer.ioc.alias( 'App\\DragDropCloze', 'DragDropCloze' );
}

export function dragdropsentence( renderer:SlideRenderer ) {
	renderer.ioc.add( 'DragDropSentence', function( model:DragDropSentenceModel, slideRenderer:SlideRenderer ) {
		return new DragDropSentence( model, slideRenderer ).render() as DragDropSentence;
	});

	renderer.ioc.alias( 'App\\DragDropSentence', 'DragDropSentence' );
}

export function matrixchoice( renderer:SlideRenderer ) {
	renderer.ioc.add( 'MatrixChoice', function( model:MatrixChoiceModel, slideRenderer:SlideRenderer ) {
		return new MatrixChoice( model, slideRenderer ).render() as MatrixChoice;
	});

	renderer.ioc.alias( 'App\\MatrixChoice', 'MatrixChoice' );
}

export function matrixbinarychoice( renderer:SlideRenderer ) {
	renderer.ioc.add( 'MatrixBinaryChoice', function( model:MatrixBinaryChoiceModel, slideRenderer:SlideRenderer ) {
		return new MatrixBinaryChoice( model, slideRenderer ).render() as MatrixBinaryChoice;
	});

	renderer.ioc.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );
}


export function common( renderer:SlideRenderer ) {
	base( renderer );
	singlechoice( renderer );
	tooltip( renderer );
	multiplechoice( renderer );
	reveal( renderer );
	hotspot( renderer );
	freetext( renderer );
	cloze( renderer );
	dragdrop( renderer );
	dropdown( renderer );
	order( renderer );
	dragdropcloze( renderer );
	dragdropsentence( renderer );
	matrixchoice( renderer );
	matrixbinarychoice( renderer );
}