// import library
import Signal from 'ln/signal/Signal';
import { EmptyCallback } from 'ln/signal/Signal';

import SlideModel from '../Slide/SlideModel';
import LernFragen from '../../LernFragen';


/**
 * An interface for a tooltip 
 */
export interface TooltipData {
	height: number;
	top: number;
	left: number;
	width: number;
	text: string;
}

/**
 * The model for a tooltip image slide
 */
export class TooltipModel extends SlideModel {


	/**
	 * Returns the array of tooltips
	 */
	get tooltips(): TooltipData[] {
		return this.get( 'tooltips', [] ) as TooltipData[];
	}

}

export default TooltipModel;