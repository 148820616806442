// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
// import project
import AnswerList from '../AnswerList/AnswerList';
import SingleChoiceModel from '../SingleChoice/SingleChoiceModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A class that represents a single choice question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		modelName: SingleChoice,
 *		imgPath: "url",
 *		description: "My question."
 *		answers: [
 *			{ text: "Answer 1" },
 *			{ text: "Answer 2", correct: true, selected: true },
 *			{ text: "Answer 3" }
 *		]
 *	}
 * 
 */
class SingleChoice extends AnswerList {

	public model:SingleChoiceModel;

	constructor( model:SingleChoiceModel, slideRenderer:SlideRenderer ) {
		super( model, slideRenderer );
		this.defaultTemplate = 'lf.answerlist-slide';
		this.answerListTemplate =  'lf.singlechoice-answer';
	}

}

export default SingleChoice;