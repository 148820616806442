import View from '../../core/view/View';
import Model from '../../core/model/Model';
import { Signal, EmptyCallback } from '../../core//signal/Signal';
import ModifiableList from '../../core/list/ModifiableList';

abstract class ModelFilter extends View {

    protected change: Signal<EmptyCallback> = new Signal<EmptyCallback>();
    protected searchAttributes: string[];
    

    /*
    * searchAttributes: the key on which to search for (e.g. the columns of the model )
    */
    constructor( searchAttributes: string[] | string) {
        super();
        if( !Array.isArray( searchAttributes) ) searchAttributes = [ searchAttributes ];
        this.searchAttributes = searchAttributes;
    }

    /*
    * optionally inject the ModifiableList to register this filter and listen to the change event
    */
    registerOn( filterList: ModifiableList<Model> ) {
        filterList.modifiers.append( this.filter.bind( this ) );
        this.change.add( filterList.update.bind( filterList ) );
    }

    /**
     * Returns true/false if the given value was found on the given data object.
     */
    filterIndexOf( value:string, data:Model ):boolean {
        return this.searchAttributes.some( (attribute) => {
            return ( data.get( attribute ) as string|string[] ).indexOf( value ) >= 0;
        });
    }

    abstract reset(): void;

    abstract filter( data:Model[] ):Model[];

}

export default ModelFilter;