import IoC from '../ioc/IoC';
import Node from '../node/Node';
import View from './View';


export interface FactoryFunction {
	( node:Node, data?:any ):View;
}


/**
 * This class scans over a html document for views to instantiate and render.
 */
export class Scanner {

	public ioc:IoC<FactoryFunction> = new IoC<FactoryFunction>();

	/**
	 * Runs the scanner for all registered keys in the ioc.
	 * @param root The root node where the scanner should search
	 * @param data The data that is passed to the ioc function
	 * @param order An optional order to make sure the scanner initializes in the correct order.
	 */
	scan( root:Node, data:any = undefined, order:string[] = [] ):void {

		// sort keys based on given order
		var keys = order.concat( this.ioc.keys() );

		// make sure keys are unique
		keys = keys.filter( function( value, index, self ) { 
			return self.indexOf( value ) === index;
		});

		// get all registered keys.
		keys.forEach( key => {

			// css lookup for all nodes with the given view attribute
			var nodes = root.all( '[view=' + key + ']' );
			
			nodes.forEach( node => {

				// do not scan if its already created
				if( node.native.view ) return;

				// create view from ioc
				var view = this.ioc.get( key )( node, data );

				// store instance on native node
				if( view ) node.native.view = view;
			});
		});
	}

	first( name:string, root?:Node ) {
		if( root == undefined ) root = Node.fromNative( document.body );
		var node = root.one( '[view=' + name + ']' );
		return ( node && node.native.view ) ? node.native.view : null;
	}

	
	all( name:string, root?:Node ) {

		if( root == undefined ) root = Node.fromNative( document.body );

		var views = [];
		
		var nodes = root.all( '[view=' + name + ']' );
		nodes.forEach( node => {
			if( node.native.view ) views.push( node.native.view );
		});
		
		return views;
	}
}

export var scanner = new Scanner();

export default Scanner;
