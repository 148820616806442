import Node from 'ln/node/Node';
import SlideView from '../slides/Slide/Slide';
import SlideModel from '../slides/Slide/SlideModel';

export interface DecoratorFunction {
	( model:SlideModel, view:SlideView ):void;
}

/**
 * This is an abstract class for all decoratos. These are classes that can manipulate a slide views html.<br/>
 */
class Decorator {

	constructor() {
	}

	/**
	 * Abstract decorate function
	 */
	public decorate( slideModel:SlideModel, slideView:SlideView ) {
	};

	/**
	 * Helper function to decorate the correct answer on one node.
	 */
	protected decorateSolutionOn( node:Node , bool:boolean ) {
		node.addClass( ( bool ) ? '-correct' : '-wrong' );
	};

	/**
	 * Helper function to remove all decoration on a node.
	 */
	protected clearSolutionOn( node:Node ) {
		node.removeClass( '-wrong' );
		node.removeClass( '-correct' );
	};

}

export default Decorator;