// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
// import project
import Question from '../Question/Question';
import FreetextModel from './FreetextModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


class Freetext extends Question {
	/**
	 * The model
	 */
	public model: FreetextModel;

	constructor( model: FreetextModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		// set default values
		this.defaultTemplate = this.model.get( 'template', 'lf.freetext-slide' );
	}

	protected init() {
		super.init();

		var c = this.node.js( 'text' );

		c.value = this.model.get( 'value', '' );
		c.keyup.add( this.onValueChanged, this );
	}

	private onValueChanged() {
		this.model.value = this.node.js( 'text' ).value;
	}

}
export default Freetext;