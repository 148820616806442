import Scanner from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import LernFragen from '../LernFragen';
import SlideRenderer from '../sliderenderer/SlideRenderer';
import NextSolutionNavigation from '../navigation/NextSolutionNavigation';
import NextPrevNavigation from '../navigation/NextPrevNavigation';
import PointsNavigation from '../navigation/PointsNavigation';
import AnsweredButton from '../navigation/AnsweredButton';
import ResponsiveNavigation from '../navigation/ResponsiveNavigation';
import Status from '../navigation/Status';


export function slideRenderer( scanner:Scanner ) {
	scanner.ioc.add( 'slide-renderer', function( node:Node, lernfragen:LernFragen ) {
		return new SlideRenderer().render( node );
	});
}

export function status( scanner:Scanner ) {
	scanner.ioc.add( 'status', function( node:Node, lernfragen:LernFragen ) {
		return new Status( lernfragen.renderer ).render( node );
	});
}


export function solutionNavi( scanner:Scanner ) {
	scanner.ioc.add( 'next-solution-navigation', function( node:Node, lernfragen:LernFragen ) {
		return new NextSolutionNavigation( lernfragen.renderer ).render( node );
	});
}

export function nextNavi( scanner:Scanner ) {
	scanner.ioc.add( 'next-previous-navigation', function( node:Node, lernfragen:LernFragen ) {
		return new NextPrevNavigation( lernfragen.renderer ).render( node );
	});
}

export function responsiveNavi( scanner:Scanner ) {
	scanner.ioc.add( 'responsive-navigation', function( node:Node, lernfragen:LernFragen ) {
		return new ResponsiveNavigation( lernfragen.renderer ).render( node );
	});
}

export function pointsNavi( scanner:Scanner ) {
	scanner.ioc.add( 'points-navigation', function( node:Node, lernfragen:LernFragen ) {
		return new PointsNavigation( lernfragen.renderer ).render( node );
	});
}

export function answeredButton( scanner:Scanner ) {
	scanner.ioc.add( 'answered-button', function( node:Node, lernfragen:LernFragen ) {
		return new AnsweredButton( lernfragen.renderer ).render( node );
	});
}


export function all( scanner:Scanner  ) {
	slideRenderer( scanner );
	solutionNavi( scanner );
	nextNavi( scanner );
	pointsNavi( scanner );
	answeredButton( scanner );
	responsiveNavi( scanner );
	status( scanner );
}