import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';

class Footer extends View {
    constructor(){
        super( { template: 'lb.footer'} );
    }
}

export default Footer;

scanner.ioc.add( 'footer', function( node:Node ) {
    return new Footer( ).render( node );
});