// import lib
import Node from 'ln/node/Node';
import ListRenderer from 'ln/list/ListRenderer';

import DragDrop from '../DragDrop/DragDrop';
import { Drag, DragDropSentenceModel} from './DragDropSentenceModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';
/**
 * A  variation of the DropDown Model to order drag items.
 */
class DragDropSentence extends DragDrop {
	
	public dragListRenderer: ListRenderer<Drag>;
	public model: DragDropSentenceModel;

	constructor( model: DragDropSentenceModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		this.defaultTemplate = this.model.get( 'template', 'lf.dragdrop-sentence-slide' );
		this.dragTemplate = this.model.get( 'dragTemplate', 'lf.dragdrop-drag-sentence-item' );
		this.dropTemplate = this.model.get( 'dropTemplate', 'lf.dragdrop-drop-sentence-item' );

		this.maxDragsOnDrop = this.model.get( 'maxDragsOnDrop', 100 );

	}

	/**
	 * Move the drops to the drop targets according 'dropped' and 'current_position' values of the model
	 */
	protected initDropPositions() {
		
		this.model.drags.sort(this.compare);
		super.initDropPositions();
	}

	protected onDropped( element: Node, target: Node, source: Node, sibling: Node ) {

		var dragModel = this.model.drags[ element.data.index ];
		var dropIndex = target.data.index; // dropIndex includes start drop area => index + 1
		var dropModel = this.model.drops[ dropIndex - 1 ];

		if ( target.hasClass( 'target' ) ) {
			dragModel.dropped = dropIndex;

			if ( this.dragsOnDrop( dropModel ) > this.maxDragsOnDrop ) {
				this.removeFirstDrag( dropModel );
			}

		} else {
			dragModel.dropped = 0;
		}

		this.setNewDragPositions( this.dragListRenderer );
		
		this.model.fireUserInput();

		 if ( this.model.isAnswered() ) {
		 	this.model.fireUserAnswered();
		 }

	}

	
	public setNewDragPositions( dragListRenderer ) {
		
		Node.js('drop-container').all('.drag-node').forEach(function (node, index) {
			var  link = dragListRenderer.linkOf(node); 
			link.item.current_position = index;
		});
		Node.js('drag-container').all('.drag-node').forEach(function (node, index) {
			var  link = dragListRenderer.linkOf(node); 
			link.item.current_position = -1;
		});
	}

	protected  compare(a, b) {
		return a.current_position - b.current_position;
	}

}

export default DragDropSentence;