// import lib
import View from 'ln/view/View';


// import project
import EvaluateAnswers from '../decorators/EvaluateAnswers';
import LernFragen from '../LernFragen';
import SlideRenderer from '../sliderenderer/SlideRenderer';

class AnsweredButton extends View {

    private renderer;

    constructor( renderer:SlideRenderer ) {
        super();
        this.renderer = renderer;
        this.defaultTemplate = 'lf.answered-button';
    }

	/**
	 * Register click event on answered-button
	 */
	public init() {
        this.node.click.add(  () => {
            this.renderer.currentModel.markAsAnswered();
        });
	}

}

export default AnsweredButton;