import Mapper from 'ln/model/Mapper';
import Slide from '../slides/Slide/SlideModel';
import Chapter from '../slides/Chapter/ChapterModel';
import SingleChoice from '../slides/SingleChoice/SingleChoiceModel';
import MultipleChoice from '../slides/MultipleChoice/MultipleChoiceModel';
import Cloze from '../slides/Cloze/ClozeModel';
import DragDrop from '../slides/DragDrop/DragDropModel';
import Freetext from '../slides/FreeText/FreetextModel';
import Hotspot from '../slides/HotSpot/HotspotModel';
import Reveal from '../slides/Reveal/RevealModel';
import Tooltip from '../slides/ToolTip/TooltipModel';
import Summary from '../slides/Summary/SummaryModel';
import DropDown from '../slides/DropDown/DropDownModel';
import Order from '../slides/Order/OrderModel';
import DragDropCloze from '../slides/DragDropCloze/DragDropClozeModel';
import DragDropSentence from '../slides/DragDropSentence/DragDropSentenceModel';
import MatrixChoice from '../slides/MatrixChoice/MatrixChoiceModel';
import MatrixBinaryChoice from '../slides/MatrixBinaryChoice/MatrixBinaryChoiceModel';



export function base( mapper:Mapper ) {
	mapper.toModel.add( 'Slide', function ( json ) {
		return new Slide( json );
	});

	mapper.toJson.add( 'Slide', function ( model:Slide ) {
		return model.object();
	});

	mapper.toModel.alias( 'App\\Slide', 'Slide' );
	mapper.toJson.alias( 'App\\Slide', 'Slide' );
};

export function chapter( mapper:Mapper ) {
	mapper.toModel.add( 'Chapter', function ( json ) {
		var chapter = new Chapter( json ); 
		chapter.assignParent();
		return chapter;
	});

	mapper.toJson.add( 'Chapter', function ( chapter:Chapter ) {
		return chapter.object();
	});

	mapper.toModel.alias( 'App\\Chapter', 'Chapter' );
	mapper.toJson.alias( 'App\\Chapter', 'Chapter' );

};


export function singlechoice( mapper:Mapper ) {
	mapper.toModel.add( 'SingleChoice', function ( json ) {
		return new SingleChoice( json );
	});
	mapper.toJson.add( 'SingleChoice', function ( model:SingleChoice ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});

	mapper.toModel.alias( 'App\\SingleChoice', 'SingleChoice' );
	mapper.toJson.alias( 'App\\SingleChoice', 'SingleChoice' );
};


export function multiplechoice( mapper:Mapper ) {
	mapper.toModel.add( 'MultipleChoice', function ( json ) {
		return new MultipleChoice( json );
	});
	mapper.toJson.add( 'MultipleChoice', function ( model:MultipleChoice ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\MultipleChoice', 'MultipleChoice' );
	mapper.toJson.alias( 'App\\MultipleChoice', 'MultipleChoice' );
};

export function cloze( mapper:Mapper ) {
	mapper.toModel.add( 'Cloze', function ( json ) {
		return new Cloze( json );
	});
	mapper.toJson.add( 'Cloze', function ( model:Cloze ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});

	mapper.toModel.alias( 'App\\Cloze', 'Cloze' );
	mapper.toJson.alias( 'App\\Cloze', 'Cloze' );
};

export function dragdrop( mapper:Mapper ) {
	mapper.toModel.add( 'DragDrop', function ( json ) {
		return new DragDrop( json );
	});
	mapper.toJson.add( 'DragDrop', function ( model:DragDrop ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\DragDrop', 'DragDrop' );
	mapper.toJson.alias( 'App\\DragDrop', 'DragDrop' );
};

export function freetext( mapper:Mapper ) {
	mapper.toModel.add( 'Freetext', function ( json ) {
		return new Freetext( json );
	});
	mapper.toJson.add( 'Freetext', function ( model:Freetext ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});

	mapper.toModel.alias( 'App\\Freetext', 'Freetext' );
	mapper.toJson.alias( 'App\\Freetext', 'Freetext' );
};

export function hotspot( mapper:Mapper ) {
	mapper.toModel.add( 'Hotspot', function ( json ) {
		return new Hotspot( json );
	});
	mapper.toJson.add( 'Hotspot', function ( model:Hotspot ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\Hotspot', 'Hotspot' );
	mapper.toJson.alias( 'App\\Hotspot', 'Hotspot' );
};

export function reveal( mapper:Mapper ) {
	mapper.toModel.add( 'Reveal', function ( json ) {
		return new Reveal( json );
	});
	mapper.toJson.add( 'Reveal', function ( model:Reveal ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\Reveal', 'Reveal' );
	mapper.toJson.alias( 'App\\Reveal', 'Reveal' );
};

export function tooltip( mapper:Mapper ) {
	mapper.toModel.add( 'Tooltip', function ( json ) {
		return new Tooltip( json );
	});
	mapper.toJson.add( 'Tooltip', function ( model:Tooltip ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\Tooltip', 'Tooltip' );
	mapper.toJson.alias( 'App\\Tooltip', 'Tooltip' );
};

export function summary( mapper:Mapper ) {
	mapper.toModel.add( 'Summary', function ( json ) {
		return new Summary( json );
	});
	mapper.toJson.add( 'Summary', function ( model:Summary ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\Summary', 'Summary' );
	mapper.toJson.alias( 'App\\Summary', 'Summary' );
};

export function dropdown( mapper:Mapper ) {
	mapper.toModel.add( 'DropDown', function ( json ) {
		return new DropDown( json );
	});
	mapper.toJson.add( 'DropDown', function ( model:DropDown ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});

	mapper.toModel.alias( 'App\\DropDown', 'DropDown' );
	mapper.toJson.alias( 'App\\DropDown', 'DropDown' );
};

export function order( mapper:Mapper ) {
	mapper.toModel.add( 'Order', function ( json ) {
		return new Order( json );
	});
	mapper.toJson.add( 'Order', function ( model:Order ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\Order', 'Order' );
	mapper.toJson.alias( 'App\\Order', 'Order' );
};

export function dragdropcloze( mapper:Mapper ) {
	mapper.toModel.add( 'DragDropCloze', function ( json ) {
		return new DragDropCloze( json );
	});
	mapper.toJson.add( 'DragDropCloze', function ( model:DragDropCloze ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\DragDropCloze', 'DragDropCloze' );
	mapper.toJson.alias( 'App\\DragDropCloze', 'DragDropCloze' );
};

export function dragdropsentence( mapper:Mapper ) {
	mapper.toModel.add( 'DragDropSentence', function ( json ) {
		return new DragDropSentence( json );
	});
	mapper.toJson.add( 'DragDropSentence', function ( model:DragDropSentence ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\DragDropSentence', 'DragDropSentence' );
	mapper.toJson.alias( 'App\\DragDropSentence', 'DragDropSentence' );
};

export function matrixchoice( mapper:Mapper ) {
	mapper.toModel.add( 'MatrixChoice', function ( json ) {
		return new MatrixChoice( json );
	});
	mapper.toJson.add( 'MatrixChoice', function ( model:MatrixChoice ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\MatrixChoice', 'MatrixChoice' );
	mapper.toJson.alias( 'App\\MatrixChoice', 'MatrixChoice' );
};

export function matrixbinarychoice( mapper:Mapper ) {
	mapper.toModel.add( 'MatrixBinaryChoice', function ( json ) {
		return new MatrixBinaryChoice( json );
	});
	mapper.toJson.add( 'MatrixBinaryChoice', function ( model:MatrixBinaryChoice ) {
		var obj = model.object();
		delete obj.parent;
		return obj;
	});
	mapper.toModel.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );
	mapper.toJson.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );
};


export function common( mapper:Mapper ) {
	base( mapper );
	chapter( mapper );
	singlechoice( mapper );
	multiplechoice( mapper );
	cloze( mapper );
	hotspot( mapper );
	dragdrop( mapper );
	freetext( mapper );
	reveal( mapper );
	tooltip( mapper );
	summary( mapper );
	dropdown( mapper);
	order( mapper);
	dragdropcloze ( mapper );
	dragdropsentence ( mapper );
	matrixchoice ( mapper );
	matrixbinarychoice ( mapper );
}


