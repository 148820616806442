// import lib
import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Signal from 'ln/signal/Signal';
import IoC from 'ln/ioc/IoC';

import Decorator from '../decorators/Decorator';
import SlideModel from './../slides/Slide/SlideModel';
import SlideView from './../slides/Slide/Slide';
import LernFragen from '../LernFragen';
import Question from '../slides/Question/QuestionModel';



export interface SlideChangedListener {
	( model: SlideModel, view: SlideView ): void;
}
export interface SlideFactoryFunction {
	( slide: SlideModel, slideRenderer:SlideRenderer ): SlideView;
}


export class SlideRenderer extends View {

	public ioc:IoC<SlideFactoryFunction>;

	public currentModel:SlideModel = null;
	public currentView:SlideView = null;

	public slideChanged: Signal<SlideChangedListener | Decorator>;
	public userInput: Signal<SlideChangedListener | Decorator>;
	public userAnswered: Signal<SlideChangedListener | Decorator>;


	constructor() {

		super();

		this.defaultTemplate = '';

		this.ioc = new IoC<SlideFactoryFunction>();

		this.slideChanged = new Signal<SlideChangedListener | Decorator>();
		this.userInput = new Signal<SlideChangedListener | Decorator>();
		this.userAnswered = new Signal<SlideChangedListener | Decorator>();

	}


	public show( currentModel: SlideModel ) {

		// do nothing 
		if( currentModel == null ) return;

		// destroy current view
		this.clear();

		// store current model
		this.currentModel = currentModel;

		// get the corresponding view
		var view = this.ioc.get( this.currentModel.get( 'modelName' ) as string )( this.currentModel, this );
		this.currentView = view;

		// append the slide
		this.node.append( this.currentView.node );

		// dispatch signals
		this.slideChanged.dispatch( this.currentModel, this.currentView );

		if( this.currentModel instanceof Question ) {

			// check if already has user input
			if( this.currentModel.hasUserInput() ) this.userInput.dispatch( this.currentModel, this.currentView );

			// check if already marked as answered
			if( this.currentModel.isAnswered() ) this.userAnswered.dispatch( this.currentModel, this.currentView );
		}
	}


	public clear() {
		// check if there is something rendered
		if ( !this.currentView ) {
			return;
		}
		// remove the node
		this.node.removeChild( this.currentView.node );

	}

	/**
	 * Enhance the add methode of the class Signal to accept a Decorator a listener 
	 */
	protected decoratedAdd( signal: Signal<SlideChangedListener | Decorator> ) {
		return function ( listener: SlideChangedListener | Decorator, context: any = null ) {
			if ( listener instanceof Decorator ) {
				Signal.prototype.add.call( signal, listener.decorate, listener );
			} else {
				Signal.prototype.add.call( signal, listener, context );
			}
		}
	}

}
export default SlideRenderer;
