import LookupDict from '../lookup/LookupDict';
import LookupObject from '../lookup/LookupObject';
import Template from '../template/TemplateManager';
	
/**
	* A class that handles translation lookups
	*/
class Language {
	
	
	private lookup:LookupDict;
	
	constructor() {
		this.lookup = new LookupDict();
	}
	
	/**
		* translates the given key.
		*/
	translate( key:string, opts:{ data?:any, condition?:number } = {} ):any {
		if( key == undefined ) return '';
		var def = this.lookup.get( key, key );
		if( opts.condition != undefined ) def = this.matchCondition( def, opts.condition );
		if( opts.data ) def = Template.render( def, opts.data );
		return def;
	}

	matchCondition( value:string, num:number ) {

		var parts = value.split( '|' );

		// check conditions
		var found = this.parseConditions( parts, num )
		if( found ) return found.trim();

		if( parts.length == 1 ) return this.clearConditions( parts[0] );
		
		// check simple | split
		return this.clearConditions( parts[ ( num == 1 ) ? 0 : 1 ] );
	}
	
	parseConditions( parts, number:number ):string {
		// loop over parts
		for( var i = 0; i < parts.length; i++ ) {
			var regex = /^[\{\[]([^\[\]\{\}]*)[\}\]](.*)/;
			var match = regex.exec( parts[i] );

			// no proper condition
			if( match == null || match.length != 3 ) continue;

			var value = match[2];
			var condition = match[1];
			var num = number + ""; // turn into string for matching

			// range condition
			if( condition.indexOf( ',' ) > -1 ) {

				var [ from, to ] = condition.split( ',' );

				if ( to == '*' && num >= from ) {
					return value;
				} else if ( from == '*' && num <= to ) {
					return value;
				} else if ( num >= from && num <= to ) {
					return value;
				}

			// exact condition
			} else if( condition == num ) {
				return value;
			}
		}
	}

	clearConditions( s:string ):string {
		return s.replace( /^[\{\[]([^\[\]\{\}]*)[\}\]]/, '' ).trim();
	}

	add( object:Object ) {
		this.lookup.add( new LookupObject( object ) );
	}
}

/**
 * Define singleton for Language
 */
var singleton = new Language();

/**
 * Add translation into context
 */
Template.context.trans = singleton.translate.bind( singleton );

export default singleton;
