import MatrixChoiceModel from '../MatrixChoice/MatrixChoiceModel';

/**
 * An interface for Answers
 */
/* export interface MatrixBinaryAnswer {
    index: number;
    selected: boolean;
    solution: string;
} */

/**
 * An interface for questions
 */
/* export interface MatrixBinaryQuestion {
    text: string;
    index: number;
    answered: boolean;
    correct: boolean;
    answers: MatrixBinaryAnswer[];
    solution: string;
} */

/**
 * A model class for MatrixBinaryChoiceSlides
 */
export class MatrixBinaryChoiceModel extends MatrixChoiceModel {
    
    constructor( json ) {
        super( json );
    };
    
    //adds answers to the question model
    protected setAnswers() {
        this.questions.forEach( function( question ){
            if( question.answers === undefined ) {
                question.answers = [];
                question.answers.push( { selected: false, index: 1, solution: ( question.solution == 'true' ) ? 1 : 2 } ); //correct
                question.answers.push( { selected: false, index: 2, solution: ( question.solution == 'true' ) ? 1 : 2 } ); //wrong
            }
        });
    }

}

export default MatrixBinaryChoiceModel;