import Signal from '../signal/Signal';
import * as js from '../js';

/**
	* Function interface for listeners on the 'update' Signal. 
	*/
export interface AttributeChangeListener {
	( name:string, newValue:any, oldValue:any ):void;
}

/**
	* A common model class that has getters and setters 
	*/
class Model {
	
	protected _obj:Object;
	public change:Signal<AttributeChangeListener>;
	
	
	constructor( obj:Object = {} ) {
		this._obj = obj;
		this.change = new Signal<AttributeChangeListener>();
		this.generateAccessors();
	}
	
	/**
		* Returns the value of the model with the given key.
		* If the key does not exists the given optional fallback value is returned.
		* @param key The key on the model to lookup
		* @param fallback The fallback value when the key does not exists.
		*/
	get<T>( key:string, fallback:T = undefined ):T {
		var value = this._obj[ key ];
		return ( value !== undefined ) ? value : fallback;
	}


	/**
		* Sets the given value on the given key in the model.
		* @param key The key on the model to adjust its value
		* @param value The new value to set of the model
		*/
	set( key:string, value:any ) {
		
		var isNew = this._obj[ key ] == undefined;
		var old = this._obj[ key ];
		if( old != value ) {
			this._obj[ key ] = value;
			this.change.dispatch( key, value, old );
		}
		if( isNew ) this.generateAccessors();
	}
	
	/**
	 * Return a clone of this object
	 */
	public object():any{
		var obj = {};
		for( var attr in this._obj ){
			obj[attr] = this._obj[attr];
		}
		return obj;
	}
	
	/**
	 * Syncs the attributes of this model with the given object attributes
	 * It only syncs builtin types. other types have to sync manually.
	 */
	public sync( obj:any ) {
		for( var property in obj ) {
			if( js.isType( obj[property], [ "String", "Date", "Boolean", "Number" ] ) ) {
				this.set( property, obj[property] );
			}
		}
	}

	public generateAccessors() {

		var createProperty = ( name ) => {
			Object.defineProperty( this, name, {
				get() {
					return this.get( name );
				},
				set( value ) {
					this.set( name, value );	
				}
			});
		}

		for( var attr in this._obj ){
			if( Object.getOwnPropertyDescriptor( this, attr ) == undefined ) {
				createProperty( attr );
			}
		}
	}
}

export default Model;