import { AnswerListModel, Answer } from '../AnswerList/AnswerListModel';
import LernFragen from '../../LernFragen';

/**
 * The model of a MultipleChoice slide
 */
class MultipleChoiceModel extends AnswerListModel {

	/**
	 * Marks the given answer as selected. 
	 */
	public select( selectedAnswer: Answer ) {

		this.answers.forEach(( answer ) => {
			if ( answer === selectedAnswer ) { this.toggleSelection( answer ); }
		});
		this.fireUserInput();
	}

	/**
	 * Toggels the selected flag on an answer
	 */
	protected toggleSelection( answer: Answer ) {
		if ( answer.selected ) {
			answer.selected = false;
		} else {
			answer.selected = true;
		}
	}

}

export default MultipleChoiceModel;
