import Model from 'ln/model/Model';
import Node from 'ln/node/Node';
import { shuffle } from 'ln/js';

//import OrderModel from '../Order/OrderModel';
import { DragDropModel, Drag as DragDropDrag, Drop } from '../DragDrop/DragDropModel';

/**
 * A model class for Drag & Drop Sentence slides.
 * drag attribute is text: each newline represents a correct sentence
 * drags are delimited with a pipe (|)
 */

/**
 * An interface for drag items
 */
export interface Drag extends DragDropDrag {
	current_position: number;
}

export class DragDropSentenceModel extends DragDropModel {

	public drags:Drag[];
	public drops:Drop[];
	public solutions;

	/**
	 * The constructor
	 */
	constructor( json ) {

		super( json );
		this.solutions = [];
		this.adjustDragDrops( json );
	}

	//overwrite parent, do nothing
	public complete() {
	}

	protected adjustDragDrops( json ) {

		var drags = [];
		var drops = [ {} ];
		var temp_all = []; //unique drags over all sentences
		
		json.drags.split("\n").forEach(function( drag, dragIndex) {
			if (drag.length > 0) {

				this.solutions[dragIndex] = "";
				var temp_sentence = []; //drags of sentence (not unique)

				//get unique drags
				drag.split('|').forEach (function (item, index) {
					
					item = item.trim();
					this.solutions[dragIndex] += item;

					if( temp_all.indexOf(item) == -1 || temp_sentence.indexOf(item) >= 0) {
						temp_all.push(item);
						temp_sentence.push(item);
						drags.push({'text': item, 'target': 1, 'dropped': 0, 'current_position': -1});
					 }
				}, this);

			}
		}, this);

		if (drags.length === 0) drags.push({'text': ''});

		drags = shuffle( drags );
		this.set( 'drops', drops );
		this.set( 'drags', drags );
	}

	/**
	 * Returns true if the given drag is at the correct position
	 * @param label Gap label
	 */
	public hasCorrectInput( drag:Drag ):boolean {
		 // check all items, because its useless to check a single one if there are multiple solutions possible
		 return this.isCorrect();
		 //return drag.current_position == drag.order;
	}

	/**
	 * Returns true if all drags are at the correct position
	 */
	public isCorrect():boolean {
		this.drags.sort(function(a,b) {return (a.current_position > b.current_position) ? 1 : ((b.current_position >a.current_position) ? -1 : 0);} );
		var solution = "";
		for (var i = 0; i < this.drags.length; i++) {
			if (this.drags[i].current_position >= 0) {
				solution += this.drags[i].text.trim();
			}
		}

		return this.solutions.indexOf(solution) >= 0;
	}
}

export default DragDropSentenceModel;