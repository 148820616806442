import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Node from 'ln/node/Node';
import { ListRenderer } from 'ln/list/ListRenderer';
import setup from 'ln/setup/setup';
import { mapper } from 'lb/LernBuch';
import Element from 'lb/elements/Element';
import ElementModel from 'lb/models/ElementModel';
import ModifiableList from 'ln/list/ModifiableList';
import Filterbutton from './Filterbutton';
import TermFilter from 'lnui/filter/TermFilter';

import MaterialcardText from './MaterialcardText';
import MaterialcardImage from './MaterialcardImage';
import MaterialcardYT from './MaterialcardYT';

class Materiallist extends View {
    public data;

    public filterThemen = [];
    public filterDimensionen = [];
    public filterCompetencies = [];
    public filterGoals = [];
    private listRenderer

    constructor( model:any) {
        super( { 'template': (model.materials.length == 1)?'lb.materiallist-embed':'lb.materiallist' } );
        this.setData( model );
    }

    init( ) {

        var listRendererMaterials = new ListRenderer( this.node.js( 'materials' ));

        listRendererMaterials.ioc.add( 'default', function( model:ElementModel ) {
            return new Element( model ).render() as Element;
        });

        listRendererMaterials.ioc.add( 'App\\MaterialImage', function ( model:ElementModel ) {
            return new MaterialcardImage( model ).render() as Element;
        });

        listRendererMaterials.ioc.add( 'App\\MaterialYT', function( model:ElementModel ) {
            if( this.data.data.materials.length == 1 ) model.set('template','lb.materialyt-embed');
            
            return new MaterialcardYT( model ).render() as Element;
        }.bind( this ));

        listRendererMaterials.source.fill( this.data.data.materials );

    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
        return this;
    } 
}

export default Materiallist;
