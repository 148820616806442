// import lib
import Signal from 'ln/signal/Signal';
// import project
import Slide from '../Slide/Slide';
import QuestionModel from '../Question/QuestionModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


class Question extends Slide {

	public model: QuestionModel;


	constructor( model: QuestionModel, slideRenderer:SlideRenderer ) {
		super( model, slideRenderer );
	}

	protected init() {

		// fire events on the model
		if ( this.model.hasUserInput() ) {
			this.model.fireUserInput();
		}

		if ( this.model.isAnswered() ) {
			this.model.fireUserAnswered();
		}

		this.model.userInput.add( this.onUserInput, this );
		this.model.userAnswered.add( this.onAnswered, this );

	}

	/**
	 * Handle the user input event
	 */
	private onUserInput() {
		this.slideRenderer.userInput.dispatch( this.model, this );
	}

	/**
	 * Handle the answered event
	 */
	private onAnswered() {
		this.slideRenderer.userAnswered.dispatch( this.model, this );
	}


}

export default Question;