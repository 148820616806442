
export interface ITreeNode {
	parent: TreeNode;
	readonly children: TreeNode[];
}

class TreeNode implements ITreeNode {
	
	public parent: TreeNode;
	public children: TreeNode[];

	/**
	 * Assign this to all children as parent
	 */
	public assignParent() {
		if ( !this.children ) return;

		this.children.forEach( function ( child ) {
			child.parent = this;
		}, this );
	
	}

	/**
	 * Get the root node of a given node
	 */
	public root(): TreeNode {
		return ( this.parent == undefined ) ? this : this.parent.root();
	}

	/**
	 * Get all nodes and leafs of one level
	 */
	public level( level: number ): TreeNode[] {
		return this._level( level, this, 0 );
	}

	protected _level( level: number, currentTreeNode: TreeNode, currentDepth: number ): TreeNode[] {
		var result: TreeNode[] = [];

		if ( level == currentDepth ) {
			result.push( currentTreeNode );
		} else if ( level > currentDepth && Array.isArray( currentTreeNode.children ) ) {
			currentTreeNode.children.forEach(( child ) => {
				result = result.concat( this._level( level, child, currentDepth + 1 ) );
			});
		}
		return result;
	}

}

export default TreeNode;
