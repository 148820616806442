// import lib
import Signal from 'ln/signal/Signal';
import LinkedList from 'ln/linkedlist/LinkedList';
import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import Template from 'ln/template/TemplateManager';
import Node from 'ln/node/Node';

// import project
import SlideView from '../Slide/Slide';
import RevealModel from '../Reveal/RevealModel';
import { Button } from '../Reveal/RevealModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';



class Reveal extends SlideView {

	public model: RevealModel;
	/**
	 * A list renderer to render the buttons
	 */
	public buttonListRenderer: ListRenderer<Button>;

	/**
	 * The default button template
	 * @default lf.reveal-button
	 */
	public revealButtonTemplate: string;

	/**
	 * The default reveal content template
	 * @default lf.reveal-content
	 */
	public revealContentTemplate: string;


	constructor( model: RevealModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		this.defaultTemplate = this.model.get('template', 'lf.reveal-slide');
		this.revealButtonTemplate = this.model.get('button', 'lf.reveal-button');
		this.revealContentTemplate = this.model.get('content', 'lf.reveal-content');
	}

	protected init() {
		super.init();
		this.renderButtons();
	}

	/**
	 * Render all the reveal buttons
	 */
	private renderButtons() {
		this.buttonListRenderer = new ListRenderer<Button>( this.node.js( 'buttons' ) );
		this.buttonListRenderer.defaultRender(( button: Button ) => {
			var node = Node.fromHTML( Template.render( this.revealButtonTemplate, button ) );
			node.click.add( this.onClick, this );
			return { node: node };
		});
		this.buttonListRenderer.source = new List<Button>( this.model.buttons );

		if ( this.model.get( 'autoSelectFirst' ) ) {
			this.revealContent( this.buttonListRenderer.links.first.prototype );
		}
	}

	/**
	 * Reveal button clicked
	 */
	private onClick( node: Node ) {
		var link = this.buttonListRenderer.linkOf( node );
		this.revealContent( link.item );

	}

	private revealContent( button: Button ) {
		var content = Node.fromHTML( Template.render( this.revealContentTemplate, button ) );
		var target = this.node.js( 'target' );
		target.empty();
		target.append( content );
	}

}

export default Reveal;