// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

// import project
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import SlideRenderer from '../sliderenderer/SlideRenderer';
import CorrectWrong from '../aggregators/CorrectWrong';

/**
 * This class can render status infos like correctSlide, totalSlide, correctSlides, wrongSlides, percentages into a template.
 */
class Status extends View {

	private slideRenderer: SlideRenderer;
	private data:{ currentSlide:number,	totalSlides: number, percentage: number, correctSlides: number,	wrongSlides: number };

	constructor( slideRenderer: SlideRenderer ) {

		super();
		this.defaultTemplate = 'lf.status';
		this.data = { currentSlide:0, totalSlides:0, percentage:0, correctSlides:0, wrongSlides:0 };

		this.slideRenderer = slideRenderer;
		this.slideRenderer.slideChanged.add( this.update.bind( this ) );
		this.slideRenderer.userAnswered.add( this.update.bind( this ) );

	}


	/**
	 */
	private update() {

		var slides = this.slideRenderer.currentModel.toArray();

		var aggregator = new CorrectWrong();
		aggregator.aggregate( slides as SlideModel[] );

		this.data = {
			currentSlide: slides.indexOf( this.slideRenderer.currentModel ) + 1,
			totalSlides: slides.length,
			percentage: aggregator.percentage,
			correctSlides: aggregator.correctSlides,
			wrongSlides: aggregator.wrongSlides,
		}

		// rerender
		this.node.html = "";
		this.render( this.node );
	}

	renderData() {
		return this.data;
	}

}

export default Status;