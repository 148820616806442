import Node from '../node/Node';
import { scanner } from './Scanner';
import Template from '../template/TemplateManager';

/**
 * A common view class that has a container to render content/template into.
 */
class View {

	public data:any
	public node:Node;

	// keep this for backward compatibility
	public defaultTemplate:string = "";


	constructor( data:any = {} ) {
		this.data = data;
	}
	
	
	/**
	 * Renders either the given template as a node.
	 * Or simply sets the given node.
	 */
	public render( target?:Node ):View {

		// is there a target node given
		if( target instanceof Node ) {
			this.node = target;

			// check if empty node - render template
			if( target.html == "" ) {
				var temp = this.renderTemplate();
				this.node.html = temp.html; // set only inner html

				// set css if there is no style defined on target
				if( !this.node.getAttribute( 'class' ) ) this.node.setAttribute( 'class', temp.getAttribute( 'class' ) );
			}

		} else {
			this.node = this.renderTemplate();
		}

		this.init();

		return this;
	}

	/**
	 * The function is called when rendering is done and the node is available.
	 * Usefull for subclasses to initialize after the node is ready. 
	 */
	protected init() {
		// do in subclass.
	}

	/**
	 * This function returns the data that should be rendered into the template
	 */
	protected renderData():any {
		return this.data;
	}

	protected renderTemplate():Node {

		// for backward compatibility
		if( !this.data.template ) this.data.template = this.defaultTemplate;

		return Node.fromHTML( Template.render( this.data.template, this.renderData() ) );
	}

}

export default View;