import Model from 'ln/model/Model';
import { shuffle } from 'ln/js';


import { DragDropModel, Drag, Drop } from '../DragDrop/DragDropModel';
import ClozeModel from '../Cloze/ClozeModel';

export interface ClozeDrop extends Drop{
	correct:string[];
}

/**
 * A model class for DragDropCloze slides.
 * Lücken werden im Dateneditor mit {korrekt1;korrekt2} definiert
 * Distractors (Lücken, die nie verwendet werden können) befinden sich in einem eigenen Inputfeld
 */
class DragDropClozeModel extends ClozeModel {

	public drags:Drag[];
	public drops:ClozeDrop[];
	public touched:boolean;
	public distractors:string[];
	public additionalDropSpace: number;
	/**
	 * The constructor
	 */

	constructor( obj: Object = { distractors:[] } ) {
 
		super( obj );
		
		//transform gaps to drags and drops
		this.drops = [];
		this.drags = [];
		this.distractors = this.get('distractors') ? this.get('distractors') as string[]: [];
		this.parseDragDrops();
	}

	private  parseDragDrops() {

		var i = 1;

		Object.keys( this.gaps ).forEach(function(key) {
			this.gaps[ key ].answers.forEach (function ( answer, index ) {
				this.drags.push( { 'target': i, 'text': this.gaps[key].answers[ index ], 'dropped':0 } );
			}, this);

			this.drops.push( { 'correct': this.gaps[key].answers, 'target': i } ); 
			i++;
		}, this);

		this.distractors.forEach( ( distractor ) => {
			this.drags.push( { 'target': 0, 'text': distractor, 'dropped': 0 } );
		} );

		this.drags = shuffle(this.drags);
	}

	public isCorrect():boolean {
		var totalDropped = 0;
		for( var i = 0; i < this.drags.length; i++ ) {
			if (this.drags[i].dropped <= 0) continue;
			//check content instead of position
			if( this.drops[this.drags[i].dropped-1].correct.indexOf(this.drags[i].text) < 0)  { return false };
			totalDropped++;
		}
		if (totalDropped < this.drops.length) { return false };

		return true;
	};


	public hasUserInput(): boolean {
		var drags = this.drags.filter(function(drag) {
			return drag.dropped > 0;
		});
		return drags.length > 0;
	}
	
	//overwrite parent, do nothing
	public complete() {
	}

}

export default DragDropClozeModel;