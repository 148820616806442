
/**
 * An interface for any source for the LookupDict
 */
export interface LookupSource {
	lookup( key:string ):any;
}


/**
	* This class provides a key:value lookup mechanism where multiple sources can be added.
	*/
class LookupDict {
	
	private sources:LookupSource[] = [];
	
	/**
		* Adds a new source to the dictionary to search for keys
		*/
	add( source:LookupSource ) {
		this.sources.push( source );
	}
	
	
	/**
		* Returns the value stored behind the given key in the first of all sources
		*/
	get( key:string, fallback?:string ):any {
		
		var result, i, s = this.sources;

		// loop over sources to find key.
		for( i = 0; i < s.length; i++ ) {
			result = s[ i ].lookup( key );
			if( result != null ) return result;
		}
		
		// if fallback is provided show that
		if( fallback !== undefined ) return fallback;
		
		// not found
		return '!{' + key + '}'; 
	}
}



export default LookupDict;