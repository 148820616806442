import AnswerListModel from '../AnswerList/AnswerListModel';
import { Answer } from '../AnswerList/AnswerListModel';
import LernFragen from '../../LernFragen';

/**
 * The model of a SingleChoice slide
 */
class SingleChoiceModel extends AnswerListModel {

	/**
	 * Marks the given answer as selected. 
	 * This overwrites the default behaviour of AnswerList
	 */
	public select( selectedAnswer: Answer ) {

		var answers = this.answers;
		answers.forEach(( answer ) => {
			answer.selected = false;
		});

		selectedAnswer.selected = true;
		this.fireUserInput();
	}
}

export default SingleChoiceModel;
