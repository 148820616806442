import View from 'ln/view/View';
import setup from 'ln/setup/setup';
import Signal from 'ln/signal/Signal';
import Request from 'ln/request/Request';


interface StateChangedCallback{
	( item:Taskitem )
}

class Taskitem extends View {
    public data;
	public clicked:Signal<StateChangedCallback>;

    constructor( ) {
        super( { 'template': 'lb.task-listitem' } );
        this.clicked = new Signal<StateChangedCallback>();
    }

    init() {

        if( this.data.data.checked ) {
            this.checked = true;
            this.clicked.dispatch( this );
        }
        
        this.node.js( 'task-done' ).click.add( function( node ) {
            this.data.data.checked = this.checked;
            this.clicked.dispatch( this );
            this.sendUserData();
        }, this );
    
    }

    renderData() {
        return this.data.data;
    }

    setData( data:any) {
        this.data.data = data;
    }

    set checked( c ) {
        this.node.js( 'task-done' ).checked = c;
    }

    get checked() {
        return this.node.js( 'task-done' ).checked;
    }

    sendUserData() {

        if( !setup.data( 'savestate' ).id ) return;

        var data = {
            'savedModelName': this.data.data.modelName,
            'savedModelId': this.data.data.id,
            'attribute': 'checked',
            'value': this.checked,
        };

        Request.post( setup.route( 'userData' ).url() )
            .headers( { 'Content-Type':'application/json' } )
            .send( JSON.stringify( data ));
    }
}

export default Taskitem;