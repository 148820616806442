// import lib
import Signal from 'ln/signal/Signal';
import LinkedList from 'ln/linkedlist/LinkedList';
import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import Template from 'ln/template/TemplateManager';
import Node from 'ln/node/Node';

// import project
import SlideView from '../Slide/Slide';
import { TooltipData, Tooltip as TooltipModel } from '../Tooltip/TooltipModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';


/**
 * A class that represents a tooltip slide
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *      "modelName": "Tooltip",
 *      "title": "Tooltip Demo",
 *      "description": "Show tooltips on the image ( Trumpet on the left, Trombon in the middle )",
 *      "image": "./img/tooltip.jpg",
 *      "tooltips": [
 *          { "height": "0.280522088353", "left": "0.201847703313", "top": "0.414180471888", "width": "0.11234939759",
 *          "text": "Das ist eine <img src='./img/trumpet.jpg' />" },
 *          { "height": "0.650522088353", "left": "0.491847703313", "top": "0.234180471888", "width": "0.09234939759",
 *          "text": "Das ist eine <b>Posaune<b>" }
 *      ],
 *       "tooltipTemplate": "lf.tooltip_tooltip_template"
 *  },
 * 
 */
class Tooltip extends SlideView {

	public model:TooltipModel;
	/**
	 * A list renderer to render the tooltips
	 */
	public areaListRenderer:ListRenderer<TooltipData>;

	/**
	 * The default tooltip template
	 * @default lf.tooltip-tooltip
	 */
	public tooltipTemplate:string;

	/**
	 * The default tooltip area template
	 * @default lf.tooltip-tooltip
	 */
	public tooltipAreaTemplate:string;


	constructor( model:TooltipModel, slideRenderer:SlideRenderer ){
		super( model, slideRenderer );

		this.defaultTemplate = this.model.get('template', 'lf.tooltip-slide');
		this.tooltipTemplate = this.model.get('tooltip-template', 'lf.tooltip-tooltip');
		this.tooltipAreaTemplate = this.model.get('area-template', 'lf.tooltip-area');
	}

	protected init() {
		super.init();
		this.renderTooltipAreas();
	}

	/**
	 * Render all the areas of the tooltip
	 */
	private renderTooltipAreas() {
		this.areaListRenderer = new ListRenderer<TooltipData>( this.node.js( 'areas' ) );
		this.areaListRenderer.defaultRender( ( tooltip:TooltipData ) => {
			var node = Node.fromHTML( Template.render( this.tooltipAreaTemplate, tooltip ) );
			node.mouseenter.add( this.onMouseEnter, this );
			node.mouseleave.add( this.onMouseLeave, this );
			node.mousemove.add( this.onMouseMove, this );
			return { node: node };
		});
		this.areaListRenderer.source = new List<TooltipData>( this.model.tooltips );
	} 

	/**
	 * Mouse enter a tooltip area
	 */
	private onMouseEnter( node:Node ) {
		var link = this.areaListRenderer.linkOf( node );
		var toolipNode = Node.fromHTML( Template.render( this.tooltipTemplate, link.item ) );
		node.append( toolipNode );
	}   

	/**
	 * Mouse leaves a tooltip area
	 */
	private onMouseLeave( node:Node ) {
		node.empty();        
	}

	/**
	 * Mouse is moving over the tooltop area
	 */
	private onMouseMove( node:Node, event:MouseEvent ) {
		if( node.hasChildren() ) {
			var tooltipNode = node.js( 'tooltip-container' );
			tooltipNode.native.style.left = ( event.clientX + 15 ) + 'px' ;
			tooltipNode.native.style.top = event.clientY + 'px' ;
		}
	}

}

export default Tooltip;