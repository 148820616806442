// improt lib
import Signal from 'ln/signal/Signal';
import View from 'ln/view/View';
import { EmptyCallback } from 'ln/signal/Signal';

export interface ButtonClickedCallback {
    ( value:any, toggleState:boolean );
}
/**
 * A base class for elements that can be rendered in a chapter
 */
class Filterbutton extends View {

    public viewData;
    public buttonClicked = new Signal<ButtonClickedCallback>();
    private togglable:boolean;
    private toggleState:boolean = false;

	/**
	 * Sets the configuration for an element
	 * @param model ElementModel
	 */
	constructor( data:any, togglable?:boolean, template?:string ) {
        super();
        this.viewData = data;
        this.togglable = ( togglable ) ? togglable : false;
		this.defaultTemplate = ( template ) ? template : 'lb.filter-button';
	}
	
	/**
	 * Execute things after element was rendered
	 * For example register to events on the element
	 */
	public init() {
        this.node.click.add(function() {
            if( this.togglable ) {
                if( this.toggleState ) {
                    this.node.removeClass( '-active-mini' )
                    this.toggleState = false;
                } else {
                    this.node.addClass( '-active-mini' )
                    this.toggleState = true;
                }

            }
            this.buttonClicked.dispatch( this.viewData, this.toggleState )
        }, this)
	}

	/**
	 * Define the data to render on the element
	 */
	protected renderData(){
		return this.viewData;
	}
}

export default Filterbutton;