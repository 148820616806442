import { LookupSource } from './LookupDict';
import { isType } from '../js';

/**
	* A lookup dictionary source that searches on an object for given keys.
	* It allows to search in nested objects too.
	*/
class LookupObject implements LookupSource {
	
	private obj:Object;

	constructor( obj:Object ) {
		this.obj = ( isType( obj, 'Object' ) ) ? obj : {};
	}
	
	/**
		* lookups in the object for the given key.
		*/
	lookup( key:string ):any {
		var keys = key.split( '.' );
		return this.search( this.obj, keys );
	}
	
	/**
		* Adds a prefix to the search keys on this object
		*/
	prefix( name:string ):void {
		var temp = {};
		temp[ name ] = this.obj;
		this.obj = temp;
	}
	
	/**
		* Searches in the given object for the first key in the keys array.
		* If there are sub keys it digs deeper into object hierarchy.
		*/
	private search = function( current:Object, keys:string[] ) {
		
		var top = keys.shift();
		
		// there are sub keys to search
		if( keys.length > 0 ) {
			return ( current[ top ] != undefined ) ? this.search( current[ top ], keys ) : undefined;
		} else {
			return current[ top ];
		}
	};
}

export default LookupObject;