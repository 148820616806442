// import library
import Signal from 'ln/signal/Signal';
import { EmptyCallback } from 'ln/signal/Signal';

// import project
import SlideModel from '../Slide/SlideModel';

export class QuestionModel extends SlideModel {

	public answered: boolean = false;
	public userInput: Signal<EmptyCallback>;
	public userAnswered: Signal<EmptyCallback>;

	/**
	 * Create a new question model instance
	 * @param obj The object to create the model from
	 */
	constructor( obj: Object = {}) {
		super( obj );

		this.userInput = new Signal<EmptyCallback>();
		this.userAnswered = new Signal<EmptyCallback>();
	}

	/**
	 * Mark this question as answered
	 */
	public markAsAnswered() {
		this.answered = true;
		this.userAnswered.dispatch( this );
	}

	/**
	 * Return if this question is answered
	 */
	public isAnswered(): boolean {
		return this.answered;
	}

	/**
	 * Return if this question has a user input
	 * This is an abstract methode, because every model
	 * can have different type of user inputs
	 */
	public hasUserInput(): boolean {
		return false;
	}

	/**
	 * Returns true if the whole question is correctly solved
	 * Abstract method - needs to be implemented in the subclasses
	 */
	public isCorrect(): boolean {
		return true;
	}

	/**
	 * Fire the event, that the question was answered
	 */
	public fireUserAnswered() {
		this.userAnswered.dispatch( this );
	}

	/**
	 * Fire the event, that the question has a new user input
	 */
	public fireUserInput() {
		this.userInput.dispatch( this );

		// if the question is answered but there is a user input,
		// fire answered again
		if ( this.answered ) this.fireUserAnswered();
	}

	

}

export default QuestionModel;