import "es6-promise/auto";
import Signal from '../signal/Signal';


interface ProgressSignal {
	( loaded:number, total:number )
}

/**
 * HttpRequest Class
 */

export class HttpRequest {
	
	protected _method:string = 'GET';
	protected _url:string;
	protected _headers:{} = null;
	protected request:XMLHttpRequest;

	public progress:Signal<ProgressSignal> = new Signal<ProgressSignal>();
	
	
	/**
	 * Create a new instance with the given url
	 * @param url
	 */
	constructor( url:string ) {
		this._url = url;

		this.request = new XMLHttpRequest();
		this.request.onprogress = ( event ) => {
			this.progress.dispatch( event.loaded, event.total );
		}
	}
	
	/**
	 * Set the method for the request
	 * @param method
	 */
	public method( method:string ):HttpRequest {
		this._method = method;
		return this;
	}
	
	/**
	 * Set the headers for the request
	 * @param headers
	 */
	public headers( headers:{} ):HttpRequest {
		this._headers = headers;
		return this
	}
	
	/**
	 * Send/Load the request without sending data
	 */
	public load():Promise<string|{}> {
		return this.sendRequest().then( this.parseJson );
	}
	
	/**
	 * Send the request with the given data
	 * @param data
	 */
	public send( data:any ):Promise<string|{}> {
		return this.sendRequest( data ).then( this.parseJson );
	}

	public url():string {
		return this._url;
	}
	
	/**
	 * Open and send the request with the given parameters
	 * and data
	 * @param data
	 */
	private sendRequest( data:any = null ):Promise<string> {
		
		var request = this.request;
		var promise = this.promise( request );
		
		request.open( this._method, this._url );
		
		for( var key in this._headers ){
			request.setRequestHeader( key, this._headers[key] );
		}
		
		request.send( data );
		
		return promise;
	}
	
	/**
	 * Create a promise with the request
	 * The promise resolves in case of a successful request - jejects otherwise
	 */
	private promise( request:XMLHttpRequest ) {
		return new Promise<string>( ( resolve, reject ) => {
			
			var errorHandler = function() {
				Request.error.dispatch( request.statusText );
				reject( Error( request.statusText ) );
			}
			
			request.onload = function() {
				if( request.status >= 200 && request.status < 300 ) {
					resolve( request.responseText );
				} else {
					errorHandler();
				}
			};
			
			request.onerror = errorHandler;
		});
	}

	/**
	 * tries to parse response otherwise returns original string data
	 */
	private parseJson( data:string ):string|{} {
		try {
			data = JSON.parse( data )
			return data;
		} catch( e ) {
			return data;
		}
	}
}

interface ErrorSignal {
	( error:string )
}

/**
 * Static Request Class
 */
export class Request {
	
	public static error:Signal<ErrorSignal> = new Signal<ErrorSignal>();
	/**
	 * Creates a new post HttpRequest
	 */
	public static post( url:string ):HttpRequest{
		return new HttpRequest( url ).method( 'POST' );
	}
	
	/**
	 * Creates a new get HttpRequest
	 */
	public static get( url:string ):HttpRequest{
		return new HttpRequest( url ).method( 'GET' );
	}
	
	/**
	 * Creates a new put HttpRequest
	 */
	public static put( url:string ):HttpRequest{
		return new HttpRequest( url ).method( 'PUT' );
	}
	
	/**
	 * Creates a new delete HttpRequest
	 */
	public static delete( url:string ):HttpRequest{
		return new HttpRequest( url ).method( 'DELETE' );
	}
}


export default Request;