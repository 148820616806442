// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

// import project
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import SlideRenderer from '../sliderenderer/SlideRenderer';
import LernFragen from '../LernFragen';

import CorrectWrongAggregator from '../aggregators/CorrectWrong';
import Question from '../slides/Question/QuestionModel';

export interface PointsNavigationConfig {
	disableNavigation?: boolean;
	evaluateAnswers?: boolean;
}

class PointsNavigation extends View {

	private slideRenderer: SlideRenderer;
	private disableNavigation:boolean;
	private evaluateAnswers:boolean;


	constructor( slideRenderer: SlideRenderer, config: PointsNavigationConfig = {}) {
		super();
		this.defaultTemplate = 'lf.points-navigation';
		this.slideRenderer = slideRenderer;

		/**
		 * A flag to disable the navigation on the points
		 */
		this.disableNavigation = ( typeof config.disableNavigation != 'undefined' ) ? config.disableNavigation : false;

		/**
		 * A flag to enable the evaluation of answers
		 */
		this.evaluateAnswers = ( typeof config.evaluateAnswers != 'undefined' ) ? config.evaluateAnswers : false;
	}

	/**
	 * Render the template for the navigation
	 */
	public init() {

		// render the points
		this.renderPoints();

		// register signals on slide renderer
		this.slideRenderer.slideChanged.add( this.update, this );
		this.slideRenderer.userInput.add( this.update, this );
	}

	/**
	 * Render the navigation points an register click handler
	 */
	private renderPoints() {

		// remove all points
		this.node.empty();

		var current = this.slideRenderer.currentModel;

		if( !current ) return;

		// all slides
		var slides = current.toArray();

		// render all slide points
		slides.forEach( function( slide, index ) {

			var point = Node.fromHTML( Template.render( 'lf.points-navigation-point', { slide: slide, index:index } ) );
			point.toggleClass( '-active', slide === current  );


			// handle question slides
			if( slide instanceof Question && this.evaluateAnswers ) {

				point.addClass( '-question' );
				point.toggleClass( '-answered', slide.answered );
				point.toggleClass( '-correct', slide.isCorrect() );
				point.toggleClass( '-wrong', !slide.isCorrect() );
			}


			// handle click registration
			if( this.disableNavigation === false ) {
				point.click.add( function( ) {
					this.slideRenderer.show( slide );
				}.bind( this ));
			}

			this.node.append( point );

		}.bind( this ));
	}


	private update( currentModel: SlideModel, currentView: SlideView ) {
		// render or rerender the points navigation
		this.renderPoints();
	}
}

export default PointsNavigation;