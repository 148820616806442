import Signal from '../signal/Signal';

/**
 * Function interface for listeners on the 'add' Signal. 
 */
export interface ListAddedListener<T> {
	( newItem:T, newIndex?:number ):void;
}
/**
 * Function interface for listeners on the 'remove' Signal. 
 */
export interface ListRemovedListener<T> {
	( removedItem:T, index:number ):void;
}
/**
 * Function interface for listeners on the 'filled' Signal. 
 */
export interface ListFilledListener<T> {
	( items:T[] ):void;
}


/**
 * A generic representation of a list that throws event on add, remove and fill
 * 
 */
export class List<T> {

	public added:Signal<ListAddedListener<T>>;
	public removed:Signal<ListRemovedListener<T>>;
	public filled:Signal<ListFilledListener<T>>;
	public sorted:Signal<ListFilledListener<T>>;

	protected items:T[];
	
	constructor( items?:T[] ) {
		this.added = new Signal<ListAddedListener<T>>();
		this.removed = new Signal<ListRemovedListener<T>>();
		this.filled = new Signal<ListFilledListener<T>>();
		this.sorted = new Signal<ListFilledListener<T>>();
		
		this.items = ( items ) ? items : [];
	}
	
	/**
	 * Returns a copy of the internal array. 
	 */
	all():T[] {
		return this.items.slice();
	}
	
	/**
	 * Refill the list with items
	 * @param items to fill the list with 
	 */
	fill( items:T[], silent:boolean = false ) {
		this.items = items; // do not add the original array.
		if( !silent ) this.filled.dispatch( items );
	}

	
	/**
	 * Adds an item to the list at the given index. If the index is outside of the array the item is appended at the end
	 * @param item The item to be added
	 * @param index The index at which the item is added.
	 */
	add( item:T, index:number ):number {
		
		// make sure index is in array length
		index = Math.max( Math.min( index, this.items.length ), 0 );

		this.items.splice( index, 0,  item );
		this.added.dispatch( item, index );
		return index;
	}

	/**
	 * Removes the item at the given index
	 * @param index The index at which the item will be removed
	 */ 
	remove( item:T ):boolean {
		
		var index = this.index( item );

		if( index >= 0 ) return this.removeAt( index );

		return false;
	}

	/**
	 * Removes an item at the given index
	 */
	removeAt( index:number ):boolean {

		var res = this.items.splice( index, 1 );
		var item = res[0];

		if( item ) {
			this.removed.dispatch( item, index );
			return true;
		}

		return false;
	}

	/**
	 * Appends a new item to the list
	 */
	append( item:T ) {
		this.add( item, this.items.length );
	}
	
	/**
	 * Prepends a new item to the list
	 */
	prepend( item:T ) {
		this.add( item, 0 );
	}
	
	/**
	 * Get the item with the given index
	 * @param index The index of the item
	 */
	get( index:number ):T {
		return this.items[ index ];
	}
	
	/**
	 * Returns the first item in the list
	 */
	first():T {
		return this.items[0];
	}
	
	/**
	 * Returns the last item in the list
	 */
	last():T {
		return this.items[ this.items.length - 1 ];
	}

	/**
	 * Shortcut to empty the list
	 */
	empty() {
		this.fill( [] );
	}

	/**
	 * Finds an element in the list with the given closure
	 */
	find( closure:( item:T, index?:number, array?:Array<T> ) => boolean ) {
		return this.all().filter( closure )[ 0 ];
    }
  

	
	/**
	 * Returns the index of the given item
	 */
	index( searchItem:T ):number {
		var index = -1;
		this.items.forEach( ( item, i ) => {
			if( item === searchItem ) index = i;
		});
		return index;
	}

	/**
	 * Tests if the given item exists in the list
	 */
	contains( item:T ):boolean {
		return this.index( item ) >= 0;
	}

	/**
	 * Returns the length of the list
	 */
	get length():number {
		return this.items.length;
	}

	/**
	 * Resorts the items based on the given array of items
	 */
	sort( items:T[] ) {

		// check if items size match
		// no check for same instances...
		if( this.items.length != items.length ) throw "Sort items should be the same size";
		
		this.items = items;
		this.sorted.dispatch( items );
	}

	/**
	 * Sorts the list by the closures return value
	 */
	sortBy( closure:( item:T )=>any ) {
		var items = this.items.map( function( x:T ) {
			return [ x, closure( x ) ];
		}).sort(function (a, b) {
			return a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
		}).map(function (x) {
			return x[0];
		});
		this.sort( items );
	}
}

export default List;