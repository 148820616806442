import Node from 'ln/node/Node';
import Decorator from './Decorator';
import { DecoratorFunction } from './Decorator';
import IoC from 'ln/ioc/IoC';
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import Hotspot from '../slides/HotSpot/Hotspot';
import DragDrop from '../slides/DragDrop/DragDrop';




/**
 * Composite decorator, allows to register decorators for specific models
 */
class ModelBasedDecorator extends Decorator{

	private ioc:IoC<(Decorator|DecoratorFunction)[]> = new IoC<(Decorator|DecoratorFunction)[]>();

    constructor(){
		super();
    }

	public default( decorators:(Decorator|DecoratorFunction)[] ) {
		this.ioc.add( 'default', decorators );

	} 

	public set( key:string, decorators:(Decorator|DecoratorFunction)[] ) {
		this.ioc.add( key, decorators );
	}

	public decorate( slideModel:SlideModel, slideView:SlideView ) {
		var key = slideView.decoratorKey;
		this.ioc.get( slideView.decoratorKey ).forEach( ( decoratorFunctionOrDecorator ) =>  {
			if( decoratorFunctionOrDecorator instanceof Decorator ) {
				decoratorFunctionOrDecorator.decorate( slideModel, slideView );
			}else {
				decoratorFunctionOrDecorator.call( this, slideModel, slideView );
			}
		});
	}

}

export default ModelBasedDecorator;


