import LookupDict from '../lookup/LookupDict';
import LookupObject from '../lookup/LookupObject';
import TemplateRenderer from './TemplateRenderer';
import Lang from '../lang/Lang';
import Node from '../node/Node';

/**
	* This class manages template from different sources.
	* It will look up for templates in the stored sources and renders them with a TemplateRenderer.
	* For performance the manager keeps cached versions of looked up templates.
	*/
class TemplateManager {
	
	private cache:any = {};
	private lookup:LookupDict;
	public renderer:TemplateRenderer;	

	constructor() {
		this.renderer = new TemplateRenderer();
		this.lookup = new LookupDict();
	}
	
	/**
	* Renders the template with the given key with the given data into a string. 
	*/
	public render( keyOrTemplate:string, data:any = {} ):string {
		
		if( this.cache[ keyOrTemplate ] == undefined ) {
			this.cache[ keyOrTemplate ] = this.renderer.compile( this.lookup.get( keyOrTemplate, keyOrTemplate ) );
		}
		
		return this.cache[ keyOrTemplate ]( data );
	}


	/**
	 * Renders the template into a document fragment
	 */
	public document( key:string, data:any = {} ):DocumentFragment {
		return Node.fragment( this.render( key, data ) );
	}
	
	public get context():any {
		return this.renderer.context;
	}

	/**
	 * Adds a template lookup object
	 * @param obj 
	 */
	public add( obj:Object ) {
		this.lookup.add( new LookupObject( obj ) );
	}
}

// create singleton to export.
var singleton = new TemplateManager();

/**
 * Add import function to TemplateRenderer context
 */
singleton.renderer.context["import"] = function( key:string, data?:any ) {
	return singleton.render( key, data );
}


export default singleton;