
/**
	* A class to link elements together as double linked list.
	* Each item of the list points to its next or previous element
	*/
class LinkedList {
	
	next:LinkedList;
	previous:LinkedList;
	
	/**
		* Get the first linked list node
		* @return The first element of the linked list
		*/
	public getFirst():LinkedList{
		return ( !this.previous ) ? this : this.previous.getFirst();
	}
	
	/**
		* Get the last linked list node
		* @return The last element of the linked list
		*/
	public getLast():LinkedList{
		return ( !this.next ) ? this : this.next.getLast();
	}
	
	/**
	* Sets the given node as next and this node as previous for the given one
	* Usefull to quickly connect nodes.
	* Does not connect the nodes if the given one has a previous element already
	* @param node The node that should be connected as next
	*/
	public addNext( node:LinkedList, force?:boolean ){
		if( node !== null && ( node.previous === null || force == true ) ){
			this.next = node;
			node.previous = this;
		}
	}
	
	/**
	* Sets the given node as previous and this node as next for the given one
	* Usefull to quickly connect nodes.
	* Does not connect the nodes if the given one has a next element already
	* @param node The node that should be connected as previous
	*/
	public addPrevious( node:LinkedList, force?:boolean ){
		if( node !== null && ( node.next === null || force == true ) ){
			this.previous = node;
			node.next = this;
		}
	}
	
	/**
	* Turns the linked list into a regular array.
	* @return An array of linkedlist elements
	*/
	public toArray():LinkedList[]{
		var node:LinkedList = this.getFirst();
		var result:LinkedList[] = [ node ];
		
		while( node.next ){
			node = node.next;
			result.push( node );
		}
		
		return result;
	}
	
	/**
		* Turns an array into a linked list.
		* @param array An array of objects
		* @return The first element of the created linked list
		*/
	public static fromArray( array:LinkedList[] ):LinkedList{
		
		// got elements?
		if( array === null || array.length <= 0 ){
			return null;
		}
		
		var lastIndex:number = array.length - 1, i;
		var current:LinkedList;
		
		for( i = 0; i < array.length; i++ ){
			
			current = array[ i ];
			
			// if first element
			if( i === 0 && lastIndex > 0 ){
				current.next = array[ i + 1 ]
				current.previous = undefined;
				
			// if last element
			} else if( i === lastIndex && lastIndex > 0 ){
				current.previous = array[ i - 1 ];
				current.next = undefined;
				
			// somewhere in the middle
			} else {
				current.next = array[ i + 1 ];
				current.previous = array[ i - 1 ];
			}
		}
		
		// return the first element
		return array[ 0 ];
	}
}

export default LinkedList;