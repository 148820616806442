// import lib
import View from 'ln/view/View';
import Window from 'ln/node/Window';
import SlideRenderer from '../sliderenderer/SlideRenderer';

class ResponsiveNavigation extends View {

	private slideRenderer: SlideRenderer;

	constructor( slideRenderer: SlideRenderer) {
		super();

		this.slideRenderer = slideRenderer;

		this.slideRenderer.slideChanged.add( this.check, this );
		this.slideRenderer.userAnswered.add( this.check, this );
		this.slideRenderer.userInput.add( this.check, this );
	}

	/**
	 * Render the template for the navigation
	 */
	public init() {

		Window.resize.add( this.check.bind( this ) );
		Window.scroll.add( this.check.bind( this ) );

		this.check();
	}

	/**
	 * Checks if the Navigation show be visible or not
	 */
	private check() {
		var scrollInfo = Window.scrollInfo();

		this.node.toggleClass( '-visible',  scrollInfo.height > 0 && scrollInfo.top < scrollInfo.height );
	}
}

export default ResponsiveNavigation;